<template>
    <v-snackbar text absolute bottom :elevation="8" class="snackbar-login" :color="color"
      :timeout="7000" v-model="alertStatus">
      <v-icon :color="color"> {{ icon }} </v-icon>
        {{ alertText }}
      <template v-slot:action="{ attrs }">
        <div v-if="getOpenedApp && eventsQuantity > 1"> 1/{{ eventsQuantity }} </div>
        <v-btn :color="color" icon v-bind="attrs" @click="setAlert(false)">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EMAlert',
  computed: {
    ...mapGetters(['getAlert', 'getAlertText', 'getAlertType', 'getNewEvents', 'getOpenedAppURN', 'getOpenedApp']),
    alertStatus: {
      get() {
        return this.getAlert;
      },
      set(status) {
        this.setAlert(status);
      },
    },
    color() {
      switch (this.getAlertType) {
        case 'alert':
          return 'red';
        case 'warning':
          return '#FF9800';
        case 'advice':
          return this.adviceOrInfoColor;
        case 'information':
          return this.adviceOrInfoColor;
        case 'success':
          return 'green';
        default:
          return 'red';
      }
    },
    adviceOrInfoColor() {
      return this.$vuetify.theme.dark ? '#6ADDFF' : '#641E8C';
    },
    icon() {
      switch (this.getAlertType) {
        case 'alert':
          return 'mdi-alert';
        case 'warning':
          return 'mdi-alert';
        case 'advice':
          return 'mdi-lightbulb-on';
        case 'information':
          return 'mdi-information';
        case 'success':
          return 'mdi-check-circle';
        default:
          return 'mdi-information';
      }
    },
    alertText() {
      return this.getAlertText;
    },
    eventsQuantity() {
      return this.getNewEvents.filter((event) => event.class === this.getOpenedAppURN).length;
    },
  },
  methods: {
    ...mapActions(['setAlert']),
  },
};
</script>

<style scoped>
.snackbar-login {
  z-index: 99999 !important;
  margin-bottom: 5px;
}
.snackbar-login .v-snack__wrapper .v-snack__content {
  padding: 14px 7px !important;
}
.snackbar-login .v-snack__wrapper .v-snack__content .v-icon {
  padding: 5px;
}
a {
  text-decoration: none;
}
</style>
