<template>
  <div style="position:relative;width:100%;height:100%">
    <div id="widget-pie-container" style="height:100%;width:100%;">
      <v-overlay :value="loadingAnimation" :opacity="0">
        <v-progress-circular :size="100" :width="7" color="buttons" indeterminate>
        </v-progress-circular>
      </v-overlay>
      <EMPieChartCreator class="widget-pie-chart"
        :series='series' ref="pieChart"/>
    </div>
    <EMWidgetSettings
      @dialogStateChangeEvent="changeSettingsDialogState"
      @saveSettingsEvent="saveTagSettings"
      :isOpen="settingsDialog"
      :title="title"
      :dialogWidth='800'
      :submitDisable="tagSettingsManagement.length > 0
      && editedTagSelectorsManagement.every((tag) => !tag.IsEdited)"
      :disableWarningMsg="$t('form_warnings.no_tags_selected')"
      ref="settings"
    >
      <div class="wdgt-settings-card-label d-flex justify-space-between">
        <div class="">{{ $t('time') }}</div>
        <div class="wdgt-settings-checkbox">
        <v-checkbox
            v-model="timeFrameSettingsValue.isActual"
            color="var(--v-buttons-base)"
            :label="$t('is_live')"
            hide-details
            :disabled="true"
          ></v-checkbox>
        </div>
      </div>
      <EMDatePicker
        class="my-8"
        :type="$t('at')"
        :isDisabled="timeFrameSettingsValue.isActual"
        @date-time-changed="timeFrameSettingsValue.timeAt = $event"
      />
      <div class="d-flex mb-2">
        <div class="wdgt-settings-card-label">{{ $t('tags') }}</div>
        <v-spacer></v-spacer>
        <v-icon medium @click="addTag" data-testid="add-tags-btn"
        class="button-white-on-purple"
        :class="{ 'plus-button-disabled': tagLimitReached }"
        >mdi-plus</v-icon>
      </div>
      <div style="max-height: 220px; overflow-y: auto; overflow-x: hidden;">
        <div
          class="tag-group-settings justify-space-between text-center"
          v-for="(tag, index) in tagSettingsManagement"
          :key="tag.name + index"
        >
          <div class="tag-group my-2 mr-1 rounded-outline" style="width: 500px;">
            <label>{{ $t('tag_name') }}</label>
            <v-container>
              <div class="text-center">
                <EMTagSelect
                  v-if="settingsDialog"
                  :tag="tag"
                  @updateTagValue="updateNameAndValue(index, $event)"
                />
              </div>
            </v-container>
          </div>
          <EMTextArea
            class="my-2 ml-1"
            :isDisabled="true"
            :inputValue="tagSettingsManagement[index].unit"
            :label="$t('unit')"
          />
          <EMDeleteButton class="mb-5 pl-2" @removeElementEvent="removeTag(index)" />
        </div>
      </div>
    </EMWidgetSettings>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import WidgetBasicMixin from '@/components/Widgets/mixins/widget-basic-mixin';
import EMPieChartCreator from './charts/EMPieChartCreator.vue';
import EMDatePicker from './Inputs/EMDatePicker.vue';
import EMTextArea from './Inputs/EMTextArea.vue';

const dateTimeNow = new Date();

export default {
  name: 'EMWidgetPieChart',
  props: {
    settingsDialog: {
      type: Boolean,
      default: false,
    },
    widgetData: {
      type: Object,
    },
    widgetTitle: {
      type: String,
    },
  },
  components: {
    EMPieChartCreator,
    EMDatePicker,
    EMTextArea,
  },
  mixins: [WidgetBasicMixin],
  data() {
    return {
      title: '',
      timeAt: dateTimeNow.toISOString().substring(0, 16),
      tags: [],
      tagsTypeList: [],
      timeFrameSettingsValue: {
        timeAt: dateTimeNow.toISOString().substring(0, 16),
        isActual: true,
      },
      series: [],
      isActual: true,
      tagLimit: Number(process.env.VUE_APP_PIE_CHART_MAX_TAG),
      loadingAnimation: false,
      editedTagSelectors: [],
    };
  },
  methods: {
    ...mapActions(['fetchTagDataLastBefore', 'updateTagSettings', 'addTagSettings', 'removeTagSettings']),
    updateNameAndValue(index, tagObject) {
      if (Object.keys(tagObject).length === 0) {
        const editedTagPayload = {
          index,
          IsEdited: true,
        };
        this.editedTagSelectorsManagement = editedTagPayload;
        return;
      }
      this.tagSettingsManagement = {
        index,
        parameters: {
          name: tagObject.tagName,
          tagId: tagObject.tagId,
          value: 0,
          unit: tagObject.unit,
        },
      };
      const editedTagPayload = {
        index,
        IsEdited: false,
      };
      this.editedTagSelectorsManagement = editedTagPayload;
      this.$emit('updateTagWidgetEvent', tagObject.tagName);
    },
    /*
     * Checks if new changes require data to be fetched.
     * Changes that require new data to be fetched:
     *  - Enable/disable live data
     *  - Timestamp At
     *  - Tags
    */
    saveTagSettings(title) {
      let needToFetchAgain = false;
      if (this.timeAt === undefined || this.tags.length === 0) {
        needToFetchAgain = true;
      } else if (this.isActual !== this.timeFrameSettingsValue.isActual) {
        needToFetchAgain = true;
      } else if (!this.timeFrameSettingsValue.isActual
        && this.timeAt !== this.timeFrameSettingsValue.timeAt) {
        needToFetchAgain = true;
      } else if (JSON.stringify(this.tags) !== JSON.stringify(this.tagSettingsManagement)) {
        needToFetchAgain = true;
      }
      this.title = title;
      this.tags = JSON.parse(JSON.stringify(this.tagSettingsManagement));
      this.isActual = JSON.parse(JSON.stringify(this.timeFrameSettingsValue.isActual));
      this.timeAt = this.timeFrameSettingsValue.timeAt;
      const uniqueTagList = [...new Set(this.tags.map((tag) => tag.name))];
      const widgetData = {
        tags: this.tags,
        timeAt: this.timeAt,
        isActual: this.isActual,
      };
      this.$emit('updateSettingsWidgetEvent', title, widgetData, uniqueTagList);
      if (this.tags.length > 0 && needToFetchAgain) {
        this.generateData();
      } else if (this.tags.length === 0) {
        this.series = [];
      }
    },
    generateTagDataSeries() {
      this.series = [];
      this.tags.forEach((tag) => {
        const tagObject = {
          name: tag.name,
          value: 0,
          unit: tag.unit,
        };
        const actualValue = this.getTagValue(tag);
        tagObject.value = actualValue;
        this.series.push(tagObject);
      });
    },
    generateHistoricalDataSeries() {
      this.series = [];
      const localTimeAt = new Date(`${this.timeAt}:00.000Z`);
      if (localTimeAt.getTime() > 0) {
        this.loadingAnimation = true;
        this.tags.forEach((tag) => {
          const tagObject = {
            name: tag.name,
            value: 0,
            unit: tag.unit,
          };
          this.fetchTagDataLastBefore({
            tag: tag.name,
            timeAt: localTimeAt,
          }).then(() => {
            tagObject.value = this.getLastBeforeDataByTagName(tag.name,
              localTimeAt.toISOString());
            if (tagObject.value) {
              this.series.push(tagObject);
            }
            this.loadingAnimation = false;
          });
        });
      }
    },
    generateData() {
      if (!this.isActual) {
        this.generateHistoricalDataSeries();
        return;
      }
      this.generateTagDataSeries();
    },
    addTag() {
      if (this.tagSettingsManagement.length === this.tagLimit) {
        return;
      }
      const tagSettingsPayload = {
        name: this.tagsTypeList[0].tagName,
        value: '',
        tagId: this.tagsTypeList[0].tagId,
        unit: '',
      };
      this.addTagSettings(tagSettingsPayload);
    },
    removeTag(index) {
      this.removeTagSettings(index);
    },
    getAllTags() {
      this.tagsTypeList = this.getTagsList;
    },
    reflowChart() {
      this.$refs.pieChart.$refs.emChart.$refs.chart.chart.reflow();
    },
    getTagValue(tag) {
      const actualValueObj = this.getTagDataByName(tag.name);
      if (actualValueObj) {
        return actualValueObj.value;
      }
      return null;
    },
    updateLiveDataForTags() {
      if (this.isActual) {
        this.generateTagDataSeries();
      }
    },
    resetSettingChanges() {
      this.addTagSettings(JSON.parse(JSON.stringify(this.tags)));
      this.timeFrameSettingsValue.isActual = JSON.parse(JSON.stringify(this.isActual));
      this.timeFrameSettingsValue.timeAt = this.timeAt;
    },
  },
  computed: {
    ...mapGetters(['getTagsList', 'getLastBeforeDataByTagName', 'getTagDataByName', 'getTagSetting']),
    tagSettingsManagement: {
      get() {
        return this.getTagSetting;
      },
      set(tagObject) {
        this.updateTagSettings(tagObject);
      },
    },
    editedTagSelectorsManagement: {
      get() {
        return this.editedTagSelectors;
      },
      set(payload) {
        const editedTag = this.editedTagSelectors.find((tag) => tag.index === payload.index);
        if (editedTag) {
          editedTag.IsEdited = payload.IsEdited;
        } else {
          this.editedTagSelectors.push({ index: payload.index, IsEdited: payload.IsEdited });
        }
      },
    },
  },
  mounted() {
    this.$root.$on('shortDataRefreshed', this.updateLiveDataForTags);
    this.$nextTick(() => {
      this.reflowChart();
    });
  },
  beforeDestroy() {
    this.$root.$off('shortDataRefreshed', this.updateLiveDataForTags);
  },
  created() {
    this.getAllTags();
    this.title = this.widgetTitle;
    if (Object.keys(this.widgetData).length > 0) {
      this.tags = this.widgetData.tags;
      this.addTagSettings(JSON.parse(JSON.stringify(this.tags)));
      this.timeAt = this.widgetData.timeAt;
      this.isActual = this.widgetData.isActual;
      this.timeFrameSettingsValue.isActual = JSON.parse(JSON.stringify(this.isActual));
      this.timeFrameSettingsValue.timeAt = this.widgetData.timeAt;
      if (this.tags.length > 0) {
        this.generateData();
      }
    }
  },
  watch: {
    settingsDialog() {
      this.addTagSettings(JSON.parse(JSON.stringify(this.tags)));
    },
  },
};
</script>

<style>
.widget-pie-chart{
  width: 100% !important;
  height: 100% !important;
}
</style>
