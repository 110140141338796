import en from './en.json';
import de from './de.json';
import pt from './pt.json';

// eslint-disable-next-line import/prefer-default-export
export const languages = {
  en,
  de,
  pt,
};
