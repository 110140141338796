<template>
  <div>
    <v-navigation-drawer :disable-resize-watcher="true"
    class="menu-drawer" data-testid="widget-drawer"
    absolute :hide-overlay="true" v-model="drawer" fixed right>
      <v-list-item class="drawer-title-sep">
      <v-list-item-content>
        <div
        class="text-capitalize font-weight-bold widget-header">
            <span data-testid="widget-drawer-title">{{ $t('widgets_lib').toUpperCase() }}</span>
            <v-icon data-testid="widget-drawer-close-icon"
            medium @click="drawer = !drawer">mdi-close</v-icon>
        </div>
      </v-list-item-content>
      </v-list-item>
    <v-list>
      <template>
        <EMWidget v-for="(item, key, index) in componentList" :key="key"
        style="cursor: pointer;" :component="item"
        :data-testid="`widget-tile-`+ index"
        v-bind:isLoaded='false'/>
      </template>
    </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import EMWidget from '@/components/Widgets/EMWidget.vue';
import ComponentListMixin from '@/components/Widgets/mixins/component-list-mixin';
import { mapMutations } from 'vuex';

export default {
  name: 'EMWidgetDrawer',
  components: { EMWidget },
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    ...mapMutations(['updateWidgetDrawerState']),
  },
  computed: {
  },
  mounted() {
    this.$root.$on('toggleWidgetDrawer', (value) => {
      this.drawer = value;
    });
  },
  beforeDestroy() {
    this.drawer = false;
    this.updateWidgetDrawerState(false);
  },
  watch: {
    drawer(newValue) {
      this.updateWidgetDrawerState(newValue);
    },
  },
  mixins: [ComponentListMixin],
};
</script>

<style scoped>
.menu-drawer {
  top: 48px !important;
  height: calc(100% - 48px);
  z-index: 9;
  background-color: var(--v-chartBackground-base);
}
.drawer-title-sep{
  border-bottom: solid 3px;
  border-bottom-color: var(--v-buttons-base);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  height: 40px;
}
.color{
  color: var(--v-buttons-base);
}
.widget-header{
  align-items: center;
  display: flex;
  justify-content: space-between;
  letter-spacing: 0.05em;
}
</style>
