import vuetify from '@/plugins/vuetify';
import Vuetify from 'vuetify/lib';
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueRouter from 'vue-router';
import VueCookies from 'vue-cookies';
import VueCrontab from 'vue-crontab';
import VueLayers from 'vuelayers';
import DatetimePicker from 'vuetify-datetime-picker';
import Moment from 'moment';
import MomentTimeZone from 'moment-timezone';
import Highcharts from 'highcharts';
import dataModule from 'highcharts/modules/data';
import store from './store';

import App from './App.vue';
import router from './router';
import i18n from './i18n';

import './assets/css/main.css';
import '@mdi/font/css/materialdesignicons.css';
import 'vuelayers/dist/vuelayers.css';

Vue.config.productionTip = false;

window.moment = Moment;
MomentTimeZone();
dataModule(Highcharts);

Vue.use(Vuetify);
Vue.use(VueRouter);
Vue.use(VueCookies);
Vue.use(DatetimePicker);
Vue.use(VueAxios, axios);
Vue.use(VueCrontab);
Vue.use(VueLayers);

Vue.config.devtools = process.env.NODE_ENV === 'development';

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
});

new Vue({
  store,
  router,
  vuetify,
  VueCrontab,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
