import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import client from 'api-client';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/*',
    redirect: {
      name: 'Home',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login') {
    const authPromise = new Promise((resolve) => resolve(client.user.getTokenStatus()));
    authPromise.then((resp) => {
      if (resp.data) {
        next();
        return;
      }
      next({ name: 'Login' });
    });
    authPromise.catch(() => next({ name: 'Login' }));
  } else next();
});

export default router;
