<template>
  <div>
    <v-dialog persistent v-model="settingsOpen" v-bind:width="dialogWidth" class="shadow-boxes">
      <v-card data-testid="wdgt-settings-dialog" elivation="5">
      <v-card-title class="text-h6 wdgt-settings-header">
        <div data-testid="wdgt-settings-title">{{ $t('widgets_settings_title') }}</div>
        <v-spacer></v-spacer>
        <v-col
        class="d-flex flex-row-reverse mr-n3" style="max-width:100px;">
          <v-icon data-testid="wdgt-settings-close-icon"
          medium @click="discardChanges()">mdi-close</v-icon>
        </v-col>
      </v-card-title>
        <v-form v-model="valid">
        <div class="wdgt-settings-card">
          <label class="wdgt-settings-card-label">{{ $t('title') }}</label>
          <v-text-field data-testid="wdgt-title-input"
          :label="$t('title')" single-line outlined v-model="widgetTitle" color="buttons"
          class="rounded-outline">
          </v-text-field>
          <slot/>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn data-testid="wdgt-settings-close-btn" text outlined class="pa-5"
          color="buttons" @click="discardChanges()">
            {{ $t('cancel') }}
          </v-btn>
          <v-menu
            top
            offset-y
            open-on-hover
            id="disable-btn-alert"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="!submitDisable ? on : {}"
                class="ml-2"
              >
                <v-btn
                  data-testid="wdgt-settings-save-btn"
                  :disabled="!submitDisable"
                  text
                  :class="{
                    'primary-button-disabled': !submitDisable,
                    'button-white-on-purple': submitDisable,
                    'pa-5': true,
                  }"
                  @click="openConfirmSave"
                >
                  {{ $t('save') }}
                </v-btn>
              </div>
            </template>
            <v-alert
              dense
              color="warning"
              icon="mdi-alert-circle"
              elevation="0"
            >
              {{ disableWarningMsg }}
            </v-alert>
          </v-menu>
        </v-card-actions>
      </v-form>
    </v-card>
    </v-dialog>

    <v-dialog v-model="confirmSave" width="450">
      <v-card elivation="5">
        <v-card-text>
          <div class="d-flex flex-column text-center widget-confirmSettings">
            <div class="widget-confirmSettings-close">
              <v-icon large @click="confirmSave = false">mdi-close</v-icon>
            </div>
            <div class="widget-confirmSettings-alert mt-10">
              <v-icon color="attentionColor" size="120">mdi-alert-circle</v-icon>
            </div>
            <div class="widget-confirmSettings-text mb-6">
              <h5 class="font-weight-bold">{{ $t('confirm_changes_title') }}</h5>
              <h6 class="font-weight-light">{{ $t('confirm_changes_subtitle') }}</h6>
            </div>
            <div class="widget-confirmSettings-buttons d-flex justify-space-between my-0">
              <v-btn text outlined  @click="discardChanges()" color="border"
              class="text-uppercase py-5 w-48 font-weight-bold border-width">
                <h4>{{ $t('discard_changes') }}</h4>
              </v-btn>
              <v-btn text outlined
              class="button-white-on-purple text-uppercase py-5 w-48
              font-weight-bold border-width shadow-boxes"
                @click="saveSettings" data-testid="confirm-save-btn">
                <h4>{{ $t('save_and_close') }}</h4>
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    dialogWidth: {
      type: Number,
      default: 500,
    },
    submitDisable: {
      type: Boolean,
      required: false,
      default: true,
    },
    disableWarningMsg: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['saveSettingsEvent', 'dialogStateChangeEvent', 'discardChanges'],
  data() {
    return {
      confirmSave: false,
      settingsOpen: this.isOpen,
      widgetTitle: this.title,
      valid: false,
    };
  },
  methods: {
    toggleConfirmSave() {
      this.confirmSave = !this.confirmSave;
    },
    toggleSettings() {
      this.settingsOpen = !this.settingsOpen;
    },
    closeAllDialogs() {
      this.toggleConfirmSave();
      this.toggleSettings();
    },
    openConfirmSave() {
      this.toggleConfirmSave();
    },
    saveSettings() {
      this.closeAllDialogs();
      this.$emit('saveSettingsEvent', this.widgetTitle);
    },
    discardChanges() {
      this.confirmSave = false;
      this.settingsOpen = false;
      this.widgetTitle = this.title;
      this.$emit('discardChanges');
    },
  },
  name: 'EMWidgetSettings',
  computed: {},
  watch: {
    settingsOpen(dialogState) {
      if (!dialogState) {
        this.$emit('dialogStateChangeEvent', dialogState);
      }
    },
    isOpen() {
      this.settingsOpen = this.isOpen;
    },
  },
};
</script>

<style>
.wdgt-settings-card{
  padding: 10px 20px;
}
.wdgt-settings-card > input{
  font-size:20px;
}
.wdgt-settings-header {
  background-color: var(--v-buttons-base);
  color: white;
  padding: 2px 17px !important;
}
.wdgt-settings-header > div > .v-icon{
  color: white;
}
.widget-confirmSettings-alert > .v-icon{
  font-size:200px;
}
.widget-confirmSettings-close{
  position:absolute;
  right:20px;
  top:10px;
}
.widget-confirmSettings-text > h5{
  color: var(--v-textConfirmSettings-base);
  font-size:20px;
  line-height: 45px;
}
.widget-confirmSettings-text > h6{
  color: var(--v-textConfirmSettings-base);
  font-size:18px;
  line-height: 20px;
  font-weight: normal;
}
.shadow-boxes{
    box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.3);
}
.w-48{
    width: 48%;
}
.v-btn__content{
    width: 80%;
    white-space: pre-wrap;
}
</style>
<style lang="scss" scoped>
.v-menu__content {
  box-shadow: none !important;
}
</style>
