/* eslint-disable no-shadow */

const state = {
  showDialogs: [],
};

const getters = {
  getDialog: (state) => (id) => (state.showDialogs.find((x) => x.id === id)
    ? state.showDialogs.find((x) => x.id === id).show : undefined),
};

const actions = {
  registerDialog({ commit }, id) {
    commit('addDialog', id);
  },
  removeDialog({ commit }, id) {
    commit('deleteDialog', id);
  },
  closeDialog({ commit }, id) {
    commit('clearDialog', id);
  },
  toggleDialog({ commit }, id) {
    commit('changeDialog', id);
  },
};

const mutations = {
  addDialog(state, id) {
    state.showDialogs.push({ id, show: false });
  },
  deleteDialog(state, id) {
    state.showDialogs = state.showDialogs.filter((x) => x.id !== id);
  },
  clearDialog(state, id) {
    const dialog = state.showDialogs.find((x) => x.id === id);
    dialog.show = false;
  },
  changeDialog(state, id) {
    const dialog = state.showDialogs.find((x) => x.id === id);
    dialog.show = true;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
