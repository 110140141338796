<template>
  <v-app>
    <div class="night-layer" v-show="nightMode"/>
    <div class="em-header">
      <EMHeader @toggleDrawer="$refs.drawerModel.drawerState = !$refs.drawerModel.drawerState"/>
    </div>
    <EMDrawer ref="drawerModel"/>
    <EMAlert/>
    <div v-if="getOpenedApp">
      <EMSystemBar :appName='getOpenedApp'/>
      <iframe :src="appURL" frameborder="0" v-resize="onResize"
      allow="clipboard-write" :height="windowSize.y" :width="windowSize.x"></iframe>
    </div>
    <div v-else>
      <EMAppBoard/>
      <EMWidgetBoard ref="widgetBoardRef" v-bind:class="[widgetArea, 'em-widgetboard']"/>
    </div>
    <EMChangePassword />
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EMSystemBar from '../components/Layout/EMSystemBar.vue';
import EMWidgetBoard from '../components/WidgetBoard/EMWidgetBoard.vue';
import EMAppBoard from '../components/Board/EMAppBoard.vue';
import EMHeader from '../components/Layout/EMHeader.vue';
import EMDrawer from '../components/Drawer/EMDrawer.vue';
import EMAlert from '../components/Alert/EMAlert.vue';
import EMChangePassword from '../components/Dialog/EMChangePassword.vue';

export default {
  name: 'Home',
  components: {
    EMAppBoard,
    EMSystemBar,
    EMWidgetBoard,
    EMAlert,
    EMHeader,
    EMDrawer,
    EMChangePassword,
  },
  data() {
    return {
      windowSize: {
        x: 0,
        y: 0,
      },
      nightMode: false,
    };
  },
  watch: {
    getOpenedApp(openedApp) {
      if (openedApp) {
        document.title = openedApp;
        this.trackIframeWindow('add');
      } else {
        document.title = 'EcoMAIN Vessel';
        this.trackIframeWindow('remove');
      }
    },
  },
  computed: {
    ...mapGetters(['getOpenedApp',
      'getOpenedAppURN',
      'getWidgetDrawerState',
      'getResetMode',
      'getAppList',
      'getGroupApps',
    ]),
    widgetArea() {
      let a = '';
      if (this.isWidgetDrawerOpen) a += ' em-widgetboard-collapsed';
      if (this.getGroupApps && this.getAppList.length <= 0) a += ' no-apps';
      return a;
    },
    appURL() {
      return `${window.location.origin}/${this.getOpenedAppURN}`;
    },
    isWidgetDrawerOpen() {
      return this.getWidgetDrawerState;
    },
  },
  methods: {
    ...mapActions(['fetchAppList', 'getUserData', 'fetchTagsList', 'toggleDialog', 'setConnectionRefresh', 'toggleUserMenu', 'closeAllMenu']),
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight - 80 };
    },
    trackIframeWindow(mode) {
      if (mode === 'add') {
        this.$nextTick(() => {
          const iframe = document.querySelector('iframe');
          if (iframe) {
            const iframeWindow = iframe.contentWindow;
            iframeWindow.addEventListener('click', () => {
              this.closeAllMenu();
            });
          }
        });
      } else if (mode === 'remove') {
        const iframe = document.querySelector('iframe');
        if (iframe) {
          const iframeWindow = iframe.contentWindow;
          iframeWindow.removeEventListener('click', () => {
            this.closeAllMenu();
          });
        }
      }
    },
  },
  created() {
    this.fetchAppList();
    this.fetchTagsList();
    this.setConnectionRefresh();
    this.$crontab.disableJob('verifyAuthCron');
  },
  mounted() {
    this.onResize();
    this.$root.$on('toggleNightMode', () => {
      this.nightMode = !this.nightMode;
    });
    this.$crontab.enableJob('verifyAuthCron');
    this.$crontab.addJob({
      name: 'shortDataRefreshCron',
      interval: {
        seconds: `/${process.env.VUE_APP_DATA_FETCH_INTERVAL_S}`,
      },
      job: this.$refs.widgetBoardRef.shortDataRefreshWrapper,
    });
    this.$crontab.addJob({
      name: 'longDataRefreshCron',
      interval: {
        seconds: `/${process.env.VUE_APP_DATA_FETCH_LONG_INTERVAL_S}`,
      },
      job: this.$refs.widgetBoardRef.longDataRefreshWrapper,
    });
    this.getUserData().then(() => {
      if (this.getResetMode) this.toggleDialog('changePassword');
    });
  },
  beforeDestroy() {
    this.$root.$off('toggleNightMode');
    this.$crontab.disableJob('verifyAuthCron');
    this.$crontab.deleteJob('shortDataRefreshCron');
    this.$crontab.deleteJob('longDataRefreshCron');
  },
};
</script>

<style>
.em-header{
  width: 100%;
  position: sticky;
  top:0;
  right:0;
  left:0;
}
.night-layer{
  position:fixed;
  opacity:.8;
  background-color:#000;
  z-index:9999;
  pointer-events:none;
  width:100%;
  height:100%;
  top:0;
  right:0;
  bottom:0;
  left:0
}

.em-widgetboard{
  height: calc(100vh - 153px);
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--v-backgroundBoard-base);
}

.em-widgetboard-collapsed{
  width: calc(100% - 256px);
}

.no-apps {
  height: calc(100vh - 50px) !important;
}

::-webkit-scrollbar {
  width: 9px;
} ::-webkit-scrollbar-track {
  background: #f1f1f1;
} ::-webkit-scrollbar-thumb {
  background: #888;
} ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
