/* eslint-disable no-shadow */
import client from 'api-client';
import EventTypeEnum from '../../../components/Enums/EventTypeEnum';

const state = {
  newEvents: [],
  advices: [],
  alerts: [],
  informations: [],
  warnings: [],
  loadingEventsState: false,
  firstLoading: true,
};

const getters = {
  getNewEvents: (state) => state.newEvents,
  getAdvices: (state) => state.advices,
  getAlerts: (state) => state.alerts,
  getInformation: (state) => state.informations,
  getWarnings: (state) => state.warnings,
  getLoadingEventsState: (state) => state.loadingEventsState,
};

const actions = {
  getNewEvents({ commit }) {
    if (state.firstLoading) {
      commit('setLoadingEventsState', true);
    }
    return new Promise((resolve) => {
      resolve(client.eventData.getNewEvents()
        .then((resp) => {
          commit('setNewEvents', resp.data.data.items);
          commit('setAdvices', state.newEvents.filter((item) => item.type === EventTypeEnum.ADVICE));
          commit('setAlerts', state.newEvents.filter((item) => item.type === EventTypeEnum.ALERT));
          commit('setInformation', state.newEvents.filter((item) => item.type === EventTypeEnum.INFORMATION));
          commit('setWarnings', state.newEvents.filter((item) => item.type === EventTypeEnum.WARNING));
          commit('setLoadingEventsState', false);
          commit('setFirstLoading', false);
          return resp;
        }));
    });
  },
};

const mutations = {
  setNewEvents(state, resp) {
    state.newEvents = resp;
  },
  setAdvices(state, resp) {
    state.advices = resp;
  },
  setAlerts(state, resp) {
    state.alerts = resp;
  },
  setInformation(state, resp) {
    state.informations = resp;
  },
  setWarnings(state, resp) {
    state.warnings = resp;
  },
  setLoadingEventsState(state, value) {
    state.loadingEventsState = value;
  },
  setFirstLoading(state, value) {
    state.firstLoading = value;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
