import axios from 'axios';
import { axiosRestApiPrivate, getCookie } from '../../axios/axiosInstance';

export default {
  getSysTime() {
    return axiosRestApiPrivate.get('/system/time');
  },
  getCurrentVersionReleaseNotes() {
    return axios.get(`EMS_ReleaseNotes_${getCookie('language')}.md`, { responseType: Text });
  },
  getConnectionStatus(tagIds) {
    return axiosRestApiPrivate.post('/system/connection-status', tagIds);
  },
};
