<template>
  <v-menu
    :value="showAlertWhileHovering"
    :position-x="positionX"
    :position-y="positionY"
  >
    <v-alert
      dense
      :color="alertColor"
      :icon="icon"
      elevation="0"
    >
      {{ alertMessage }}
    </v-alert>
  </v-menu>
</template>
<script>
export default {
  name: 'EMHoverAlert',
  props: {
    showAlertWhileHovering: {
      type: Boolean,
      default: false,
    },
    alertMessage: {
      type: String,
      default: '',
    },
    alertColor: {
      type: String,
      default: 'warning',
    },
    icon: {
      type: String,
      default: 'mdi-alert-circle',
    },
    positionX: {
      type: Number,
      default: 0,
    },
    positionY: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style scoped>
.v-menu__content {
  box-shadow: none !important;
}
</style>
