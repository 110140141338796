// import axios from 'axios';
import { axiosRestApiPrivate } from '../../axios/axiosInstance';

export default {
  getActualData(tagIds) {
    return axiosRestApiPrivate.post('tag-management/tags/actual-data', tagIds);
  },
  getAllTags() {
    return axiosRestApiPrivate.get('tag-management/tags/tag-configuration-lite');
  },
  getTimeSeriesData(
    tagIds,
    fromTimestamp,
    toTimestamp,
    sampleSize,
    sampleIndex,
    cancellationToken,
  ) {
    const tagList = [parseInt(tagIds, 10)];
    return axiosRestApiPrivate.post('/tag-management/tags/paged-historical-data', tagList, {
      cancelToken: cancellationToken.token,
      params: {
        startDate: fromTimestamp,
        endDate: toTimestamp,
        granularity: 'Raw',
        change: false,
        sampleIndex,
        sampleSize,
      },
    });
  },
};
