<template>
  <v-menu offset-y v-model="eventMenuState">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text tile color="iconsHeader" class="sm-btn-toolbar"
        data-testid="lightbulb-icon" v-bind="attrs" v-on="on">
        <v-icon v-if="type==='advice'">mdi-lightbulb-on</v-icon>
        <v-badge v-if="type==='alert'" dot :value="eventItems
            .filter(item => item.type === 'alert' || 'warning').length"
            offset-x="-14px" offset-y="-3px" color="red"></v-badge>
        <v-icon v-if="type==='alert'">mdi-bell</v-icon>
      </v-btn>
    </template>
    <v-card width="600" class="mt-2">
      <div
        v-if="getLoadingEventsState"
        class="mx-auto"
        style="width: 32px;">
        <v-progress-circular
          style="height: 124px;"
          indeterminate
          color="header"
        ></v-progress-circular>
      </div>
      <v-list v-if="!getLoadingEventsState" dense>
        <v-list-item v-if="eventItems.length == 0">
          <v-list-item-content style="height: 124px; text-align: center;">
            <v-list-item-subtitle style="font-size: large;">
              {{ $t('no_new_notifications') }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-virtual-scroll v-else
          :bench="eventItems.length > 4 ? eventItems.length - 4 : 0"
          :items="eventItems"
          :item-height="124"
          :height="128 * (eventItems.length < 4 ? eventItems.length : 4)"
          :min-height="136"
        >
          <template v-slot:default="{ item }">
            <v-list-item
              :style="{ backgroundColor: changeColor(item.type) }"
              class="mx-2 my-2 pl-2 pr-0">
              <v-card tile>
                <v-list-item>
                  <v-list-item-content style="height:120px;width: 580px;">
                    <v-list-item-title
                    style="display:flex; justify-content:space-between;"
                    class="text-h6">
                      <span style="display:flex; align-items: baseline;">
                        <v-icon class="pr-2" :color="iconColor(item.type)">
                          {{ showIcon(item.type) }}
                        </v-icon>
                        <div v-if="item.title.length <= 35">{{ item.title }}</div>
                        <div v-else>{{ item.title.substring(0, 35) }}...</div>
                      </span>
                      <span style="font-size:small; color:#666666;">
                        {{ item.created.substring(0,10) }} {{ item.created.substring(11,19) }}
                      </span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      In {{ item.class }}
                    </v-list-item-subtitle>
                    <v-list-item-content>
                      {{ item.message }}
                    </v-list-item-content>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </v-list>
      <v-btn
        color="#ADA9C2"
        width="100%"
        tile
        @click="setOpenedApp('Event Manager')"
        data-testid="notification-btn">
        {{ $t('show_more') }}
      </v-btn>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EventTypeEnum from '../Enums/EventTypeEnum';

export default {
  name: 'EventMenu',
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['getEventMenuState', 'getAdvices', 'getAlerts', 'getInformation', 'getWarnings', 'getLoadingEventsState']),
    eventMenuState: {
      get() {
        return this.getEventMenuState(this.type);
      },
      set(value) {
        this.toggleEventMenu({ menuType: this.type, menuState: value });
      },
    },
    advicesGetter: {
      get() {
        return this.getAdvices;
      },
    },
    alertsGetter: {
      get() {
        return this.getAlerts;
      },
    },
    informationGetter: {
      get() {
        return this.getInformation;
      },
    },
    warningsGetter: {
      get() {
        return this.getWarnings;
      },
    },
    eventItems: {
      get() {
        const items = [];
        if (this.type === EventTypeEnum.ALERT) {
          items.push(...this.alertsGetter);
          items.push(...this.warningsGetter);
        } else if (this.type === EventTypeEnum.ADVICE) {
          items.push(...this.advicesGetter);
          items.push(...this.informationGetter);
        }
        return items;
      },
    },
    adviceOrInfoColor: {
      get() {
        return this.$vuetify.theme.dark ? '#6ADDFF' : '#641E8C';
      },
    },
  },
  methods: {
    ...mapActions(['toggleEventMenu', 'setOpenedApp']),
    changeColor(type) {
      switch (type) {
        case EventTypeEnum.ALERT:
          return 'red';
        case EventTypeEnum.WARNING:
          return 'yellow';
        default:
          return this.adviceOrInfoColor;
      }
    },
    showIcon(type) {
      switch (type) {
        case EventTypeEnum.ADVICE:
          return 'mdi-lightbulb-on';
        case EventTypeEnum.ALERT:
          return 'mdi-alert';
        case EventTypeEnum.INFORMATION:
          return 'mdi-information';
        case EventTypeEnum.WARNING:
          return 'mdi-alert';
        default:
          return 'mdi-information';
      }
    },
    iconColor(type) {
      switch (type) {
        case EventTypeEnum.ALERT:
          return 'red';
        case EventTypeEnum.WARNING:
          return 'yellow';
        default:
          return this.adviceOrInfoColor;
      }
    },
  },
};
</script>

<style scoped>
.sm-btn-toolbar {
  height: 36px;
  min-width: 36px !important;
  padding: 0px 5px !important;
}
.v-badge {
  z-index: 99999;
}
</style>
