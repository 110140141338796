import systemData from './modules/systemData';
import configurationData from './modules/configurationData';
import user from './modules/user';
import tagData from './modules/tagData';
import eventData from './modules/eventData';

export default {
  namespaced: true,
  systemData,
  configurationData,
  user,
  tagData,
  eventData,
};
