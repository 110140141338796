// Import Available Components
import EMWidgetTagList from '../components/EMWidgetTagList.vue';
import EMWidgetMessage from '../components/EMWidgetMessage.vue';
import EMWidgetPieChart from '../components/EMWidgetPieChart.vue';
import EMWidgetLineChart from '../components/EMWidgetLineChart.vue';
import EMWidgetGaugeChart from '../components/EMWidgetGaugeChart.vue';
import EMWidgetHistogramChart from '../components/EMWidgetHistogramChart.vue';

export default {
  computed: {
    isDark() {
      return this.$vuetify.theme.dark ? '-dark' : '';
    },
    componentList() {
      return {
        EMWidgetTagList: {
          image: `img/widgets/tag-value${this.isDark}.svg`, h: 6, w: 2, minH: 6, minW: 2, type: 'EMWidgetTagList', name: this.$t('tag_list'), preserveAspectRatio: 'true', isSettingsOpen: false, widgetData: {}, tagList: {}, title: '',
        },
        EMWidgetLineChart: {
          image: `img/widgets/line-chart${this.isDark}.svg`, h: 6, w: 4, minH: 6, minW: 4, type: 'EMWidgetLineChart', name: this.$t('line_chart'), preserveAspectRatio: 'true', isSettingsOpen: false, widgetData: {}, tagList: {}, title: '',
        },
        EMWidgetGaugeChart: {
          image: `img/widgets/gauge${this.isDark}.svg`, h: 7, w: 4, minH: 7, minW: 4, type: 'EMWidgetGaugeChart', name: this.$t('gauge_chart'), preserveAspectRatio: 'true', isSettingsOpen: false, widgetData: {}, tagList: {}, title: '',
        },
        EMWidgetHistogramChart: {
          image: `img/widgets/histogram${this.isDark}.svg`, h: 8, w: 4, minH: 6, minW: 3, type: 'EMWidgetHistogramChart', name: this.$t('histogram_chart'), preserveAspectRatio: true, isSettingsOpen: false, widgetData: {}, tagList: {}, title: '',
        },
        EMWidgetMessage: {
          image: `img/widgets/message${this.isDark}.svg`, h: 4, w: 2, minH: 3, minW: 2, type: 'EMWidgetMessage', name: this.$t('widget_message'), preserveAspectRatio: 'true', isSettingsOpen: false, widgetData: {}, tagList: {}, title: '',
        },
        EMWidgetPieChart: {
          image: `img/widgets/pie-chart${this.isDark}.svg`, h: 7, w: 4, minH: 7, minW: 4, type: 'EMWidgetPieChart', name: this.$t('pie_chart_widget'), preserveAspectRatio: 'true', isSettingsOpen: false, widgetData: {}, tagList: {}, title: '',
        },
      };
    },
  },
  methods: {

  },
  components: {
    EMWidgetMessage,
    EMWidgetTagList,
    EMWidgetPieChart,
    EMWidgetLineChart,
    EMWidgetGaugeChart,
    EMWidgetHistogramChart,
  },
};
