<template>
  <highcharts :options='chartOptions' ref='chart'></highcharts>
</template>

<script>
import Highcharts from 'highcharts';
import { Chart } from 'highcharts-vue';
import hcMore from 'highcharts/highcharts-more';
import histogramInit from 'highcharts/modules/histogram-bellcurve';

hcMore(Highcharts);
histogramInit(Highcharts);

export default {
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      credits: {
        credits: {
          enabled: false,
        },
      },
    };
  },
  methods: {
  },
  computed: {
    chartOptions() {
      const darkThemeOptions = this.options;
      let theme = 'light';
      if (this.$vuetify.theme.dark) {
        theme = 'dark';
      }
      const color = this.$vuetify.theme.themes[theme].chartFont;
      if (darkThemeOptions.plotOptions !== undefined
      && darkThemeOptions.plotOptions.pie !== undefined) {
        Object.assign(darkThemeOptions.plotOptions.pie.dataLabels,
          { style: { textOutline: '' } });
      }
      if (darkThemeOptions.legend !== undefined) {
        darkThemeOptions.legend.itemStyle = { color };
        darkThemeOptions.legend.itemHoverStyle = { color };
      }
      darkThemeOptions.chart.backgroundColor = this.$vuetify.theme.themes[theme];
      return { ...this.credits, ...darkThemeOptions };
    },
  },
  created() {
  },
  beforeDestroy() {
    this.options.series = [];
  },
  components: {
    highcharts: Chart,
  },
  name: 'EMChart',
};
</script>

<style scoped>

</style>
