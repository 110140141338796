<template>
  <EMDialog id="logoutConfirmation" width="300" simpleDialog>
    <v-card-title class="justify-center text-h6 pb-2 pt-3 px-0">
      {{ $t('dialog_title') }}
    </v-card-title>
    <v-card-text class="justify-center d-flex text-body-2 pb-5 px-0">
      {{ $t('dialog_body') }}
    </v-card-text>
    <v-card-actions class="d-flex flex-row justify-space-around">
      <v-btn dense class="px-4" outlined color="buttons"
        @click="closeDialog('logoutConfirmation')">
        {{ $t('cancel') }}
      </v-btn>
      <v-btn dense class="px-4" color="buttons white--text" data-testid="logout-confirm"
        @click="$emit('dialog-logout')">
        {{ $t('dialog_title') }}
      </v-btn>
    </v-card-actions>
  </EMDialog>
</template>

<script>
import { mapActions } from 'vuex';
import EMDialog from '@/components/Dialog/EMDialog.vue';

export default {
  name: 'EMLogoutConfirmation',
  components: {
    EMDialog,
  },
  methods: {
    ...mapActions(['closeDialog']),
  },
};
</script>
