<template>
  <div style="position:relative;width:100%;height:100%">
    <EMOverlay
      :showOverlay="isTagDataListEmpty"
      :text="$t('no_values')"
      icon="mdi-database-off-outline"
    />
    <template v-if="!isTagDataListEmpty">
      <div class="tag-group my-5"  v-for="(tag, i) in tags" :key="i">
        <label>{{ tag.legend }}</label>
        <v-container>
          <div class="value text-center" v-if="getTagDataByName(tag.name)">
            {{ formatNumber(getTagDataByName(tag.name).value) }} <b>{{ tag.unit }}</b>
          </div>
        </v-container>
      </div>
    </template>
    <EMWidgetSettings
      @dialogStateChangeEvent="changeSettingsDialogState"
      @saveSettingsEvent="saveTagSettings"
      :isOpen="settingsDialog"
      :title="title"
      :dialogWidth='900'
      :submitDisable="tagSettingsManagement.length > 0
      && editedTagSelectorsManagement.every((tag) => !tag.IsEdited)"
      :disableWarningMsg="$t('form_warnings.no_tags_selected')"
      ref="settings"
    >
      <div class="d-flex mb-2">
        <div class="wdgt-settings-card-label">{{ $t('tags') }}</div>
        <v-spacer></v-spacer>
        <v-icon data-testid="add-tags-btn"
          medium @click="addTag" class="button-white-on-purple"
          :class="{ 'plus-button-disabled': tagLimitReached }"
        >
          mdi-plus
        </v-icon>
      </div>

      <div class="d-flex justify-space-between text-center"
      v-for="(tag, index) in tagSettingsManagement"
      :key="tag.name + index">
        <div class="tag-group my-3 mr-2 rounded-outline" style="width: 300px;">
          <label>{{ $t('tag_name') }}</label>
          <v-container>
            <div class="text-center">
              <EMTagSelect
              v-if="settingsDialog"
              :tag="tag"
              @updateTagValue="updateNameAndValue(index, $event)"/>
            </div>
          </v-container>
        </div>
        <EMTextArea
          class="my-3 mr-2"
          :inputValue="tagSettingsManagement[index]['legend']"
          @value-changed="updateLegend(index, $event)"
          :label="$t('field_label')"
        />
        <EMTextArea
          class="my-3 mr-2"
          :isDisabled="true"
          :inputValue="tagSettingsManagement[index].unit"
          :rules="[(v) => v.length <= 5 || $t('maxLength', { numb: 5 })]"
          :label="$t('x_axis_label')"
        />
        <EMTextArea
          class="my-3 mr-2"
          :isReadonly="true"
          :inputValue="getTagDataByName(tag.name) ?
          getTagDataByName(tag.name).value.toString() : ''"
          :label="$t('value')"
        />
        <EMDeleteButton class="mb-5 pl-2" @removeElementEvent="removeTag(index)" />
      </div>
    </EMWidgetSettings>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';
import WidgetBasicMixin from '@/components/Widgets/mixins/widget-basic-mixin';
import EMTextArea from './Inputs/EMTextArea.vue';
import EMOverlay from '../../Overlay/EMOverlay.vue';

export default {
  name: 'EMWidgetTagList',
  components: {
    EMTextArea,
    EMOverlay,
  },
  mixins: [WidgetBasicMixin],
  data() {
    return {
      title: '',
      tags: [],
      tagLimit: 4,
      tagsTypeList: [],
      unitFieldRule: [
        (v) => v.match(/^[a-zA-Z0-9$€]*$/) || 'Unit must be alphanumeric',
        (v) => (v || '').length > 5 || 'Unit must be less or equal 5 characters',
      ],
      editedTagSelectors: [],
    };
  },
  props: {
    settingsDialog: {
      type: Boolean,
      default: false,
    },
    widgetData: {
      type: Object,
    },
    widgetTitle: {
      type: String,
    },
  },
  methods: {
    ...mapActions(['updateTagSettings', 'addTagSettings', 'removeTagSettings']),
    updateNameAndValue(index, tagObject) {
      if (Object.keys(tagObject).length === 0) {
        const editedTagPayload = {
          index,
          IsEdited: true,
        };
        this.editedTagSelectorsManagement = editedTagPayload;
        return;
      }
      const selectedTag = this.tags
        .find((tag) => tag.name === tagObject.tagName);
      const tagLegend = selectedTag ? selectedTag.legend : tagObject.tagName;
      this.tagSettingsManagement = {
        index,
        parameters: {
          name: tagObject.tagName,
          tagId: tagObject.tagId,
          value: 0,
          unit: tagObject.unit,
          legend: tagLegend,
        },
      };
      const editedTagPayload = {
        index,
        IsEdited: false,
      };
      this.editedTagSelectorsManagement = editedTagPayload;
      this.$emit('updateTagWidgetEvent', tagObject.tagName);
    },
    updateLegend(index, legend) {
      this.tagSettingsManagement = {
        index,
        parameters: {
          name: this.tagSettingsManagement[index].name,
          tagId: this.tagSettingsManagement[index].tagId,
          value: this.tagSettingsManagement[index].value,
          unit: this.tagSettingsManagement[index].unit,
          legend,
        },
      };
    },
    saveTagSettings(title) {
      this.title = title;
      this.tags = JSON.parse(JSON.stringify(this.tagSettingsManagement));
      const uniqueTagList = [...new Set(this.tags.map((tag) => tag.name))];
      this.$emit('updateSettingsWidgetEvent', title, { tags: this.tags }, uniqueTagList);
    },
    addTag() {
      if (this.tagSettingsManagement.length === this.tagLimit) {
        return;
      }
      const tagSettingsPayload = {
        name: this.tagsTypeList[0].tagName,
        value: '',
        tagId: this.tagsTypeList[0].tagId,
        unit: '',
        legend: this.tagsTypeList[0].tagName,
      };
      this.addTagSettings(tagSettingsPayload);
    },
    getAllTags() {
      this.tagsTypeList = this.getTagsList;
    },
    removeTag(index) {
      this.removeTagSettings(index);
      this.tags = JSON.parse(JSON.stringify(this.tagSettingsManagement));
    },
    formatNumber(n) {
      if (Number(n) !== 0) {
        return Number(n).toFixed(2);
      }
      return n;
    },
    resetSettingChanges() {
      this.addTagSettings(JSON.parse(JSON.stringify(this.tags)));
    },
  },
  computed: {
    ...mapGetters(['getTagsList', 'getTagDataById', 'getTagDataByName', 'getTagSetting']),
    tagSettingsManagement: {
      get() {
        return this.getTagSetting;
      },
      set(tagObject) {
        this.updateTagSettings(tagObject);
      },
    },
    isTagDataListEmpty() {
      return this.tags.map((tag) => this.getTagDataByName(tag.name))
        .every((tag) => !tag);
    },
    editedTagSelectorsManagement: {
      get() {
        return this.editedTagSelectors;
      },
      set(payload) {
        const editedTag = this.editedTagSelectors.find((tag) => tag.index === payload.index);
        if (editedTag) {
          editedTag.IsEdited = payload.IsEdited;
        } else {
          this.editedTagSelectors.push({ index: payload.index, IsEdited: payload.IsEdited });
        }
      },
    },
  },
  created() {
    this.getAllTags();
    this.tags = this.widgetData.tags ? this.widgetData.tags : [];
    this.addTagSettings(JSON.parse(JSON.stringify(this.tags)));
    this.title = this.widgetTitle;
  },
  watch: {
    settingsDialog() {
      this.addTagSettings(JSON.parse(JSON.stringify(this.tags)));
    },
  },
};
</script>
<style scoped>
</style>
