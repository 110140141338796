<template>
  <v-menu offset-y :close-on-content-click="false" :z-index="99" v-model="menuStateGetter" eager>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" outlined class="sm-btn-toolbar" color="iconsHeader"
      data-testid="btn-content-header">
        {{ getFullname ?
        getFullname.match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()
        : '' }}
      </v-btn>
    </template>
    <v-card width="360" class="mt-2">
      <v-list dense class="py-0">
        <v-list-item class="pl-2 pr-1">
          <v-icon x-large color="buttons" class="mr-2" data-testid="user-menu-icon">
            mdi-account
          </v-icon>
          <v-list-item-content>
            <v-list-item-title> {{ getFullname }} </v-list-item-title>
            <v-list-item-subtitle> {{ getEmail }} </v-list-item-subtitle>
            <v-list-item-subtitle> {{ getGroupData.groupName }} </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action style="width: 33px;" class="mb-4 ml-7">
            <EMLanguageSwitch data-testid="user-menu-language-btn"/>
          </v-list-item-action>
          <v-list-item-action class="my-0 ml-1">
            <v-btn data-testid="user-menu-logout-button" icon large
            tile @click="toggleDialog('logoutConfirmation') && toggleUserMenu(false)">
              <v-icon>mdi-logout</v-icon>
              <EMLogoutConfirmation @dialog-logout="userLogout()"/>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-divider class="divider"/>
      <v-list dense class="d-flex flex-column">
        <div class="d-flex justify-space-between">
          <v-col cols="6" class="d-flex flex-column pa-0">
            <v-list-item :disabled="!!getOpenedApp" data-testid="user-menu-widgets-btn"
            @click="toggleUserMenu(false) && toggleWidgetDrawer(true)">
              <v-icon class="pr-3" data-testid="user-menu-widgets-icon">mdi-widgets</v-icon>
              <v-list-item-title data-testid="user-menu-widgets-title">
                {{ $t('widgets') }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="toggleUserMenu(false) && toggleTheme()">
              <v-icon class="pr-3" data-testid="user-menu-brightness-icon">mdi-brightness-6</v-icon>
              <v-list-item-title data-testid="user-menu-brightness-title">
                {{ $t('change_theme') }}
              </v-list-item-title>
            </v-list-item>
          </v-col>
          <v-col cols="6" class="d-flex flex-column pa-0">
            <v-list-item @click="toggleUserMenu(false) && toggleNightMode()">
              <v-icon class="pr-3" data-testid="user-menu-weather-icon">mdi-weather-night</v-icon>
              <v-list-item-title data-testid="user-menu-weather-title">
                {{ $t('night_mode') }}
              </v-list-item-title>
                <EMChangePassword />
            </v-list-item>
            <v-list-item @click="toggleDialog('changePassword') && toggleUserMenu(false)" link>
              <v-icon class="pr-3" data-testid="user-menu-cog-icon">mdi-cog</v-icon>
              <v-list-item-title data-testid="user-menu-settings-title">
                {{ $t('change_password') }}
              </v-list-item-title>
            </v-list-item>
          </v-col>
        </div>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EMLanguageSwitch from '../Layout/EMLanguageSwitch.vue';
import EMLogoutConfirmation from '../Dialog/EMLogoutConfirmation.vue';
import EMChangePassword from '../Dialog/EMChangePassword.vue';

export default {
  name: 'UserMenu',
  components: {
    EMLanguageSwitch,
    EMLogoutConfirmation,
    EMChangePassword,
  },
  data() {
    return {
      intervalId: undefined,
      closeIntervalId: undefined,
      closeInterval: 5000,
    };
  },
  computed: {
    ...mapGetters(['getFullname', 'getUsername', 'getEmail', 'getGroupData', 'getUserMenuState', 'getOpenedApp']),
    menuStateGetter: {
      get() {
        return this.getUserMenuState;
      },
      set(menuState) {
        this.toggleUserMenu(menuState);
      },
    },
  },
  methods: {
    ...mapActions(['logout', 'setOpenedApp', 'toggleUserMenu', 'toggleDialog', 'getNewEvents']),
    async userLogout() {
      await this.logout();
      await this.setOpenedApp('Launchpad');
      clearInterval(this.intervalId);
      this.$router.push('/login');
    },
    toggleTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$cookies.set('theme', (this.$vuetify.theme.dark ? 'dark' : 'light'), Infinity);
    },
    toggleWidgetDrawer(value) {
      this.$root.$emit('toggleWidgetDrawer', value);
    },
    toggleNightMode() {
      this.$root.$emit('toggleNightMode');
    },
  },
  created() {
    this.getNewEvents();
    this.intervalId = setInterval(() => {
      this.getNewEvents();
    }, this.closeInterval);
  },
};
</script>

<style>
.divider {
  background-color: var(--v-buttons-base);
  height: 1.5px;
  max-height: 1.5px;
}
.sm-btn-toolbar {
  height: 36px;
  min-width: 36px !important;
  padding: 0px 5px !important;
}
</style>
