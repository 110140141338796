import Vue from 'vue';
import VueI18n from 'vue-i18n';

import { languages } from './assets/locales/index';

const messages = Object.assign(languages);

Vue.use(VueI18n);
export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en-US',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de-DE',
  messages,
});
