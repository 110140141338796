<template>
  <EMDialog id="changePassword" width="350">
    <v-card-title class="d-flex justify-space-between text-h6 py-1 px-4 dialog-header">
      {{ $t('change_password') }}
    </v-card-title>
    <v-divider class="dialog-divider mb-3"/>
    <v-card-text class="justify-center d-flex flex-column text-body-2 py-2 px-4">
      <div v-if="getResetMode" class="d-flex align-center mb-6">
        <v-icon size="24" color="#DBDBDB" v-text="`mdi-information`" class="mr-2" />
        <label>{{ $t('reset_password_message') }}</label>
      </div>
      <v-form ref="form" v-model="valid" lazy-validation style="width: 100%;">
        <div v-if="!getResetMode">
          <label class="text-subtitle-2 text-uppercase">{{ $t('old_password') }}</label>
          <v-text-field class="mt-1 mb-1" solo color="buttons" dense
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'"
          @click:append="show1 = !show1" :rules="oldPasswordRule" v-model="oldPassword" required />
        </div>
        <label class="text-subtitle-2 text-uppercase">{{ $t('new_password') }}</label>
        <v-text-field class="mt-1 mb-1" solo color="buttons" dense
        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" :type="show2 ? 'text' : 'password'"
        @click:append="show2 = !show2" :rules="passwordRules" v-model="newPassword" required />
        <label class="text-subtitle-2 text-uppercase">{{ $t('repeat_password') }}</label>
        <v-text-field class="mt-1 mb-1" solo color="buttons" dense
        :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'" :type="show3 ? 'text' : 'password'"
        @click:append="show3 = !show3" :rules="confirmRules" v-model="confirmPassword" required />
      </v-form>
    </v-card-text>
    <v-card-actions class="d-flex flex-row justify-space-between px-4">
      <EMButton class="pr-3" primaryButtons @click.native="modifyPassword" :disabled="!valid">
        <v-icon left size="25" v-text="`mdi-content-save`"/>
        {{ $t('save') }}
      </EMButton>
      <EMButton class="pr-3" outlined @click.native="reset" v-if="getResetMode">
        {{ $t('clear') }}
      </EMButton>
      <EMButton class="pr-3" outlined @click.native="close" v-else>
        {{ $t('cancel') }}
      </EMButton>
    </v-card-actions>
  </EMDialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import EMDialog from '@/components/Dialog/EMDialog.vue';
import EMButton from '../Buttons/EMButton.vue';

export default {
  name: 'EMChangePassword',
  components: {
    EMDialog,
    EMButton,
  },
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      show1: false,
      show2: false,
      show3: false,
      valid: true,
      oldPasswordRule: [(v) => !!v || this.$i18n.t('required')],
      passwordRules: [
        (v) => !!v || this.$i18n.t('required'),
        (v) => (v && /(^.*(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_0-9]).*$)/.test(v)) || this.$i18n.t('password_rules'),
      ],
      confirmRules: [
        (v) => !!v || this.$i18n.t('required'),
        (v) => (v === this.newPassword) || this.$i18n.t('password_match'),
        (v) => (v && /(^.*(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_0-9]).*$)/.test(v)) || this.$i18n.t('password_rules'),
      ],
    };
  },
  computed: {
    ...mapGetters(['getResetMode', 'getOldPwd']),
  },
  methods: {
    ...mapActions(['toggleDialog', 'changePassword', 'closeDialog', 'setAlertText', 'setAlertType', 'setAlert']),
    reset() {
      this.$refs.form.reset();
    },
    close() {
      this.$refs.form.reset();
      this.closeDialog('changePassword');
    },
    async modifyPassword() {
      await this.$refs.form.validate();
      if (this.valid) {
        await this.changePassword({
          oldPassword: this.getResetMode ? this.getOldPwd : this.oldPassword,
          newPassword: this.newPassword,
        }).then(() => {
          this.setAlertText(this.$i18n.t('changed_password'));
          this.setAlertType('success');
          this.setAlert(true);
        })
          .catch((err) => {
            this.setAlertText(err ?? this.$i18n.t('default_error'));
            this.setAlertType('error');
            this.setAlert(true);
          });
      }
    },
  },
};
</script>
<style>
.dialog-header {
  color: var(--v-buttons-base) !important;
}
.dialog-divider {
  background-color: var(--v-buttons-base) !important;
  height: 2px !important;
  max-height: 2px !important;
  min-height: 2px !important;
}
</style>
