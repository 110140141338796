<template draggable="true">
  <div style="width:100%;height:100%;" ref="componentContainer">
    <div @drag="drag(component)" @dragend="dragend(component)"
    class="droppable-element wdgt-pw-header d-flex"
      draggable="true" unselectable="on" v-if="isLoaded == false">
      <div class="wdgt-pw-sidebar"></div>
      <v-col col="11" class="wdgt-pw-content d-flex flex-column justify-center">
        <div class="d-flex justify-space-between align-center">
          <div>{{component.name}}</div>
          <div style="height:60px;">
            <img data-testid = "widget-tile-icon"  :src="require('../../assets/'+ component.image)"
            style="height:100%"/>
          </div>
        </div>
      </v-col>
    </div>

    <div style="width:100%;height:100%;" v-if="isLoaded == true">
      <v-card-title class="wdgt-title-sep justify-space-between">
        <v-col class="wdgt-content-title col-8">{{ title }}</v-col>
        <slot></slot>
      </v-card-title>
      <v-container class="widget-container-full">
        <component v-bind:is="component.type"
        v-bind:settingsDialog="getSettingsDialogState(widgetId)"
        v-bind:widgetData="componentWidgetData"
        v-bind:widgetTitle="componentWidgetTitle"
        @updateSettingsWidgetEvent="updateSettings"
        @updateTagWidgetEvent="updateTagList"
        @dialogStateChangeEvent="updateSettingsDialogState"
        ref="component"
        />
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import ComponentListMixin from './mixins/component-list-mixin';

export default {
  mixins: [ComponentListMixin],
  data() {
    return {
      title: '',
      componentWidgetData: [],
      componentWidgetTitle: '',
      resizeObserver: null,
    };
  },
  props: {
    isLoaded: {
      type: Boolean,
    },
    component: {
      type: Object,
    },
    widgetId: {
      type: String,
    },
  },
  name: 'EMWidget',
  methods: {
    ...mapActions(['getTagDataByIds', 'fetchTagDataByName']),
    ...mapMutations(['toggleWidgetSettingsDialog', 'setWidgetDataConfig']),
    drag(data) {
      this.$root.$emit('widgetDrag', data);
    },
    dragend(data) {
      this.$root.$emit('widgetDragend', data);
    },
    updateTagList(singleTag) {
      this.fetchTagDataByName([singleTag]);
    },
    updateSettings(title, data, tagList) {
      this.title = title;
      const widgetObject = {
        widgetId: this.widgetId,
        title,
        data,
        tagList,
      };
      this.setWidgetDataConfig(widgetObject);
      this.$emit('updateWidgetConfigData', widgetObject);
    },
    updateSettingsDialogState() {
      const dialog = this.getSettingsDialogState(this.widgetId);
      this.toggleWidgetSettingsDialog(this.widgetId);
      this.$emit('dialogStateChangeEvent', { widgetId: this.widgetId, state: dialog });
    },
    onResize() {
      if (this.$refs.component && typeof this.$refs.component.reflowChart === 'function') {
        this.$refs.component.reflowChart();
      }
    },
  },
  computed: {
    ...mapGetters(['getSettingsDialogState']),
  },
  created() {
    this.componentWidgetData = this.component.widgetData ? this.component.widgetData : {};
    this.componentWidgetTitle = this.component.title ? this.component.title : '';
    this.title = this.componentWidgetTitle;
  },
  mounted() {
    this.resizeObserver = new ResizeObserver(this.onResize)
      .observe(this.$refs.componentContainer);
  },

  beforeDestroy() {
    if (typeof this.resizeObserver !== 'undefined') this.resizeObserver.unobserve(this.$refs.componentContainer);
  },
};
</script>

<style>
.widget-card{
  border-radius:4px !important;
  touch-action: none;
}
.wdgt-pw-header{
  background-color: var(--v-background) !important;
  color: var(--v-sidebarFont-base);
  height: 65px;
  margin: 3% 10px;
}
.wdgt-pw-content{
  border-style: solid;
  border-radius: 0px 4px 4px 0;
  border-width: 2px 2px 2px 0px;
  border-color: var(--v-sidebarWidgetOutline-base);
  font-weight: 500;
  font-size: 15px;
}
.wdgt-pw-sidebar{
  background-color: var(--v-buttons-base);
  border-radius: 5px 0px 0px 5px;
  height:100%;
  width:10px;
}
.wdgt-content-title{
  color: var(--v-buttons-base);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  line-height:1.25rem !important;
}
.wdgt-title-sep{
  border-bottom: solid 2px;
  border-bottom-color: var(--v-buttons-base);
  padding:0;
  line-height:1.25rem !important;
  height: 40px;
}
.widget-container-full{
  width:100%;
  overflow: auto;
  /* reduce by header height */
  height:calc( 100% - 50px );
}

</style>
