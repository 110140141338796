<template>
  <v-card
    flat
    color="transparent"
  >
    <v-subheader>{{ label }}</v-subheader>

    <v-card-text>
      <v-row>
        <v-col class="pr-4">
          <v-slider
            color="buttons"
            track-color="menu"
            v-model="sliderValue"
            @input="sliderUpdated($event)"
            class="align-center"
            :max="max"
            :min="min"
            hide-details
          >
            <template v-slot:append>
              <v-text-field
                v-model="sliderValue"
                @input="sliderUpdated($event)"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              ></v-text-field>
            </template>
          </v-slider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'EMSlider',
  props: {
    inputValue: {
      type: Number,
      required: false,
      default: 0,
    },
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    max: {
      type: Number,
      required: false,
      default: 100,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
  },
  emits: ['slider-value-changed'],
  data() {
    return {
      sliderValue: this.inputValue,
    };
  },
  methods: {
    sliderUpdated(value) {
      this.$emit('slider-value-changed', value);
    },
  },
};
</script>

<style>

</style>
