<template>
  <v-system-bar window color="footer">
    <EMIcons :name="appName.replace(/ /g,'')" width="24"/>
    <div class="text-capitalize ml-4 iconsHeader--text">{{ appName }}</div>
    <div class="ml-3 iconsHeader--text version">
      {{ appVersion }}
    </div>
    <v-spacer/>
    <v-btn @click="setOpenedApp('')" plain small>
      <v-icon color="iconsHeader" class="ml-2">mdi-close</v-icon>
    </v-btn>
  </v-system-bar>
</template>

<script>
import EMIcons from '@/components/SVG/EMIcons.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EMSystemBar',
  components: { EMIcons },
  props: {
    appName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      appVersionIntervalFunc: setInterval,
    };
  },
  mounted() {
    this.fetchAppList();
    this.activateAppVersionInterval();
  },
  beforeDestroy() {
    clearInterval(this.appVersionIntervalFunc);
  },
  methods: {
    ...mapActions(['setOpenedApp', 'fetchAppList']),
    getActiveVersion() {
      return this.getOpenedAppVersion.find((x) => (x.state === 'activated'));
    },
    getVersionNumber(version) {
      if (version) {
        return `v${version.versionMajor}.${version.versionMinor}
          ${(version.versionMajor === 0 ? `(${this.$t('app_version')})` : '')}`;
      }
      return '';
    },
    activateAppVersionInterval() {
      const updateInterval = process.env.VUE_APP_FETCH_VERSION_INTERVAL_S * 1000;
      this.appVersionIntervalFunc = setInterval(async () => {
        await this.fetchAppList();
      }, updateInterval);
    },
  },
  computed: {
    ...mapGetters(['getOpenedAppVersion']),
    appVersion() {
      return this.getVersionNumber(this.getActiveVersion());
    },
  },
};
</script>

<style scope>
.version {
  padding-top: 2px;
  font-style: italic;
  font-size: 0.75rem;
  letter-spacing: 0.25px;
}
</style>
