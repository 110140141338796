import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        background: '#F8F8F8',
        footer: '#1B1435',
        header: '#38206A',
        buttons: '#641E8C',
        hoveringButtons: '#3D0F4D',
        activeButtons: '#221D43',
        menu: '#DBDBDB',
        applications: '#00B4AA',
        textsIconsMenu: '#333333',
        tooltip: '#E4C2F2',
        iconsHeader: '#FFFFFF',
        tableHeader: '#ADA9C2',
        error: '#F44336',
        chartBackground: '#FFFFFF',
        border: '#8C8C8C',
        textConfirmSettings: '#545454',
        backgroundBoard: '#E5E5E5',
        placeholder: '#3A3E3B',
        sidebarFont: '#641E8C',
        sidebarWidgetOutline: '#A4A4A4',
        attentionColor: '#E5E5E5',
        settingsBackground: '#FFFFFF',
        chartFont: '#666666',
        chartLabel: '#3D0F4D',
        chartLegend: '#000000',
      },
      dark: {
        background: '#111111',
        footer: '#0A1B26',
        header: '#054065',
        buttons: '#0066A4',
        hoveringButtons: '#008AC7',
        activeButtons: '#112A3A',
        menu: '#1E1E1E',
        applications: '#B6D6F2',
        textsIconsMenu: '#E5E5E5',
        tooltip: '#A0C6E8',
        iconsHeader: '#FFFFFF',
        tableHeader: '#333333',
        error: '#F44336',
        chartBackground: '#2E2E2E',
        sidebarFont: '#E5E5E5',
        sidebarWidgetOutline: '#A4A4A4',
        backgroundBoard: '#1a1a1a',
        border: '#FFFFFF',
        textConfirmSettings: '#E5E5E5',
        attentionColor: '#A4A4A4',
        settingsBackground: '#1E1E1E',
        chartFont: '#FFFFFF',
        chartLabel: '#FFFFFF',
        chartLegend: '#FFFFFF',
      },
    },
    options: { customProperties: true },
  },
});

export default vuetify;
