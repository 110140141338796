import { axiosRestApiPrivate, getCookie } from '../../axios/axiosInstance';

export default {
  login(payload) {
    return (axiosRestApiPrivate.post('/auth/login', payload));
  },
  getTokenStatus() {
    return axiosRestApiPrivate.get('/auth/token-status');
  },

  refreshToken() {
    return axiosRestApiPrivate.get('/auth/refreshed-token');
  },

  updatePassword(payload) {
    return axiosRestApiPrivate.put('/user-management/users/password', payload);
  },
  updateUserDetails(payload) {
    return axiosRestApiPrivate.put(`/user-management/users/${getCookie('loginId')}`, payload);
  },
  getUserData() {
    return axiosRestApiPrivate.get(`/user-management/users/${getCookie('loginId')}`);
  },
  getGroupList() {
    return axiosRestApiPrivate.get('/user-management/groups');
  },
  getGroupData(groupId) {
    return axiosRestApiPrivate.get(`/user-management/groups/${groupId}`);
  },
};
