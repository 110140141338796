<template>
  <div style="position:relative;width:100%;height:100%">
    <div :class="{ 'gauge-legend-horizontal' : isLegendHorizontal === 1 }"
    id="widget-gauge-container" style="height:100%;display:flex">
      <GaugeCreator class="widget-gauge-chart" :legends='legends'
      :series='series' :scale='scale' :tag='tag' ref="gaugeChart"
       @scale-changed="($event) => saveScaleUpdate($event)"/>
      <div :class="legendWrapperCSS">
        <div class="widget-gauge-legend" v-for="(legend ,i) in legends" v-bind:key='i'>
          <div class="widget-gauge-color" v-bind:style="legendColor(legend)"></div>
          <div class="pl-2">{{ legend.name }}</div>
        </div>
      </div>
    </div>
    <EMWidgetSettings
      @dialogStateChangeEvent='changeSettingsDialogState'
      @saveSettingsEvent='saveTagSettings'
      :isOpen='settingsDialog'
      :title='title'
      :dialogWidth='800'
      :submitDisable="settings.tag.name !== '' && settings.tag.name !== undefined"
      :disableWarningMsg="$t('form_warnings.no_tags_selected')"
      ref="settings"
    >
      <div class='tag-group-header'>
        <div class="wdgt-settings-card-label">{{ $t('tag') }}</div>
        <v-spacer></v-spacer>
      </div>
      <div class="tag-group-settings justify-space-between text-center">
        <div class="tag-group my-3 mr-2" >
          <label>{{ $t('tag_name') }}</label>
          <v-container>
              <div class="text-center">
                <EMTagSelect
                v-if="settingsDialog"
                :tag="settings.tag"
                @updateTagValue="updateNameAndValue($event)"/>
              </div>
          </v-container>
        </div>
        <div class="my-3 mr-2">
          <EMTextArea
            :inputValue="settings.tag.unit"
            :label="$t('unit')"
            @value-changed="settings.tag.unit = $event"
            :isDisabled="true"
          />
        </div>
        <div class="my-3 w-50">
          <EMumberArea
            :isReadonly="true"
            :inputValue="getTagDataByName(settings.tag.name) ?
            parseFloat(getTagDataByName(settings.tag.name).value) : 0.0"
            :label="$t('value')"
          />
        </div>
      </div>
      <div class="scale-group">
        <div class="wdgt-settings-card-label">{{ $t('scale') }}</div>
        <div class="scale-group-settings d-flex">
          <div class="scale-group my-3 mr-4">
            <EMumberArea
              :inputValue="parseFloat(settings.scale.min)"
              :label="$t('min')"
              @value-changed="settings.scale.min = $event"
            />
          </div>
          <div class="scale-group my-3 mr-4">
            <EMumberArea
              :inputValue="parseFloat(settings.scale.max)"
              :label="$t('max')"
              @value-changed="settings.scale.max = $event"
            />
          </div>
        </div>
      </div>
      <div class='tag-group-header d-flex mb-2'>
        <div class="wdgt-settings-card-label">{{ $t('legend') }}</div>
        <v-spacer></v-spacer>
        <v-icon data-testid="add-legend-btn" medium @click='addLegend'
        class="button-white-on-purple">mdi-plus</v-icon>
      </div>

      <div
        class='tag-group-settings justify-space-between text-center'
        v-for='(legend, index) in settings.legends'
        :key='index'
      >
        <div class="mt-8 mr-2 w-25">
          <EMTextArea
            :data-testid="`legend-input-`+ index"
            :inputValue="legend.name"
            :label="$t('legend_name')"
            @value-changed="legend.name = $event"
          />
        </div>
        <div class='tag-group mt-8 mr-2 w-20'>
          <label>{{ $t('color') }}</label>
          <v-container>
            <div class='text-center'>
              <colorPicker :colorProp="legend.color" @change="val => legend.color = val" />
            </div>
          </v-container>
        </div>
        <div class="d-flex flex-column">
          <div class="my-3 mr-2 w-25">
            <EMumberArea
              :data-testid="`legend-from-input-`+ index"
              :inputValue="legend.from"
              :label="$t('legend_from')"
              @value-changed="legend.from = $event"
            />
            <EMumberArea
              :data-testid="`legend-to-input-`+ index"
              :inputValue="legend.to"
              :label="$t('legend_to')"
              @value-changed="legend.to = $event"
            />
          </div>
        </div>
        <EMDeleteButton class="pl-2" @removeElementEvent="removeLegend(index)" />
      </div>
    </EMWidgetSettings>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WidgetBasicMixin from '@/components/Widgets/mixins/widget-basic-mixin';
import EMColorPicker from '@/components/ColorPicker/EMColorPicker.vue';
import EMGaugeChartCreator from './charts/EMGaugeChartCreator.vue';
import EMumberArea from './Inputs/EMNumberArea.vue';
import EMTextArea from './Inputs/EMTextArea.vue';

export default {
  props: {
    settingsDialog: {
      type: Boolean,
      default: false,
    },
    widgetData: {
      type: Object,
    },
    widgetTitle: {
      type: String,
    },
  },
  components: {
    colorPicker: EMColorPicker,
    GaugeCreator: EMGaugeChartCreator,
    EMumberArea,
    EMTextArea,
  },
  data() {
    return {
      title: '',
      tag: { id: '', name: '', unit: '' },
      legends: [],
      series: {},
      scale: { min: 0, max: 500 },
      settings: {
        legends: [],
        tag: { id: '', name: '' },
        scale: { min: 0, max: 500 },
      },
      tagLimit: 1,
      tagsTypeList: [],
      isLegendHorizontal: 1,
      editedTagSelector: false,
    };
  },
  methods: {
    updateNameAndValue(tagObject) {
      if (Object.keys(tagObject).length === 0) {
        this.editedTagSelector = true;
      }
      this.settings.tag.name = tagObject.tagName;
      this.settings.tag.id = tagObject.tagId;
      this.settings.tag.unit = tagObject.unit;
      this.editedTagSelector = false;
      this.$emit('updateTagWidgetEvent', tagObject.tagName);
    },
    saveTagSettings(title) {
      this.title = title;
      this.tag = JSON.parse(JSON.stringify(this.settings.tag));
      this.legends = JSON.parse(JSON.stringify(this.settings.legends));
      this.scale = JSON.parse(JSON.stringify(this.settings.scale));
      this.setGaugeSeriesData();

      const uniqueTagList = [this.tag.name];
      const widgetData = {
        tag: this.tag,
        scale: this.scale,
        legends: this.legends,
      };
      this.$emit('updateSettingsWidgetEvent', title, widgetData, uniqueTagList);
    },
    saveScaleUpdate(updatedScale) {
      this.settings.scale = updatedScale;
      this.$emit('updateSettingsWidgetEvent', this.title, this.settings, [this.tag.name]);
    },
    addLegend() {
      if (this.settings.legends.length === 3) {
        return;
      }
      this.settings.legends.push({
        name: '',
        color: '#666666',
        from: 0,
        to: 0,
      });
    },
    legendColor(legendObject) {
      return {
        backgroundColor: `${legendObject.color}`,
      };
    },
    getAllTags() {
      this.tagsTypeList = this.getTagsList;
    },
    reflowChart() {
      this.$refs.gaugeChart.$refs.emChart.$refs.chart.chart.reflow();
      this.setLayoutPosition();
    },
    setGaugeSeriesData() {
      const tagObject = {
        name: `${this.tag.name} [${this.tag.unit}]`,
        data: [0],
      };
      this.series = tagObject;
    },
    setLayoutPosition() {
      const widget = document.getElementById('widget-gauge-container');
      if (widget.offsetWidth < 400) {
        this.isLegendHorizontal = 1;
        return;
      }
      this.isLegendHorizontal = 0;
    },
    removeLegend(index) {
      this.settings.legends.splice(index, 1);
    },
    resetSettingChanges() {
      this.settings.tag = JSON.parse(JSON.stringify(this.tag));
      this.settings.legends = JSON.parse(JSON.stringify(this.legends));
      this.settings.scale = JSON.parse(JSON.stringify(this.scale));
    },
  },
  name: 'EMWidgetGaugeChart',
  computed: {
    ...mapGetters(['getTagsList', 'getTagDataById', 'getTagDataByName']),
    legendWrapperCSS() {
      return this.isLegendHorizontal ? 'widget-gauge-wrapper-horz' : 'widget-gauge-wrapper';
    },
  },
  mounted() {
    this.reflowChart();
  },
  created() {
    this.getAllTags();
    this.title = this.widgetTitle;
    this.tag = this.widgetData.tag ? this.widgetData.tag : { id: '', name: '', unit: '' };
    this.legends = this.widgetData.legends ? this.widgetData.legends : [];
    this.settings.legends = JSON.parse(JSON.stringify(this.legends));
    this.settings.tag = JSON.parse(JSON.stringify(this.tag));
    this.scale = this.widgetData.scale ? this.widgetData.scale : { min: 0, max: 500 };
    this.settings.scale = JSON.parse(JSON.stringify(this.scale));
    this.setGaugeSeriesData();
  },
  mixins: [WidgetBasicMixin],
};
</script>

<style>
.widget-gauge-wrapper{
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;
  height:80%;
}
.widget-gauge-wrapper-horz{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}
.widget-gauge-color{
  width:30px;
  min-width:30px;
  height: 30px;
  position: relative;
}
.widget-gauge-legend{
  margin-top:10px;
  display: flex;
  align-items: center;
}
.tag-group{
  height: 55px;
}
.tag-group .v-input__slot{
  box-shadow: none !important;
}
.tag-group .v-input__control{
  min-height: 0 !important;
}
.widget-gauge-chart{
  width: 80%;
}
/* important necessary to override inline styles from the library*/
.widget-gauge-chart > div{
  width: 100% !important;
  height: 100% !important;
}
.widget-gauge-chart > div > div{
  width: 100% !important;
}
.widget-gauge-chart > div > div > svg{
  width: 100% !important;
  height: 100% !important;
}
.gauge-legend-horizontal .widget-gauge-chart{
  width:100%;
}
.w-33{
  max-width: 32.5% !important;
}
.w-20{
  width: 20% !important;
}
.w-50{
  width: 50% !important;
}

</style>
