const second = 1000;
const minute = 60 * second;
const hour = 60 * minute;
const day = 24 * hour;

const TimeFrameEnum = Object.freeze([
  {
    name: 'last minute', index: 'last_1_minute', value: minute,
  },
  {
    name: 'last 15 minutes', index: 'last_15_minutes', value: 15 * minute,
  },
  {
    name: 'last hour', index: 'last_1_hour', value: hour,
  },
  {
    name: 'last 6 hours', index: 'last_6_hours', value: 6 * hour,
  },
  {
    name: 'last day', index: 'last_day', value: day,
  },
]);

export default TimeFrameEnum;
