/* eslint-disable no-shadow */
import client from 'api-client';
import VueCookies from 'vue-cookies';

const state = {
  status: '',
  showAlert: false,
  alertText: '',
  alertType: '',
  clientToken: '',
  oldPwd: '',
  profile: {},
  userLanguage: '',
  groupData: {},
};

const getters = {
  getAuthStatus: (state) => state.status,
  getAlert: (state) => state.showAlert,
  getAlertText: (state) => state.alertText,
  getAlertType: (state) => state.alertType,
  getClientToken: (state) => state.clientToken,
  getUserProfile: (state) => state.profile,
  getOldPwd: (state) => state.oldPwd,
  getResetMode: (state) => {
    if (state.profile.passwordReset === 1 || state.profile.passwordReset === '1') return true;
    return false;
  },
  getUsername: (state) => state.profile.username,
  getEmail: (state) => (state.profile.email === '' ? '' : state.profile.email),
  getFullname: (state) => state.profile.fullName,
  getGroupData: (state) => state.groupData,
  getGroupApps: (state) => state.groupData.permissions,
  getIsTimeInUTC: (state) => state.profile.isTimezoneUTC,
};

const actions = {
  login({ commit }, loginData) {
    commit('authRequest');
    return new Promise((resolve) => {
      resolve(client.user.login(loginData)
        .then((resp) => {
          commit('saveLoginData', loginData);
          commit('authSuccess', resp);
          return resp;
        }));
    });
  },
  logout({ commit }) {
    commit('authLogout');
  },
  setAlert({ commit }, status) {
    commit('changeAlertStatus', status);
  },
  setAlertText({ commit }, text) {
    commit('changeAlertText', text);
  },
  setAlertType({ commit }, type) {
    commit('changeAlertType', type);
  },
  getUserData({ commit, dispatch }) {
    return new Promise((resolve) => {
      resolve(client.user.getUserData()
        .then((resp) => {
          commit('setUserData', resp);
          dispatch('getGroupList', resp.data.data.groupName);
          return resp;
        }));
    });
  },
  getGroupList({ dispatch }, name) {
    return new Promise((resolve) => {
      resolve(client.user.getGroupList()
        .then((resp) => {
          const group = resp.data.data.find((x) => x.groupName === name);
          dispatch('getGroupData', group.id);
          return resp;
        }));
    });
  },
  getGroupData({ commit }, groupId) {
    return new Promise((resolve) => {
      resolve(client.user.getGroupData(groupId)
        .then((resp) => {
          commit('setGroupData', resp.data.data);
          return resp;
        }));
    });
  },
  changePassword({ commit, dispatch }, payload) {
    return new Promise((resolve) => {
      resolve(client.user.updatePassword(payload)
        .then(() => {
          commit('clearDialog', 'changePassword');
          dispatch('getUserData');
        }));
    });
  },
  changeTimezonePreference({ commit }, payload) {
    return new Promise((resolve) => {
      resolve(client.user.updateUserDetails(payload)
        .then((resp) => {
          commit('setUserData', resp);
        }));
    });
  },
};

const mutations = {
  authRequest: (state) => {
    state.status = 'loading';
  },
  authSuccess: (state, payload) => {
    const { token, id, passwordReset } = payload.data.data;
    state.passwordReset = passwordReset;
    state.clientToken = token;
    document.cookie = `sessionToken=${token}; SameSite=Lax; Secure`;
    document.cookie = `loginId=${id}; SameSite=Lax`;
    state.status = 'success';
    if (passwordReset === 0) state.oldPwd = '';
  },
  authError: (state) => {
    state.status = 'error';
    document.cookie = 'sessionToken=; SameSite=Lax; Expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure';
    document.cookie = 'loginId=; SameSite=Lax;';
  },
  authLogout: (state) => {
    document.cookie = 'sessionToken=; SameSite=Lax; Expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure';
    document.cookie = 'loginId=; SameSite=Lax;';
    state.profile = {};
    state.status = 'out';
  },
  changeAlertStatus(state, status) {
    state.showAlert = status;
  },
  changeAlertText(state, text) {
    state.alertText = text;
  },
  changeAlertType(state, type) {
    state.alertType = type;
  },
  saveLoginData(state, payload) {
    state.oldPwd = payload.password;
  },
  setUserData: (state, resp) => {
    const data = { ...resp.data.data };
    state.profile = data;
    VueCookies.set('isTimezoneUTC', state.profile.isTimezoneUTC, Infinity);
  },
  setGroupData: (state, resp) => {
    state.groupData = resp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
