<template>
  <div class="d-flex">
    <v-icon class="mr-2"> mdi-calendar </v-icon>
    <v-datetime-picker
      :datetime="inputValue"
      :disabled="isDisabled"
      @input="dateTimeUpdated($event)"
      :color="inputDateColor"
      :textFieldProps="{ outlined: true, dense: true,
        prefix: type,
        'hide-details': true,
        error: !inputDateColor,
        color: 'buttons',
      }"
      :timePickerProps="{ format: '24hr', min: timeMin, max: timeMax, color: 'buttons' }"
      :datePickerProps="{ min: dateMin, max: dateMax, color: 'buttons'}"
      >
      <v-icon slot="dateIcon">mdi-calendar</v-icon>
      <v-icon slot="timeIcon">mdi-timer</v-icon>
    </v-datetime-picker>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'EMDatePicker',
  props: {
    type: {
      type: String,
      required: false,
      default: 'from',
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    dateMin: {
      type: String,
      required: false,
      default: '',
    },
    timeMin: {
      type: String,
      required: false,
      default: '',
    },
    dateMax: {
      type: String,
      required: false,
      default: '',
    },
    timeMax: {
      type: String,
      required: false,
      default: '',
    },
    inputValue: {
      type: [Date, String],
      required: false,
      default: '',
    },
    inputDateColor: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['date-time-changed'],
  data() {
    return {
      debouncedValue: null,
    };
  },
  methods: {
    dateTimeUpdated(value) {
      if (!value) {
        this.$emit('date-time-changed', '');
        return;
      }
      this.$emit('date-time-changed', value.toString());
    },
  },
  created() {
    this.debouncedValue = _.debounce(this.dateTimeUpdated, 1000);
  },
};
</script>

<style>

</style>
