/* eslint-disable no-shadow */
import client from 'api-client';

const state = {
  sysTime: 0,
  releaseNotesData: '',
  // Create a ENUM for the different states of the system data
  connectionStates: {
    CONNECTED: 'CONNECTED',
    CONNECTING: 'CONNECTING',
    DISCONNECTED: 'DISCONNECTED',
  },
  connectionState: '',
};

const getters = {
  systime: (state) => state.sysTime,
  getReleaseNotesData: (state) => state.releaseNotesData,
  getConnectionStates: (state) => state.connectionStates,
  getConnectionState: (state) => state.connectionState,
};

const actions = {
  getSysTime({ commit }) {
    return new Promise((resolve) => {
      resolve(client.systemData.getSysTime()
        .then((resp) => {
          const time = Date(resp.data.d);
          commit('setTime', time);
        }));
    });
  },
  getCurrentVersionReleaseNotes({ commit }) {
    return new Promise((resolve) => {
      resolve(client.systemData.getCurrentVersionReleaseNotes()
        .then((resp) => {
          commit('setReleaseNotesData', resp.data);
        }));
    });
  },
  getConnectionStatus({ commit, dispatch }, tagIds) {
    if (state.connectionState === state.connectionStates.DISCONNECTED) {
      dispatch('setConnectionRefresh');
    }
    return new Promise((resolve) => {
      resolve(client.systemData.getConnectionStatus(tagIds)
        .then((resp) => {
          commit('setConnectionStatus', resp.data);
        })
        .catch((err) => {
          if (err.response.status === 500) {
            commit('setConnectionStatus', { isAlive: false });
          }
          if (err.response.status === 422) {
            dispatch('setConnectionRefresh');
          }
        }));
    });
  },
  fetchConnectionStatusConfig() {
    const keyName = 'ConnectionStatusConfig';
    return new Promise((resolve) => {
      resolve(client.configurationData.getApplicationConfigData(keyName)
        .then((resp) => {
          const config = resp.data.data;
          return JSON.parse(config);
        })
        .catch(() => {
          const config = {
            interval: process.env.VUE_APP_VERIFY_VESSEL_CONNECTION_INTERVAL_S,
            tags: [process.env.VUE_APP_VESSEL_HEARTBEAT_TAG],
          };
          return config;
        }));
    });
  },
  setConnectionRefresh({ commit }) {
    commit('setConnectionStatus', {});
  },
};

const mutations = {
  setTime: (state, payload) => {
    state.sysTime = payload;
  },
  setReleaseNotesData: (state, mdText) => {
    state.releaseNotesData = mdText;
  },
  setConnectionStatus: (state, payload) => {
    if (payload.data && payload.data.isAlive) {
      state.connectionState = state.connectionStates.CONNECTED;
    } else if (Object.keys(payload).length === 0) {
      state.connectionState = state.connectionStates.CONNECTING;
    } else {
      state.connectionState = state.connectionStates.DISCONNECTED;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
