<template>
  <v-navigation-drawer class="menu-drawer" v-model="drawerState"
    absolute temporary hide-overlay>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <span class="text-capitalize py-2">{{ $t('welcome') }}, {{ getFullname }}</span>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link @click="setOpenedApp('Launchpad') && (drawerState = false)">
        <v-list-item-icon class="mr-4">
          <EMIcons name="Launchpad" width="30" />
        </v-list-item-icon>
        <v-list-item-content class="font-weight-medium text-capitalize pa-0">
          Home
        </v-list-item-content>
      </v-list-item>
      <div v-if="getGroupApps">
        <template>
          <v-list-item v-for="(app) in getAppList" :key="app.appId"
            class="d-flex py-2 align-center" dense
            @click="setOpenedApp(app.internalName) && (drawerState = false)">
            <v-list-item-icon class="mr-4">
              <EMIcons :name="app.internalName.replace(/ /g,'')" width="30" />
            </v-list-item-icon>
            <v-list-item-content class="font-weight-medium text-capitalize pa-0">
              {{ app.internalName }}
            </v-list-item-content>
          </v-list-item>
        </template>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import EMIcons from '@/components/SVG/EMIcons.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EMDrawer',
  components: { EMIcons },
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    ...mapActions(['setOpenedApp', 'toggleDrawer']),
  },
  computed: {
    ...mapGetters(['getDrawerState', 'getAppList', 'getFullname', 'getGroupApps']),
    drawerState: {
      get() {
        return this.getDrawerState;
      },
      set(drawerState) {
        this.toggleDrawer(drawerState);
      },
    },
  },
};
</script>

<style>
.menu-drawer {
  top: 48px !important;
  height: calc(100% - 48px) !important;
  z-index: 10;
}
</style>
