/* eslint-disable no-shadow */
import client from 'api-client';

// eslint-disable-next-line no-unused-vars
const state = {
  appList: [],
  openedApp: '',
  userMenuState: false,
  eventMenuState: {
    alert: false,
    advice: false,
  },
  helpMenuState: false,
  heartBeatMenuState: false,
  dateMenuState: false,
  drawerState: false,
};

const getters = {
  getOpenedApp: (state) => state.openedApp,
  getOpenedAppVersion: (state, getters) => getters.getAppList
    .find((x) => x.internalName === state.openedApp).versions,
  getOpenedAppURN: (state, getters) => getters.getAppList
    .find((x) => x.internalName === state.openedApp).baseUrl,
  getAppList: (state, getters) => {
    const appList = state.appList.filter((x) => x.appType === 'user' && x.internalName !== 'Launchpad')
      .sort((a, b) => a.internalName.localeCompare(b.internalName));
    const newlist = [];
    if (getters.getGroupApps) {
      appList.forEach((x) => {
        const filteredList = getters.getGroupApps.find((item) => item.appName === x.internalName);
        if (filteredList && filteredList.permissionCode !== 'A00') newlist.push(x);
      });
    }
    return newlist;
  },
  getUserMenuState: (state) => state.userMenuState,
  getEventMenuState: (state) => (menuType) => state.eventMenuState[menuType],
  getHelpMenuState: (state) => state.helpMenuState,
  getHeartBeatMenuState: (state) => state.heartBeatMenuState,
  getDateMenuState: (state) => state.dateMenuState,
  getDrawerState: (state) => state.drawerState,
};

const actions = {
  fetchAppList({ commit }) {
    return new Promise((resolve) => {
      resolve(client.configurationData.getApplicationList()
        .then((resp) => {
          commit('updateAppList', resp.data.data);
        }));
    });
  },
  setOpenedApp({ commit }, app) {
    if (app === 'Launchpad') {
      commit('closeOpenedApp', app);
    } else {
      commit('updateOpenedApp', app);
    }
  },
  toggleUserMenu({ commit }, userMenuState) {
    commit('changeUserMenuState', userMenuState);
  },
  toggleEventMenu({ commit }, payload) {
    commit('changeEventMenuState', payload);
  },
  toggleHelpMenu({ commit }, helpMenuState) {
    commit('changeHelpMenuState', helpMenuState);
  },
  toggleHeartBeatMenu({ commit }, heartBeatMenuState) {
    commit('changeHeartBeatMenuState', heartBeatMenuState);
  },
  toggleDateMenu({ commit }, dateMenuState) {
    commit('changeDateMenuState', dateMenuState);
  },
  toggleDrawer({ commit }, drawerState) {
    commit('changeDrawerState', drawerState);
  },
  closeAllMenu({ commit }) {
    commit('setFalseAllMenuState');
  },
};

const mutations = {
  updateAppList: (state, list) => {
    state.appList = list;
  },
  clearAppList: (state) => {
    state.appList = [];
  },
  updateOpenedApp: (state, app) => {
    state.openedApp = app;
  },
  closeOpenedApp: (state) => {
    state.openedApp = '';
  },
  changeUserMenuState: (state, userMenuState) => {
    state.userMenuState = userMenuState;
  },
  changeEventMenuState: (state, eventMenuState) => {
    state.eventMenuState[eventMenuState.menuType] = eventMenuState.menuState;
    const otherMenuType = eventMenuState.menuType === 'alert' ? 'advice' : 'alert';
    state.eventMenuState[otherMenuType] = false;
  },
  changeHelpMenuState: (state, helpMenuState) => {
    state.helpMenuState = helpMenuState;
  },
  changeHeartBeatMenuState: (state, heartBeatMenuState) => {
    state.heartBeatMenuState = heartBeatMenuState;
  },
  changeDateMenuState: (state, dateMenuState) => {
    state.dateMenuState = dateMenuState;
  },
  changeDrawerState: (state, drawerState) => {
    state.drawerState = drawerState;
  },
  setFalseAllMenuState: (state) => {
    state.userMenuState = false;
    state.eventMenuState.alert = false;
    state.eventMenuState.advice = false;
    state.helpMenuState = false;
    state.heartBeatMenuState = false;
    state.dateMenuState = false;
    state.drawerState = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
