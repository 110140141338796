<template>
  <div>
    <v-toolbar dense tile color="header" class="white--text">
      <v-row style="margin: -15px !important;">
        <v-col md="2" sm="1" class="pl-0 d-flex justify-start align-center justify-space-between">
          <v-app-bar-nav-icon data-testid="header-menu-icon"
          @click.stop="$emit('toggleDrawer')"
          color="iconsHeader" x-large tile class="mr-3" />
          <img data-testid="siemens-header-logo" class="siemens-header-logo"
            src="~assets/EM_SE_Logo.svg" />
          <v-divider dark vertical inset class="mx-3" />
        </v-col>
        <v-col lg="7" md="6" sm="5" class="d-flex justify-center">
          <div class="em-logo" @click="setOpenedApp('Launchpad')">
            <img data-testid="app-header-logo-icon" src="~assets/EM_Light.svg" class="em-icon"/>
            <div class="d-flex flex-column ml-2">
              <h2 class="em-title" data-testid="ecomainsuite-header-title"> EcoMAIN </h2>
              <h5 class="em-subtitle">
                Vessel {{ selectEnvironment }}
                <div class="em-version">v{{ systemVersion }}</div>
              </h5>
            </div>
          </div>
        </v-col>
        <v-col lg="3" md="4" sm="6" class="d-flex justify-start align-center justify-space-around">
          <EMConnectionHeartbeat />
          <EMDateMenu />
          <EMEventMenu type="advice" />
          <EMEventMenu type="alert" />
          <EMHelpMenu />
          <EMUserMenu />
          <EMReleaseNotes />
        </v-col>
      </v-row>
    </v-toolbar>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import version from '../../version';
import EMReleaseNotes from '../Dialog/EMReleaseNotes.vue';
import EMDateMenu from '../Menu/EMDateMenu.vue';
import EMHelpMenu from '../Menu/EMHelpMenu.vue';
import EMEventMenu from '../Menu/EMEventMenu.vue';
import EMUserMenu from '../Menu/EMUserMenu.vue';
import EMConnectionHeartbeat from '../ConnectionState/EMConnectionHeartbeat.vue';

export default {
  components: {
    EMDateMenu,
    EMHelpMenu,
    EMEventMenu,
    EMUserMenu,
    EMReleaseNotes,
    EMConnectionHeartbeat,
  },
  data() {
    return {
      buildVersion: version.BUILD_VERSION,
      copyright: version.COPYRIGHT,
      systemVersion: process.env.VUE_APP_EMS_CURRENT_VERSION,
    };
  },
  computed: {
    selectEnvironment() {
      const host = String(window.location.hostname);
      const vessel1 = ['ecomain-suite.siemens-energy.com'];
      const vessel2 = ['ecomain-suite-vessel2.siemens-energy', 'ecomainsuitevessel2b.westeurope.cloudapp.azure.com'];
      let env = '';

      if (vessel1.includes(host)) {
        env = '1';
      } else if (vessel2.includes(host)) {
        env = '2';
      }

      return env;
    },
  },
  methods: {
    ...mapActions(['toggleDrawer', 'getSysTime', 'setOpenedApp']),
  },
  created() {
    this.$crontab.disableJob('updateSysTimeCron');
  },
  mounted() {
    this.getSysTime();
    this.$crontab.enableJob('updateSysTimeCron');
  },
  beforeDestroy() {
    this.$crontab.disableJob('updateSysTimeCron');
  },
};
</script>

<style scoped>
.menu-drawer {
  top: 48px !important;
  height: calc(100% - 48px);
}
.em-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.em-title{
  font-weight: 500;
  margin-top: -2px;
  letter-spacing: 0.5px;
}
.em-subtitle{
  margin-top: -8px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.em-version {
  font-style: italic;
  font-size: 0.75em;
  padding: 0 0 1.5px 5px;
}
.em-icon {
  width: 25px;
}
.siemens-header-logo {
  width: 95px;
  height: 33px;
}

@media screen and (max-width: 800px) {
  .siemens-header-logo {
    display: none;
  }
}
@media screen and (max-width: 550px) {
  .em-title{
    font-size: 20px;
  }
  .em-subtitle{
    font-size: 13px;
    letter-spacing: 0.5px;
  }
}
</style>
