<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import client from 'api-client';
import { mapActions } from 'vuex';

export default {
  name: 'App',
  methods: {
    ...mapActions(['getSysTime', 'logout', 'setAlert', 'setAlertText', 'setAlertType', 'toggleDialog']),
    async exitToLogin() {
      await this.logout();
      this.setAlertText(this.$i18n.t('request_login'));
      this.setAlertType('error');
      this.setAlert(true);
      setTimeout(() => { this.$router.push('/login'); }, 7000);
    },
    verifyAuth() {
      const verifyToken = new Promise((resolve) => resolve(client.user.getTokenStatus()));
      verifyToken.then((resp) => {
        if (resp.data !== true) {
          const newToken = new Promise((resolve) => resolve(client.user.refreshToken()));
          newToken.then((response) => {
            if (!newToken) {
              this.exitToLogin();
            }
            document.cookie = `sessionToken=${response.data.data}; SameSite=Lax; Secure`;
          });
        }
      });
      verifyToken.catch(() => this.exitToLogin());
    },
  },
  created() {
    this.$vuetify.theme.dark = this.$cookies.get('theme') === 'dark';
    this.$i18n.locale = this.$cookies.get('language');
    this.$crontab.addJob({
      name: 'verifyAuthCron',
      interval: {
        seconds: `/${process.env.VUE_APP_VERIFY_AUTH_FETCH_INTERVAL_S}`,
      },
      job: this.verifyAuth,
    });
    this.$crontab.addJob({
      name: 'updateSysTimeCron',
      interval: {
        seconds: `/${process.env.VUE_APP_TIME_FETCH_INTERVAL_S}`,
      },
      job: this.getSysTime,
    });
  },
  beforeDestroy() {
    this.$crontab.deleteJob('verifyAuthCron');
    this.$crontab.deleteJob('updateSysTimeCron');
  },
};
</script>
<style lang="sass">
  @import '../node_modules/typeface-roboto/index.css'
</style>
<style lang="css">
  .tooltip-background {
    background-color: rgb(var(--v-theme-tooltip)) !important;
    color: #333333 !important;
    font-weight: bold !important;
  }
</style>
