<template>
    <div>
      <EMChart :options='options' ref='emChart'></EMChart>
      <EMOverlay
        :showOverlay="!chartHasData"
        :text="$t('no_values')"
        icon="mdi-database-off-outline"
      />
      <EMHoverAlert
        :showAlertWhileHovering="showNoDataAlert"
        :positionX="noDataAlertPos.x"
        :positionY="noDataAlertPos.y"
        :alertMessage="$t('no_values')"
        alertColor="tooltip"
        icon="mdi-database-off"
      />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EMChart from './EMChart.vue';
import EMOverlay from '../../../Overlay/EMOverlay.vue';
import EMHoverAlert from '../../../Alert/EMHoverAlert.vue';

export default {
  props: {
    series: {
      type: Array,
      required: true,
    },
  },
  components: {
    EMChart,
    EMOverlay,
    EMHoverAlert,
  },
  data() {
    return {
      totalValue: 0,
      tagData: [],
      showNoDataAlert: false,
      noDataAlertPos: { x: 0, y: 0 },
      emptyTagIdxList: [],
    };
  },
  methods: {
    generateTotalValue() {
      this.series.forEach((tag) => {
        const a = Math.trunc(Number(tag.value));
        this.totalValue += a;
      });
    },
    generatePercentageOfTags() {
      this.tagData = [];
      this.series.forEach((tag) => {
        const tagPercentage = (tag.value * 100) / (this.totalValue === 0 ? 1 : this.totalValue);
        const tagObject = {
          name: tag.name,
          y: tagPercentage,
          actualValue: tag.value,
          unit: tag.unit,
          visible: tag.value !== null,
        };
        this.tagData.push(tagObject);
      });
    },
    setNoDataAlertPosition(event) {
      const alertHeight = 56;
      const alertWidth = 143;
      const availableSpaceBelow = window.innerHeight - (event.clientY + alertHeight);
      if (availableSpaceBelow >= alertHeight) {
        this.noDataAlertPos.x = event.clientX - alertWidth / 2;
        this.noDataAlertPos.y = event.clientY + 9;
      } else {
        this.noDataAlertPos.x = event.clientX - alertWidth / 2;
        this.noDataAlertPos.y = event.clientY - alertHeight - 9;
      }
    },
  },
  watch: {},
  created() {
  },
  name: 'EMGaugeChartCreator',
  computed: {
    ...mapGetters(['getTagsList', 'getTagDataByName']),
    options() {
      const that = this;
      return {
        chart: {
          type: 'pie',
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          events: {
            render(event) {
              const CurrentEmptyTagIdxList = [];
              event.target.series[0].data.forEach((d, i) => {
                if (d.actualValue === null) {
                  CurrentEmptyTagIdxList.push(i);
                }
              });
              that.emptyTagIdxList.forEach((emptyTagIdx) => {
                if (!CurrentEmptyTagIdxList.includes(emptyTagIdx)) {
                  event.target.legend.allItems[emptyTagIdx]
                    .legendItem.on('mouseover', null);
                  event.target.legend.allItems[emptyTagIdx]
                    .legendItem.on('mouseout', null);
                }
              });
              if (event.target.series[0].data.length > 0
                && CurrentEmptyTagIdxList.length > 0) {
                CurrentEmptyTagIdxList.forEach((emptyTagHistIndex) => {
                  event.target.legend.allItems[emptyTagHistIndex].legendItem.on('mouseover', (e) => {
                    that.setNoDataAlertPosition(e);
                    that.showNoDataAlert = true;
                  });
                });
                CurrentEmptyTagIdxList.forEach((emptyTagHistIndex) => {
                  event.target.legend.allItems[emptyTagHistIndex].legendItem.on('mouseout', (e) => {
                    that.setNoDataAlertPosition(e);
                    that.showNoDataAlert = false;
                  });
                });
              }
            },
          },
        },
        title: {
          text: '',
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.actualValue:.2f} {point.unit}</b>',
        },
        legend: {
          labelFormat: '{name} [{unit}]',
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              color: 'var(--v-chartLegend-base)',
            },
            showInLegend: true,
          },
        },
        series: [
          {
            name: this.$t('value'),
            colorByPoint: true,
            data: this.getSeries,
          },
        ],
      };
    },
    getSeries() {
      this.generateTotalValue();
      this.generatePercentageOfTags();
      return this.tagData;
    },
    chartHasData() {
      return this.getSeries.length > 0
        && this.getSeries.some((series) => series.actualValue !== null);
    },
  },
};
</script>

<style>
/* important necessary to override inline styles from the library*/
.widget-pie-chart > div{
  width: 100% !important;
  height: 100% !important;
}
.widget-pie-chart > div > div{
  width: 100% !important;
}
.widget-pie-chart > div > div > svg{
  width: 100% !important;
  height: 100% !important;
}
</style>
