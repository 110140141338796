<template>
  <div style="position:relative;width:100%;height:100%">
    <div style="position:relative;width:100%;height:100%">
        <v-overlay :value="loadingAnimation" :opacity="0">
          <v-progress-circular :size="100" :width="7" color="buttons" indeterminate>
          </v-progress-circular>
        </v-overlay>
        <EMLineChartCreator
        class="widget-line-chart"
        :tags="this.tags"
        :isTimeInUTC="getIsTimeInUTC"
        :timeFrameValue="timeFrameValue"
        ref="lineChart"
        @setLoadingAnimation="updateloadingAnimation"
        ></EMLineChartCreator>
    </div>

    <EMWidgetSettings
      @dialogStateChangeEvent="changeSettingsDialogState"
      @saveSettingsEvent="saveTagSettings"
      :isOpen="settingsDialog"
      :title="title"
      :dialogWidth='800'
      :submitDisable="tagSettingsManagement.length > 0
      && editedTagSelectorsManagement.every((tag) => !tag.IsEdited)"
      :disableWarningMsg="$t('form_warnings.no_tags_selected')"
      ref="settings"
    >
      <div class="wdgt-settings-card-label">{{ $t('time_frame') }}</div>
      <div class="widget-time-picker">
        <div class="mb-4 mt-3 rounded-outline w-100">
          <EMTimeFrameSelect
            :select = "timeFrameSettingsValue"
            :timeFrames="timeFrames"
            :label="$t('timespan')"
            @timestampChanged="timeFrameSettingsValue = $event"
            :itemText="'name'"
            :itemValue="'value'"
          />
        </div>
      </div>
      <div class="d-flex">
        <div class="wdgt-settings-card-label">{{ $t('axis_settings') }}</div>
      </div>

      <div>
        <EMTextArea
          :inputValue="xAxisTitleSetting"
          :label="$t('x_axis_label')"
          @value-changed="xAxisTitleSetting = $event"
        />
      </div>

      <div class="d-flex mb-2">
        <div class="wdgt-settings-card-label">{{ $t('tags') }}</div>
        <v-spacer></v-spacer>
        <v-icon data-testid="add-tags-btn" medium @click="addTag"
        class="button-white-on-purple"
        :class="{ 'plus-button-disabled': tagLimitReached }"
        >mdi-plus</v-icon>
      </div>
      <div style="max-height: 150px; overflow-y: auto; overflow-x: hidden;">
        <div
          class="tag-group-settings text-center d-flex"
          v-for="(tag, index) in tagSettingsManagement"
          :key="index"
        >
          <div class="tag-group my-2 mr-1 rounded-outline" style="width: 500px;">
            <label>{{ $t('tag_name') }}</label>
            <v-container>
              <div class="text-center">
                <EMTagSelect
                  v-if="settingsDialog"
                  :tag="tag"
                  @updateTagValue="updateNameAndValue(index, $event)"
                />
              </div>
            </v-container>
          </div>
          <EMTextArea
            class="my-2 ml-1"
            :isDisabled="true"
            :inputValue="tagSettingsManagement[index].unit"
            :rules="[(v) => v.length <= 5 || $t('maxLength', { numb: 5 })]"
            :label="$t('x_axis_label')"
          />
          <EMDeleteButton class="pl-2" @removeElementEvent="removeTag(index)" />
        </div>
      </div>
    </EMWidgetSettings>
  </div>
</template>

<script>
import WidgetBasicMixin from '@/components/Widgets/mixins/widget-basic-mixin';
import { mapGetters, mapActions } from 'vuex';
import EMLineChartCreator from '@/components/Widgets/components/charts/EMLineChartCreator.vue';
import Enums from '@/components/Widgets/enums/index';
import EMTimeFrameSelect from './Inputs/EMTimeFrameSelect.vue';
import EMTextArea from './Inputs/EMTextArea.vue';
import COLORS from '../constants/Colors';

export default {
  name: 'EMWidgetLineChart',
  components: {
    EMLineChartCreator,
    EMTimeFrameSelect,
    EMTextArea,
  },
  data() {
    return {
      title: this.$t('line_chart'),
      timeFrames: Enums.TimeFrameEnum,
      timeFrameValue: Enums.TimeFrameEnum[0].value,
      timeFrameSettingsValue: Enums.TimeFrameEnum[0].value,
      tags: [],
      tagLimit: Number(process.env.VUE_APP_LINE_CHART_MAX_TAG),
      xAxisTitleSetting: this.$t('time'),
      unitAxis: [],
      loadingAnimation: false,
      xAxis: {
        type: 'datetime',
        title: {
          text: this.$t('time'),
        },
      },
      editedTagSelectors: [],
    };
  },
  props: {
    settingsDialog: {
      type: Boolean,
      default: false,
    },
    widgetData: {
      type: Object,
    },
    widgetTitle: {
      type: String,
    },
  },
  methods: {
    ...mapActions(['updateTagSettings', 'addTagSettings', 'removeTagSettings']),
    updateNameAndValue(index, tagObject) {
      if (!tagObject.tagId) {
        const editedTagPayload = {
          index,
          IsEdited: true,
        };
        this.editedTagSelectorsManagement = editedTagPayload;
        return;
      }
      this.tagSettingsManagement = {
        index,
        parameters: {
          name: tagObject.tagName,
          tagId: tagObject.tagId,
          value: 0,
          unit: tagObject.unit,
        },
      };
      const editedTagPayload = {
        index,
        IsEdited: false,
      };
      this.editedTagSelectorsManagement = editedTagPayload;
      this.$emit('updateTagWidgetEvent', tagObject.tagName);
    },
    getAllTags() {
      this.tagsTypeList = this.getTagsList;
    },
    addTag() {
      if (this.tagSettingsManagement.length === this.tagLimit) {
        return;
      }
      const tagSettingsPayload = {
        name: this.tagsTypeList[0].tagName,
        value: '',
        tagId: this.tagsTypeList[0].tagId,
        unit: '',
      };
      this.addTagSettings(tagSettingsPayload);
    },
    saveTagSettings(title) {
      this.title = title;
      this.tags = JSON.parse(JSON.stringify(this.tagSettingsManagement));
      this.timeFrameValue = this.timeFrameSettingsValue || Enums.TimeFrameEnum[0].value;
      this.xAxis.title.text = this.xAxisTitleSetting;
      this.$refs.lineChart.$refs.emChart.$refs.chart.chart.xAxis[0]
        .setTitle({ text: this.xAxis.title.text });
      const data = {
        timeFrame: this.timeFrameValue,
        xAxisLegend: this.xAxis.title.text,
        tags: this.tags,
      };
      const uniqueTagList = [...new Set(this.tags.map((tag) => tag.name))];
      this.$emit('updateSettingsWidgetEvent', title, data, uniqueTagList);
    },
    reflowChart() {
      if (this.$refs.lineChart) {
        this.$refs.lineChart.$refs.emChart.$refs.chart.chart.reflow();
      }
    },
    updateLiveData(tags) {
      const refChart = this.$refs.lineChart.$refs.emChart.$refs.chart;
      if (refChart === undefined) return;
      if (refChart.chart === undefined) return;
      if (refChart.chart.series.length === 0) return;
      refChart.chart.series.forEach((serie, i) => {
        let shift = false;
        if ((serie.data.length > 0) && (serie.data.find((x) => !!x).options.x
          < (new Date().getTime() - this.timeFrameValue
          - (Number(process.env.VUE_APP_DATA_RECORDING_MAX_INTERVAL_S) * 1000)))) {
          shift = true;
        }
        if (serie.data.length >= 0 && this.getTagDataByName(tags[i].name)) {
          if (serie.data.length === 0) {
            const tagData = {
              name: serie.name,
              data: serie.data,
              color: COLORS[i],
              unit: serie.unit,
              visible: true,
              marker: {
                symbol: '',
              },
            };
            serie.update(tagData);
          }
          const dataPoint = [Date.now(), this.getTagDataByName(tags[i].name).value];
          serie.addPoint(dataPoint, true, shift);
        }
      });
      if (refChart.chart.xAxis.length > 0) {
        refChart.chart.xAxis[0].setExtremes(
          new Date().getTime() - this.timeFrameValue,
          new Date().getTime(),
        );
      }
    },
    removeTag(index) {
      this.removeTagSettings(index);
    },
    updateLiveDataForTags() {
      this.updateLiveData(this.tags);
    },
    resetSettingChanges() {
      this.addTagSettings(JSON.parse(JSON.stringify(this.tags)));
      this.timeFrameSettingsValue = JSON.parse(JSON.stringify(this.timeFrameValue));
      this.xAxisTitleSetting = JSON.parse(JSON.stringify(this.xAxis.title.text));
    },
    updateloadingAnimation(status) {
      this.loadingAnimation = status;
    },
  },
  created() {
    this.getAllTags();
    if (Object.keys(this.widgetData).length > 0) {
      this.timeFrameValue = this.widgetData.timeFrame;
      this.xAxis.title.text = this.widgetData.xAxisLegend;
      this.timeFrameSettingsValue = JSON.parse(JSON.stringify(this.timeFrameValue));
      this.xAxisTitleSetting = JSON.parse(JSON.stringify(this.xAxis.title.text));
      this.tags = this.widgetData.tags;
      this.addTagSettings(JSON.parse(JSON.stringify(this.tags)));
    }
    this.title = this.widgetTitle;
  },
  computed: {
    ...mapGetters([
      'getTagDataById',
      'getTagDataByName',
      'getTagsList',
      'getTagSetting',
      'getIsTimeInUTC',
    ]),
    tagSettingsManagement: {
      get() {
        return this.getTagSetting;
      },
      set(tagObject) {
        this.updateTagSettings(tagObject);
      },
    },
    editedTagSelectorsManagement: {
      get() {
        return this.editedTagSelectors;
      },
      set(payload) {
        const editedTag = this.editedTagSelectors.find((tag) => tag.index === payload.index);
        if (editedTag) {
          editedTag.IsEdited = payload.IsEdited;
        } else {
          this.editedTagSelectors.push({ index: payload.index, IsEdited: payload.IsEdited });
        }
      },
    },
  },
  mounted() {
    this.$root.$on('shortDataRefreshed', this.updateLiveDataForTags);
    this.$nextTick(() => {
      this.reflowChart();
    });
  },
  beforeDestroy() {
    this.$root.$off('shortDataRefreshed', this.updateLiveDataForTags);
  },
  watch: {
    settingsDialog() {
      this.addTagSettings(JSON.parse(JSON.stringify(this.tags)));
    },
  },
  mixins: [WidgetBasicMixin],
};
</script>

<style scoped>
.widget-time-picker {
  display: flex;
}
.widget-time-picker input{
  width:100%;
}
.widget-line-chart{
  height:100%;
  width:100%;
}
.widget-line-timespan .v-text-field{
  margin-top:0 !important;
  padding-top:0 !important;
}
.axis-input input{
  width:100%;
}
.widget-line-chart > div{
  width: 100% !important;
  height: 100% !important;
}
.widget-line-chart > div > div{
  width: 100% !important;
}
.widget-line-chart > div > div > svg{
  width: 100% !important;
  height: 100% !important;
}
</style>
