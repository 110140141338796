<template>
  <v-select
    :dark="dark"
    class="ma-0 pa-0 select-language"
    style="font-size: 13px;"
    color="buttons"
    item-color="white"
    :items="flags"
    v-model="localSelected"
    @change="setLocale()"
    hide-details
    hide-selected
    :menu-props="menuProp"
    >
      <template v-slot:selection="{ item }">
        <img :data-testid="item.value" :src="require('../../assets/' + item.image)"
        style="width: 20px; height: 20px; margin-left: -15px; margin-top: 5px;"/>
      </template>
      <template v-slot:item="{ item }">
        <img :data-testid="item.value" :src="require('../../assets/' + item.image)"
        style="width: 20px; height: 20px;"/>
      </template>
  </v-select>
</template>

<script>

export default {
  props: {
    dark: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {
      flags: [
        { value: 'en', image: 'svg/en_flag.svg' },
        { value: 'de', image: 'svg/de_flag.svg' },
        { value: 'pt', image: 'svg/pt_flag.svg' },
      ],
      defaultSelected: 'en',
      localSelected: {},
      menuProp: { nudgeLeft: 20, nudgeBottom: 30 },
    };
  },
  computed: {
    Locales() {
      return this.$i18n.availableLocales.map((x) => ({ code: x, name: this.$i18n.t(x) }));
    },
  },
  methods: {
    setLocale() {
      this.$i18n.locale = this.localSelected;
      this.$cookies.set('language', this.$i18n.locale, Infinity);
    },
    resolveLocale() {
      if (this.Locales.some((locale) => locale.code === this.$i18n.locale)) {
        const local = this.flags.find((flag) => flag.value === this.$i18n.locale);
        this.localSelected = local;
        return;
      }
      this.localSelected = this.defaultSelected;
      this.setLocale();
    },
  },
  created() {
    this.resolveLocale();
  },
};
</script>

<style>
/* .v-menu__content{
  transform:translate(-31px, 27px);
} */

.select-language.v-text-field>.v-input__control>.v-input__slot:before {
  border-style: none;
}
.select-language.v-text-field>.v-input__control>.v-input__slot:after {
  border-style: none;
}

.select-language {
  width: 10px;

}
.v-list{
  padding: 0px 0px;
}
.v-list-item{
  min-height: 28px;
}
</style>
