<template>
   <v-menu v-model="menu" top nudge-bottom="105"
    nudge-left="16" :close-on-content-click="false">
    <template v-slot:activator="{ on }">
        <div :style="swatchStyle" v-on="on" />
            </template>
            <v-card>
            <v-card-text class="pa-0">
            <v-color-picker
            v-model="color"
            flat
            hide-mode-switch
            :swatches="swatches"
            show-swatches
            />
            <div class="color-picker-button">
              <v-btn @click="menu = false" small color="buttons">{{ $t('ok') }}</v-btn>
            </div>
            </v-card-text>
            </v-card>
            </v-menu>
 </template>

<script>

export default {
  data() {
    return {
      menu: false,
      color: '',
      swatches: [
        ['#FF0000', '#AA0000', '#550000'],
        ['#FFFF00', '#AAAA00', '#555500'],
        ['#00FF00', '#00AA00', '#005500'],
        ['#00FFFF', '#00AAAA', '#005555'],
        ['#0000FF', '#0000AA', '#000055'],
      ],
    };
  },
  props: {
    colorProp: {
      type: String,
      default: '',
    },
  },
  methods: {
    getColorProp() {
      this.color = this.colorProp;
    },
  },
  name: 'EMColorPicker',
  computed: {
    swatchStyle() {
      const { color, menu } = this;
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out',
        margin: '0 auto',
      };
    },
  },
  created() {
    this.getColorProp();
  },
  watch: {
    color(newColor) {
      this.$emit('change', newColor);
    },
    colorProp(newColor) {
      this.color = newColor;
    },
  },
};
</script>

<style scoped>
.color-picker-button{
  padding:5px;
  display:flex;
  justify-content: flex-end;
}
.color-picker-button > button {
  color:white;
}
</style>
