<template>
  <div>
    <EMChart :options='chartOptions' ref='emChart'></EMChart>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EMChart from './EMChart.vue';

export default {
  props: {
    series: {
      type: Object,
      required: true,
    },
    yAxis: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    isTimeInUTC: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  components: {
    EMChart,
  },
  name: 'EMHistogramChartCreator',
  methods: {},
  mounted() {},
  computed: {
    ...mapGetters([
      'getHCTagsDataById',
      'getHistoricalTagsDataByTagName',
      'getTagDataByName',
    ]),
    chartOptions() {
      const component = this;
      return {
        time: {
          useUTC: component.isTimeInUTC,
        },
        chart: {
          type: 'column',
        },
        title: {
          text: '',
        },
        yAxis: {
          ...this.yAxis,
          title: { style: { color: this.$vuetify.theme.dark ? 'white' : 'gray' } },
          labels: { style: { color: this.$vuetify.theme.dark ? 'white' : 'gray' } },
        },
        xAxis: {
          labels: {
            formatter() {
              return component.$parent.tag.unit ? `${this.value} [${component.$parent.tag.unit}]` : `${this.value}`;
            },
            style: {
              color: this.$vuetify.theme.dark ? 'white' : 'gray',
            },
          },
          title: {
            style: {
              color: this.$vuetify.theme.dark ? 'white' : 'gray',
            },
          },
        },
        tooltip: {
          formatter() {
            const binWidth = this.series.points.length > 1
              ? this.series.points[1].x - this.series.points[0].x : 0;
            const x1 = this.x;
            const x2 = this.x + binWidth;
            const header = `<span style="font-size:10px">${x1} - ${x2}</span></br><table>`;
            const body = `<tr>
            <td style="color:${this.color};padding:0">${this.series.name}: </td>
            <td style="padding:0"><b>${this.y}</b></td>
            </tr>`;
            const footer = '</table>';
            return header + body + footer;
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            borderWidth: 0,
            pointPadding: 0.1,
            color: this.color,
            dataLabels: {
              enabled: false,
              color: 'var(--v-chartLabel-base)',
              style: {
                textOutline: 'var(--v-chartLabel-base)',
              },
            },
          },
        },
        series: this.histogramData,
      };
    },
    histogramData() {
      const series = {
        name: this.$t('occurrences'),
        data: this.series.data,
        type: 'histogram',
        id: 's1',
        visible: true,
      };
      return [series];
    },
  },
  watch: {
    isTimeInUTC(value) {
      this.$refs.emChart.$refs.chart.chart.update({
        time: {
          useUTC: value,
        },
      });
    },
  },
  created() {
    this.chartOptions.yAxis = this.yAxis;
  },
};
</script>

<style>
.widget-histo-chart > div {
  width: 100% !important;
  height: 100% !important;
}
</style>
