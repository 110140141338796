var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{staticClass:"shadow-boxes",attrs:{"persistent":"","width":_vm.dialogWidth},model:{value:(_vm.settingsOpen),callback:function ($$v) {_vm.settingsOpen=$$v},expression:"settingsOpen"}},[_c('v-card',{attrs:{"data-testid":"wdgt-settings-dialog","elivation":"5"}},[_c('v-card-title',{staticClass:"text-h6 wdgt-settings-header"},[_c('div',{attrs:{"data-testid":"wdgt-settings-title"}},[_vm._v(_vm._s(_vm.$t('widgets_settings_title')))]),_c('v-spacer'),_c('v-col',{staticClass:"d-flex flex-row-reverse mr-n3",staticStyle:{"max-width":"100px"}},[_c('v-icon',{attrs:{"data-testid":"wdgt-settings-close-icon","medium":""},on:{"click":function($event){return _vm.discardChanges()}}},[_vm._v("mdi-close")])],1)],1),_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"wdgt-settings-card"},[_c('label',{staticClass:"wdgt-settings-card-label"},[_vm._v(_vm._s(_vm.$t('title')))]),_c('v-text-field',{staticClass:"rounded-outline",attrs:{"data-testid":"wdgt-title-input","label":_vm.$t('title'),"single-line":"","outlined":"","color":"buttons"},model:{value:(_vm.widgetTitle),callback:function ($$v) {_vm.widgetTitle=$$v},expression:"widgetTitle"}}),_vm._t("default")],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"pa-5",attrs:{"data-testid":"wdgt-settings-close-btn","text":"","outlined":"","color":"buttons"},on:{"click":function($event){return _vm.discardChanges()}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-menu',{attrs:{"top":"","offset-y":"","open-on-hover":"","id":"disable-btn-alert"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"ml-2"},'div',attrs,false),!_vm.submitDisable ? on : {}),[_c('v-btn',{class:{
                  'primary-button-disabled': !_vm.submitDisable,
                  'button-white-on-purple': _vm.submitDisable,
                  'pa-5': true,
                },attrs:{"data-testid":"wdgt-settings-save-btn","disabled":!_vm.submitDisable,"text":""},on:{"click":_vm.openConfirmSave}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")])],1)]}}])},[_c('v-alert',{attrs:{"dense":"","color":"warning","icon":"mdi-alert-circle","elevation":"0"}},[_vm._v(" "+_vm._s(_vm.disableWarningMsg)+" ")])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"width":"450"},model:{value:(_vm.confirmSave),callback:function ($$v) {_vm.confirmSave=$$v},expression:"confirmSave"}},[_c('v-card',{attrs:{"elivation":"5"}},[_c('v-card-text',[_c('div',{staticClass:"d-flex flex-column text-center widget-confirmSettings"},[_c('div',{staticClass:"widget-confirmSettings-close"},[_c('v-icon',{attrs:{"large":""},on:{"click":function($event){_vm.confirmSave = false}}},[_vm._v("mdi-close")])],1),_c('div',{staticClass:"widget-confirmSettings-alert mt-10"},[_c('v-icon',{attrs:{"color":"attentionColor","size":"120"}},[_vm._v("mdi-alert-circle")])],1),_c('div',{staticClass:"widget-confirmSettings-text mb-6"},[_c('h5',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('confirm_changes_title')))]),_c('h6',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm.$t('confirm_changes_subtitle')))])]),_c('div',{staticClass:"widget-confirmSettings-buttons d-flex justify-space-between my-0"},[_c('v-btn',{staticClass:"text-uppercase py-5 w-48 font-weight-bold border-width",attrs:{"text":"","outlined":"","color":"border"},on:{"click":function($event){return _vm.discardChanges()}}},[_c('h4',[_vm._v(_vm._s(_vm.$t('discard_changes')))])]),_c('v-btn',{staticClass:"button-white-on-purple text-uppercase py-5 w-48\n            font-weight-bold border-width shadow-boxes",attrs:{"text":"","outlined":"","data-testid":"confirm-save-btn"},on:{"click":_vm.saveSettings}},[_c('h4',[_vm._v(_vm._s(_vm.$t('save_and_close')))])])],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }