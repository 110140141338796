<template>
  <v-menu
    offset-y
    v-model="dateMenuState"
    :close-on-content-click="false">
    <template v-slot:activator="{ on: onMenu, attrs: menuAttrs }">
      <v-tooltip bottom color="tooltip" contentClass="tooltip-background">
        <template v-slot:activator="{ on: onTooltip, attrs: tooltipAttrs }">
          <v-btn text tile color="iconsHeader" v-bind="menuAttrs" v-on="onMenu"
            class="md-btn-toolbar text-body-2 btn-date"
            data-testid="btn-date-header"
          >
            <div v-bind="tooltipAttrs" v-on="onTooltip">
              {{ getSysTimeFormatted.hours }}
            </div>
            <div v-bind="tooltipAttrs" v-on="onTooltip">
              {{ getSysTimeFormatted.date }}
            </div>
          </v-btn>
        </template>
        <span>{{ $t('date_menu.tooltip_txt') }}</span>
      </v-tooltip>
    </template>
    <v-card class="mt-2" style="width: 300px;">
      <v-list class="bg-date-menu">
        <v-list-item>
          <v-list-item-content class="py-3">
            <v-list-item-title class="text-h6 font-weight-medium date-menu-title">
              <v-row align="center" justify="space-between" class="w-100 ma-0">
                {{ getSysTimeFormatted.hours  }}
                <v-switch
                  :input-value="getIsTimeInUTC"
                  @change="toggleTimezone"
                  inset
                  color="buttons"
                  :label="getIsTimeInUTC ? 'UTC' : $t('date_menu.local')"
                ></v-switch>
              </v-row>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ dateInWords }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider class="divider"/>
      <v-list class="d-flex justify-center align-center">
        <v-date-picker color="buttons" class="disable-click" readonly no-title
          next-icon='' prev-icon=''/>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
/* eslint-disable prefer-template */
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EMDateMenu',
  data() {
    return {
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    };
  },
  computed: {
    ...mapGetters(['systime', 'getDateMenuState', 'getUserProfile', 'getIsTimeInUTC']),
    dateMenuState: {
      get() {
        return this.getDateMenuState;
      },
      set(value) {
        this.toggleDateMenu(value);
      },
    },
    getSysTimeFormatted() {
      if (this.getIsTimeInUTC) {
        const hours = `${('0' + new Date(this.systime)
          .getUTCHours()).slice(-2)}:${('0' + new Date(this.systime)
          .getUTCMinutes()).slice(-2)} UTC`;
        const date = new Date(this.systime)
          .toISOString().replace(/T/, ' ').replace(/\..+/, '')
          .substring(0, 10);
        return { hours, date };
      }
      const hours = `${('0' + new Date(this.systime)
        .getHours()).slice(-2)}:${('0' + new Date(this.systime)
        .getMinutes()).slice(-2)} ${this.$t('date_menu.local')}`;
      const date = new Date(this.systime)
        .toISOString().replace(/T/, ' ').replace(/\..+/, '')
        .substring(0, 10);
      return { hours, date };
    },
    dateInWords() {
      const date = new Date(this.systime);
      if (this.getIsTimeInUTC) {
        return `${this.days[date.getUTCDay()]}, ${this.months[date.getUTCMonth()]} ${date.getUTCDate()}, ${date.getUTCFullYear()}`;
      }
      return `${this.days[date.getDay()]}, ${this.months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
    },
  },
  methods: {
    ...mapActions(['toggleDateMenu', 'changeTimezonePreference']),
    async toggleTimezone(value) {
      const updatedUserProfile = { ...this.getUserProfile, isTimezoneUTC: value };
      await this.changeTimezonePreference(updatedUserProfile);
    },
  },
};
</script>

<style>
.disable-click {
  pointer-events: none
}
.md-btn-toolbar  {
  padding: 0 5px !important;
}
.bg-date-menu {
  background-color: var(--v-menu-base) !important;
}
.btn-date {
  white-space: nowrap;
}
.btn-date .v-btn__content, .date-menu-title .v-list-item__title {
  display: table-caption;
}
</style>
