<template>
  <div style="position:relative;width:100%;height:100%">
    <div v-if="!noValues" style="position:relative;width:100%;height:100%">
      <v-overlay :value="loadingAnimation" :opacity="0">
        <v-progress-circular :size="100" :width="7" color="buttons" indeterminate>
        </v-progress-circular>
      </v-overlay>
      <EMHistogramChartCreator
        ref="histChart"
        :series="series"
        :isTimeInUTC="getIsTimeInUTC"
        :yAxis="yAxis"
        :color="barColor"
        class="widget-histo-chart"
      ></EMHistogramChartCreator>
    </div>
    <EMOverlay
      :showOverlay="noValues && !loadingAnimation"
      :text="$t('no_values')"
      icon="mdi-database-off-outline"
    />
    <EMWidgetSettings
      @dialogStateChangeEvent="changeSettingsDialogState"
      @saveSettingsEvent="saveTagSettings"
      :isOpen="settingsDialog"
      :title="title"
      :dialogWidth='800'
      :disableWarningMsg="disabledSaveBtnMsg"
      ref="settings"
      :submitDisable="(Object.keys(tagSettings).length > 0 && compareDates)
        && !editedTagSelector"
    >
      <div class="tag-group my-3 mr-1 rounded-outline">
        <label>{{ $t('tag_name') }}</label>
        <v-container>
          <div class="text-center">
            <EMTagSelect
              v-if="settingsDialog"
              :tag="tagSettings"
              @updateTagValue="updateNameAndValue($event)"
            />
          </div>
        </v-container>
      </div>

      <div class="d-flex">
        <div class="wdgt-settings-card-label">{{ $t('axis_settings') }}</div>
      </div>

      <div class="d-flex">
        <div class="my-5 rounded-outline">
          <EMDatePicker
            type='from'
            :inputValue="new Date(timeFrameSettingsValue.timeFrameFrom)"
            dateMin="2018-06-07"
            timeMin="00:00"
            :dateMax="dateMaxLimit"
            :inputDateColor="compareDates"
            @date-time-changed="timeFrameSettingsValue.timeFrameFrom = $event"
          />
        </div>
        <div class="my-5 ml-3 rounded-outline">
          <EMDatePicker
            type='to'
            :inputValue="new Date(timeFrameSettingsValue.timeFrameTo)"
            :dateMin="dateMinLimit"
            :timeMin="timeMinLimit"
            :inputDateColor="compareDates"
            @date-time-changed="timeFrameSettingsValue.timeFrameTo = $event"
          />
        </div>
      </div>

      <div class="d-flex axis-input">
        <div class="my-5 w-50">
          <EMTextArea
            data-testid="y-axis-label"
            :inputValue="yAxisSettingsTitle"
            :label="$t('y_axis_label')"
            @value-changed="yAxisSettingsTitle = $event"
          />
        </div>
          <div class='tag-group color-picker my-5 ml-3'>
            <label>{{ $t('color') }}</label>
            <v-container>
              <div class='text-center'>
                <colorPicker :colorProp="binSettings.color"
                @change="val => binSettings.color = val" />
              </div>
            </v-container>
          </div>
      </div>

      <div>
        <EMSlider
          :inputValue="binSettings.size"
          :min="1"
          :label="$t('number_of_bins')"
        />
      </div>
    </EMWidgetSettings>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import WidgetBasicMixin from '../mixins/widget-basic-mixin';
import EMHistogramChartCreator from './charts/EMHistogramChartCreator.vue';
import EMColorPicker from '../../ColorPicker/EMColorPicker.vue';
import EMTextArea from './Inputs/EMTextArea.vue';
import EMDatePicker from './Inputs/EMDatePicker.vue';
import EMSlider from './Inputs/EMSlider.vue';
import EMOverlay from '../../Overlay/EMOverlay.vue';

const dateTimeNow = new Date();
const dateTimePast = new Date(dateTimeNow.getTime() - (1000 * 60 * 60));

export default {
  name: 'EMWidgetHistogramChart',
  props: {
    settingsDialog: {
      type: Boolean,
      default: false,
    },
    widgetData: {
      type: Object,
    },
    widgetTitle: {
      type: String,
    },
  },
  components: {
    EMHistogramChartCreator,
    colorPicker: EMColorPicker,
    EMTextArea,
    EMDatePicker,
    EMSlider,
    EMOverlay,
  },
  data() {
    return {
      title: '',
      timeFrameKey: '',
      tag: {},
      tagSettings: {},
      series: {},
      rawSeries: [],
      bins: 10,
      binMax: 100,
      binMin: 1,
      barColor: '#641e8c',
      dataConfig: {},
      timeFrameFrom: dateTimePast.toISOString().substring(0, 16),
      timeFrameTo: dateTimeNow.toISOString().substring(0, 16),
      binSettings: { color: '#641e8c', size: 10 },
      timeFrameSettingsValue: {
        timeFrameFrom: dateTimePast.toISOString().substring(0, 16),
        timeFrameTo: dateTimeNow.toISOString().substring(0, 16),
      },
      yAxisSettingsTitle: '',
      yAxis: {
        title: {
          text: '',
        },
      },
      loadingAnimation: false,
      editedTagSelector: false,
    };
  },
  methods: {
    ...mapActions(['fetchTagHistoricalData', 'setAlert', 'setAlertText', 'setAlertType']),
    ...mapMutations(['removeHCTagData']),
    updateNameAndValue(tagObject) {
      if (Object.keys(tagObject).length === 0) {
        this.editedTagSelector = true;
        return;
      }
      this.tagSettings.name = tagObject.tagName;
      this.tagSettings.tagId = tagObject.tagId;
      this.tagSettings.unit = tagObject.unit;
      this.yAxisSettingsTitle = tagObject.tagName;
      this.editedTagSelector = false;
      this.$emit('updateTagWidgetEvent', tagObject.tagName);
    },
    getAllTags() {
      this.tagsTypeList = this.getTagsList;
    },
    /*
     * Checks if new changes require data to be fetched
     * and/or resampled.
     * Changes that require new data to be fetched:
     *  - Time from and/or to
     *  - Tag Name
     * Changes that require data to be resampled:
     *  - Number of bins
    */
    saveTagSettings(title) {
      let needToFetchAgain = false;
      let needToSample = false;
      if (this.timeFrameTo === undefined
        || this.timeFrameFrom === undefined
        || this.tag === undefined) {
        needToFetchAgain = true;
      } else if (this.timeFrameTo !== this.timeFrameSettingsValue.timeFrameTo
        || this.timeFrameFrom !== this.timeFrameSettingsValue.timeFrameFrom) {
        needToFetchAgain = true;
      } else if (this.tag.tagId !== this.tagSettings.tagId) {
        needToFetchAgain = true;
      }
      if (this.bins !== this.binSettings.size) {
        needToSample = true;
      }
      this.title = title;
      this.tag = JSON.parse(JSON.stringify(this.tagSettings));
      this.timeFrameTo = this.timeFrameSettingsValue.timeFrameTo;
      this.timeFrameFrom = this.timeFrameSettingsValue.timeFrameFrom;
      this.barColor = this.binSettings.color;
      this.bins = this.binSettings.size;
      this.yAxis.title.text = JSON.parse(JSON.stringify(this.yAxisSettingsTitle));
      const data = {
        tags: this.tag,
        timeFrameFrom: this.timeFrameFrom,
        timeFrameTo: this.timeFrameTo,
        bins: this.bins,
        barColor: this.barColor,
        yAxisLegend: this.yAxis.title.text,
      };
      this.dataConfig = data;
      const uniqueTagList = [this.tag.name];
      this.$emit('updateSettingsWidgetEvent', title, data, uniqueTagList);
      if (needToFetchAgain && this.tag.name) {
        this.generateDataPlot();
      } else if (needToSample && this.tag.name) {
        this.updateDataPlot();
      }
    },
    reflowChart() {
      this.$refs.histChart.$refs.emChart.$refs.chart.chart.reflow();
    },
    generateDataPlot() {
      this.series = {};
      this.loadingAnimation = true;
      if (typeof this.timeFrameFrom === 'string' && typeof this.timeFrameTo === 'string') {
        const localTimeFrameFrom = new Date(`${this.timeFrameFrom}`);
        const localTimeFrameTo = new Date(`${this.timeFrameTo}`);
        this.timeFrameKey = `${localTimeFrameFrom.toISOString()}${localTimeFrameTo.toISOString()}`;
        this.fetchTagHistoricalData({
          tag: this.tag.name,
          timeFrameFrom: localTimeFrameFrom,
          timeFrameTo: localTimeFrameTo,
          timeFrameKey: this.timeFrameKey,
        });
      } else {
        this.timeFrameKey = `${this.timeFrameFrom.toISOString()}${this.timeFrameTo.toISOString()}`;
        this.fetchTagHistoricalData({
          tag: this.tag.name,
          timeFrameFrom: this.timeFrameFrom,
          timeFrameTo: this.timeFrameTo,
          timeFrameKey: this.timeFrameKey,
        });
      }
    },
    updateDataPlot() {
      const tagData = {
        name: this.tag.name,
        unit: this.tag.unit,
        bins: this.bins,
        data: this.getHistoricalTagsDataByTagName(this.tag.name, this.timeFrameKey),
      };
      tagData.data = this.generateBins(tagData.data);
      this.updateSettingsConfig(tagData.data);
      this.series = tagData;
      this.removeHCTagData({ tag: this.tag.name, timeFrameKey: this.timeFrameKey });
    },
    refineSeries(dataSeries) {
      const simpleSeries = [];
      dataSeries.forEach((element) => {
        simpleSeries.push(element[1]);
      });
      return simpleSeries;
    },
    generateBins(rawSeries) {
      const hData = [];
      const categories = [];
      const data = this.refineSeries(rawSeries);
      const size = data.length;
      let numberOfBins = this.bins;
      const max = Math.max.apply(null, data);
      const min = Math.min.apply(null, data);
      const range = max - min;
      const width = range / numberOfBins;
      numberOfBins = width === 0 ? 1 : numberOfBins;
      let binBottom;
      let binTop;

      for (let i = 0; i < numberOfBins; i += 1) {
        binBottom = min + (i * width);
        binTop = binBottom + width;
        categories.push(`${Number.parseFloat(binBottom).toPrecision(2)} - ${Number.parseFloat(binTop).toPrecision(2)}`);
        if (!hData[i]) {
          hData[i] = [];
          hData[i][0] = binBottom;
        }
        hData[i][1] = !hData[i][1] ? 0 : hData[i][1];
        for (let j = 0; j < size; j += 1) {
          const x = data[j];
          if (i === 0 && j === 0) {
            binBottom -= 1;
          }
          if (x > binBottom && x <= binTop) {
            hData[i][1] = !hData[i][1] ? 1 : hData[i][1] += 1;
          }
        }
      }
      this.binWidth = width;
      return hData;
    },
    updateSettingsConfig(binConfig) {
      this.dataConfig.binConfiguration = binConfig;
      this.$emit('updateSettingsWidgetEvent', this.title, this.dataConfig, this.tag.name);
    },
    resetSettingChanges() {
      this.tagSettings = JSON.parse(JSON.stringify(this.tag));
      this.timeFrameSettingsValue.timeFrameFrom = this.timeFrameFrom;
      this.timeFrameSettingsValue.timeFrameTo = this.timeFrameTo;
      this.binSettings.color = this.barColor;
      this.binSettings.size = this.bins;
      this.yAxisSettingsTitle = JSON.parse(JSON.stringify(this.yAxis.title.text));
    },
  },
  created() {
    this.getAllTags();
    this.title = this.widgetTitle;
    if (Object.keys(this.widgetData).length > 0) {
      this.dataConfig = this.widgetData;
      this.tag = this.widgetData.tags;
      this.tagSettings = JSON.parse(JSON.stringify(this.tag));
      this.timeFrameFrom = this.widgetData.timeFrameFrom;
      this.timeFrameTo = this.widgetData.timeFrameTo;
      this.timeFrameSettingsValue.timeFrameFrom = this.widgetData.timeFrameFrom;
      this.timeFrameSettingsValue.timeFrameTo = this.widgetData.timeFrameTo;
      this.barColor = this.widgetData.barColor;
      this.bins = this.widgetData.bins;
      this.binSettings.color = this.widgetData.barColor;
      this.binSettings.size = this.widgetData.bins;
      this.yAxis.title.text = JSON.parse(JSON.stringify(this.widgetData.yAxisLegend));
      this.yAxisSettingsTitle = JSON.parse(JSON.stringify(this.widgetData.yAxisLegend));
      if (this.tag.name) {
        this.generateDataPlot();
      }
    }
  },
  computed: {
    ...mapGetters(['getHistoricalDataLoading', 'getHistoricalTagsDataByTagName', 'getIsTimeInUTC']),
    getLoadingState() {
      return this.getHistoricalDataLoading(this.tag.name, this.timeFrameKey);
    },
    dateMinLimit() {
      if (typeof this.timeFrameSettingsValue.timeFrameFrom === 'object'
        && this.timeFrameSettingsValue.timeFrameFrom !== null) {
        return this.timeFrameSettingsValue.timeFrameFrom.toISOString().split('T')[0];
      }
      return '2018-06-07';
    },
    timeMinLimit() {
      if (typeof this.timeFrameSettingsValue.timeFrameFrom === 'object'
        && this.timeFrameSettingsValue.timeFrameFrom !== null) {
        const timeZoneDiff = this.timeFrameSettingsValue.timeFrameFrom.getTimezoneOffset() * 60000;
        return new Date(this.timeFrameSettingsValue.timeFrameFrom.getTime() - timeZoneDiff).toISOString().split('T')[1];
      }
      return '00:00:00';
    },
    dateMaxLimit() {
      if (typeof this.timeFrameSettingsValue.timeFrameTo === 'object'
        && this.timeFrameSettingsValue.timeFrameTo !== null) {
        return this.timeFrameSettingsValue.timeFrameTo.toISOString().split('T')[0];
      }
      return new Date().toISOString().split('T')[0];
    },
    compareDates() {
      if (!this.timeFrameSettingsValue.timeFrameFrom || !this.timeFrameSettingsValue.timeFrameTo) {
        return false;
      }
      if (typeof this.timeFrameSettingsValue.timeFrameFrom === 'object'
        && this.timeFrameSettingsValue.timeFrameFrom !== null
        && typeof this.timeFrameSettingsValue.timeFrameTo === 'object'
        && this.timeFrameSettingsValue.timeFrameTo !== null) {
        if (new Date(this.timeFrameSettingsValue.timeFrameFrom)
          > new Date(this.timeFrameSettingsValue.timeFrameTo)) {
          return false;
        }
      }
      return true;
    },
    noValues() {
      if (this.series.data !== undefined && this.series.data.length > 0) {
        return this.series.data.every((value) => value[0].toString() === 'NaN');
      }
      return false;
    },
    disabledSaveBtnMsg() {
      if (Object.keys(this.tagSettings).length === 0 && this.compareDates) {
        return this.$t('form_warnings.no_tags_selected');
      }
      if (!this.compareDates && Object.keys(this.tagSettings).length > 0) {
        return this.$t('form_warnings.no_time_frame_selected');
      }
      return this.$t('form_warnings.no_time_frame_and_tags_selected');
    },
  },
  watch: {
    getLoadingState(newState, oldState) {
      if (!newState && oldState) {
        this.updateDataPlot();
        this.loadingAnimation = false;
      }
    },
  },
  mixins: [WidgetBasicMixin],
};
</script>

<style scoped>
.color-picker {
  width: 50%;
}
.widget-time-picker {
  display: flex;
}
.widget-time-picker input{
  width:100%;
}
.widget-histo-chart{
  height:100%;
  width:100%;
}
.widget-line-timespan .v-text-field{
  margin-top:0 !important;
  padding-top:0 !important;
}
.axis-input input{
  width:100%;
}
.widget-histo-chart > div{
  width: 100% !important;
  height: 100% !important;
}
.widget-histo-chart > div > div{
  width: 100% !important;
}
.widget-histo-chart > div > div > svg{
  width: 100% !important;
  height: 100% !important;
}
</style>
