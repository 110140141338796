<template>
  <div>
    <EMWidgetDrawer />
    <div class="widget-loader" v-if="isLoading || loading">
      <v-progress-circular
        :size="50"
        color="buttons"
        indeterminate
      ></v-progress-circular>
    </div>
    <div id="content" v-if="!isLoading && !loading" data-testid='widget-board'>
      <grid-layout style="min-height:500px;" ref="gridlayout"
        :layout="widgets"
        :col-num="5"
        :row-height="30"
        :is-draggable="isDraggable"
        :is-resizable="true"
        :vertical-compact="true"
        :use-css-transforms="true"
        :responsive="true"
      >
        <grid-item :key="item.i" v-for="(item, index) in widgets"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :minW="item.minW"
          :minH="item.minH"
          :h="item.h"
          :i="item.i"
          autoSize= true
          class="widget-card"
          @resized="updateWidgetConfigDataOnMove()"
          @moved="updateWidgetConfigDataOnMove()"
          @layout-updated="updateWidgetConfig(widgets)"
          :data-testid="`full-widget-`+ index"
        >
          <EMWidget :key="item.i" :component="item"
          v-bind:isLoaded="true"
          v-bind:widgetId="item.i"
          :preserveAspectRatio="true"
          @updateWidgetConfigData="updateWidgetConfigData"
          @dialogStateChangeEvent="updateSettingsDialogState"
          >
            <v-col class="d-flex justify-content-center mr-n6"
            style="max-width:100px;min-width:0">
              <div>
                <v-icon :data-testid="`widget-settings-btn-`+ index"
                medium @click="openSettings(item.i)" class="icon-size-20 mr-3">
                  mdi-cog
                </v-icon>
                <v-icon :data-testid="`widget-delete-btn-`+ index" style="z-index:6"
                medium @click="toggleDeleteDialog(item.i)">mdi-close</v-icon>
              </div>
            </v-col>
          </EMWidget>
        </grid-item>
      </grid-layout>
    </div>
    <EMDialog id="deleteWidgetDialog">
      <v-card-title class="text-h6" style="word-break:normal;">
        {{ $t('delete_widget') }}
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <EMButton data-testid='wdgt-delete-no-btn' outlined
        text @click.native="closeDialog('deleteWidgetDialog')">
          {{ $t('no') }}
        </EMButton>
        <EMButton data-testid='wdgt-delete-yes-btn' primaryButtons
          text @click.native="removeItem()">
          {{ $t('yes') }}
        </EMButton>
      </v-card-actions>
    </EMDialog>
  </div>
</template>

<script>
import { GridLayout, GridItem } from 'vue-grid-layout';
import EMWidget from '@/components/Widgets/EMWidget.vue';
import ComponentListMixin from '@/components/Widgets/mixins/component-list-mixin';
import { mapActions, mapMutations, mapGetters } from 'vuex';
import EMWidgetDrawer from '@/components/WidgetDrawer/EMWidgetDrawer.vue';
import EMDialog from '@/components/Dialog/EMDialog.vue';
import EMButton from '@/components/Buttons/EMButton.vue';

const mouseXY = { x: null, y: null };
const DragPos = {
  x: null, y: null, w: 1, h: 1, i: null,
};

export default {
  components: {
    GridLayout,
    GridItem,
    EMWidget,
    EMWidgetDrawer,
    EMDialog,
    EMButton,
  },
  mixins: [ComponentListMixin],
  data() {
    return {
      isDraggable: true,
      widgets: [],
      loading: true,
      deleteWidgetDialog: {
        id: '',
        state: false,
      },
    };
  },
  mounted() {
    document.addEventListener('dragover', (e) => {
      mouseXY.x = e.clientX;
      mouseXY.y = e.clientY;
    }, false);

    this.$root.$on('widgetDrag', (data) => {
      this.drag(data);
    });

    this.$root.$on('widgetDragend', (data) => {
      this.dragend(data);
    });

    this.$root.$on('updateWidgetConfig', () => {
      this.updateWidgetConfig(this.widgets);
    });

    this.$root.$on('setDraggable', (value) => {
      this.setDraggable(value);
    });
  },
  created() {
    this.getUserData().then(() => {
      this.fetchWidgetConfig().then((resp) => {
        if (resp !== undefined) {
          this.widgets = JSON.parse(JSON.stringify(this.getWidgetsConfig));
          this.loading = false;
        }
      });
    });
    this.$crontab.enableJob('shortDataRefreshCron');
    this.$crontab.enableJob('longDataRefreshCron');
  },
  computed: {
    ...mapGetters(['getWidgetsConfig', 'getIsLoadingState']),
    isLoading() {
      return this.getIsLoadingState;
    },
  },
  beforeDestroy() {
    this.$crontab.disableJob('shortDataRefreshCron');
    this.$crontab.disableJob('longDataRefreshCron');
  },
  methods: {
    ...mapActions(['updateWidgetConfig', 'fetchWidgetConfig',
      'getUserData', 'deleteWidget', 'refreshActualTagsData',
      'getTagDataByIds', 'fetchTagDataByName', 'getDefaultTagValue',
      'closeDialog', 'toggleDialog',
    ]),
    ...mapMutations(['toggleWidgetSettingsDialog']),
    ...mapActions(['closeDialog', 'getCurrentVersionReleaseNotes']),
    shortDataRefreshWrapper() {
      this.refreshActualTagsData();
      this.$root.$emit('shortDataRefreshed');
    },
    longDataRefreshWrapper() {
      this.$root.$emit('longDataRefreshed');
    },
    /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
    drag(data) {
      const parentRect = document.getElementById('content').getBoundingClientRect();
      let mouseInGrid = false;

      // Check if mouse is inside dashboard bounds
      if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right))
        && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
        mouseInGrid = true;
      }
      // Add new item to widgets object
      if (mouseInGrid === true && (this.widgets.findIndex((item) => item.i === 'drop')) === -1) {
        this.widgets.push({
          x: (this.widgets.length * 2) % (this.colNum || 12),
          y: this.widgets.length + (this.colNum || 12), // puts it at the bottom
          w: data.w,
          minW: data.minW,
          minH: data.minH,
          h: data.h,
          i: 'drop',
          type: data.type,
          name: data.name,
          preserveAspectRatio: data.preserveAspectRatio,
          isSettingsOpen: data.isSettingsOpen,
        });
      }
      const timeId = new Date().getTime().toString();
      const index = this.widgets.findIndex((item) => item.i === 'drop');
      if (index !== -1 && typeof this.$refs.gridlayout !== 'undefined' && this.$refs.gridlayout.$children.length) {
        try {
          this.$refs.gridlayout.$children[this.widgets.length].$refs.item.style.display = 'none';
        } catch {}
        const el = this.$refs.gridlayout.$children[index];
        el.dragging = { top: mouseXY.y - parentRect.top, left: mouseXY.x - parentRect.left };
        const newPos = el.calcXY(mouseXY.y - parentRect.top, mouseXY.x - parentRect.left);

        if (mouseInGrid === true) {
          this.$refs.gridlayout.dragEvent('dragstart', 'drop', newPos.x, newPos.y, data.h, data.w);
          DragPos.x = this.widgets[index].x;
          DragPos.y = this.widgets[index].y;
        }
        if (mouseInGrid === false) {
          this.$refs.gridlayout.dragEvent('dragend', timeId, newPos.x, newPos.y, data.h, data.w);
          this.widgets = this.widgets.filter((obj) => obj.i !== 'drop');
        }
        DragPos.i = timeId;
      }
    },
    dragend(data) {
      if (typeof this.$refs.gridlayout === 'undefined') {
        return;
      }
      const parentRect = document.getElementById('content').getBoundingClientRect();
      let mouseInGrid = false;

      // Check if mouse is inside dashboard bounds
      if (((mouseXY.x > parentRect.left) && (mouseXY.x < parentRect.right))
      && ((mouseXY.y > parentRect.top) && (mouseXY.y < parentRect.bottom))) {
        mouseInGrid = true;
      }

      if (mouseInGrid === true) {
        this.$refs.gridlayout.dragEvent('dragend', 'drop', DragPos.x, DragPos.y, data.h, data.w);

        // Remove the old 'drop' object
        this.widgets = this.widgets.filter((obj) => obj.i !== 'drop');

        this.widgets.push({
          x: DragPos.x,
          y: DragPos.y,
          w: data.w,
          minW: data.minW,
          minH: data.minH,
          h: data.h,
          i: DragPos.i,
          type: data.type,
          name: data.name,
          preserveAspectRatio: data.preserveAspectRatio,
          isSettingsOpen: data.isSettingsOpen,
          widgetData: data.widgetData,
          tagList: data.tagList,
          title: data.title,
        });

        try {
          this.$refs.gridlayout.dragEvent('dragend', DragPos.i, DragPos.x, DragPos.y, data.h, data.w);
          this.$refs.gridlayout.$children[this.widgets.length]
            .$refs.item.style.display = 'block';
        } catch {}
      }
      this.updateWidgetConfig(this.widgets);
    },
    removeItem() {
      const widgetId = this.deleteWidgetDialog.id;
      this.deleteWidget(widgetId);
      this.widgets = this.getWidgetsConfig;
      this.updateWidgetConfig(this.widgets);
      this.toggleDeleteDialog(widgetId);
      this.deleteWidgetDialog.id = '';
      this.closeDialog('deleteWidgetDialog');
    },
    openSettings(widgetId) {
      this.toggleWidgetSettingsDialog(widgetId);
      const index = this.widgets.map((e) => e.i).indexOf(widgetId);
      this.widgets[index].isSettingsOpen = true;
    },
    updateWidgetConfigData(widgetObject) {
      const index = this.widgets.findIndex((item) => item.i === widgetObject.widgetId);
      this.widgets[index].widgetData = widgetObject.data;
      this.widgets[index].tagList = widgetObject.tagList;
      this.widgets[index].title = widgetObject.title;
      this.updateWidgetConfig(this.widgets);
    },
    updateWidgetConfigDataOnMove() {
      if (this.widgets[this.widgets.length - 1].i === 'drop') {
        this.widgets[this.widgets.length - 1].i = new Date().getTime().toString();
      }
      this.updateWidgetConfig(this.widgets);
    },
    toggleDeleteDialog(widgetId) {
      this.deleteWidgetDialog.id = widgetId;
      this.deleteWidgetDialog.state = !this.deleteWidgetDialog.state;
      this.toggleDialog('deleteWidgetDialog');
    },
    updateSettingsDialogState(event) {
      const index = this.widgets.map((e) => e.i).indexOf(event.widgetId);
      this.widgets[index].isSettingsOpen = !event.state;
    },
    setDraggable(value) {
      this.isDraggable = value;
    },
  },
};
</script>

<style scoped>

.vue-grid-layout {
    background: var(--v-backgroundBoard-base);
}

.vue-grid-item:not(.vue-grid-placeholder) {
    background: var(--v-chartBackground-base);
    border: 1px solid black;
}

.vue-grid-item .resizing {
    opacity: 0.9;
}

.vue-grid-item .static {
    background: #cce;
}

.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}

.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}

.vue-grid-item .minMax {
    font-size: 12px;
}

.vue-grid-item .add {
    cursor: pointer;
}

.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}

.layoutJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
}

.layoutJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
}

.columns {
    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
}

.remove {
    position: absolute;
    right: 2px;
    top: 0;
    cursor: pointer;
}
.widget-card{
    border-radius:10px;
    border:none !important;
}

.vue-grid-item.vue-grid-placeholder{
  background: var(--v-placeholder-base) !important;
}

.icon-size-20{
  font-size: 20px;
}

.widget-loader{
  position:absolute;
  width:100%;
  height:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.123);
}
</style>
