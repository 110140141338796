import Vue from 'vue';
import Vuex from 'vuex';

import user from './modules/user';
import systemData from './modules/systemData';
import configurationData from './modules/configurationData';
import dialog from './modules/dialog';
import widgetData from './modules/widgetData';
import eventData from './modules/eventData';

const debug = process.env.NODE_ENV !== 'production';

Vue.use(Vuex);

Vue.config.devtools = process.env.NODE_ENV === 'development';

export default new Vuex.Store({
  namespaced: true,
  modules: {
    configurationData,
    user,
    systemData,
    dialog,
    widgetData,
    eventData,
  },
  mutations: {},
  actions: {},
  strict: debug,
});
