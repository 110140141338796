<template>
  <object
    :data="`${$vuetify.theme.dark?
    require(`@/assets/svg/${name}-dark.svg`): require(`@/assets/svg/${name}.svg`)}`"
    type="image/svg+xml" :width="width"/>
</template>

<script>
import icons from '@/assets/svg/icons.json';

export default {
  props: {
    name: {
      type: String,
      default: '',
      required: true,
    },
    width: {
      type: [Number, String],
      default: 10,
      required: false,
    },
    resize: {
      type: String,
      default: undefined,
      required: false,
    },
  },
  name: 'EMIcons',
  computed: {
    path() {
      return icons[this.name];
    },
    size() {
      if (this.resize === undefined) {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'small';
          case 'sm': return 'small';
          case 'md': return 'medium';
          case 'lg': return 'large';
          case 'xl': return 'large';
          default: return 'medium';
        }
      } else {
        return this.resize;
      }
    },
    iconStyle() {
      const finalStyle = [this.size].map((val) => val && `is-${val}`);
      return finalStyle;
    },
  },
};
</script>

<style>
object {
  border-radius: 0 !important;
  pointer-events: none;
}
.icon {
  width: 1.2em;
  height: 1.2em;
}
.icon.is-large {
  width: 2em;
  height: 2em;
}
.icon.is-medium {
  width: 1.4em;
  height: 1.4em;
}
.icon.is-small {
  width: 0.6em;
  height: 0.6em;
}
</style>
