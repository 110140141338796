<template>
  <v-overlay
  :absolute="isAbsolute"
  :value="showOverlay"
  >
    <v-row class="no-line-chart-data" justify="center" align="center">
      <p class="text-overline ma-0">
        {{ text }}
      </p>
      <v-icon large>
        {{ icon }}
      </v-icon>
    </v-row>
    <v-row class="overlay-action" justify="center" align="center">
      <slot name="action"></slot>
    </v-row>
  </v-overlay>
</template>

<script>
export default {
  props: {
    showOverlay: {
      type: Boolean,
      required: true,
    },
    isAbsolute: {
      type: Boolean,
      required: false,
      default: true,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>
