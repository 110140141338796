<template>
    <div>
        <EMChart :options='options' ref='emChart'></EMChart>
        <EMOverlay
          :showOverlay="!chartHasData"
          :text="$t('no_values')"
          icon="mdi-database-off-outline"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EMChart from './EMChart.vue';
import EMOverlay from '../../../Overlay/EMOverlay.vue';

export default {
  props: {
    legends: {
      type: Array,
      required: true,
    },
    series: {
      type: Object,
      required: true,
    },
    tag: {
      type: Object,
      required: true,
    },
    scale: {
      type: Object,
      required: true,
    },
  },
  components: {
    EMChart,
    EMOverlay,
  },
  emits: ['scale-changed'],
  data() {
    return {
      updatePointInterval: null,
      updatePointTime: 6000,
    };
  },
  methods: {
    formatNumber(n) {
      if (Number(n) !== 0) {
        return Number(n).toFixed(2);
      }
      return Number(n);
    },
    updateGauge(value) {
      const formattedValue = Number(this.formatNumber(value));
      if (formattedValue > this.scale.max) {
        this.scale.max = formattedValue;
        this.$emit('scale-changed', this.scale);
      }
      if (formattedValue < this.scale.min) {
        this.scale.min = formattedValue;
        this.$emit('scale-changed', this.scale);
      }
      const { points } = this.$refs.emChart.$refs.chart.chart.series[0];
      points[0].update(formattedValue, false, false);
    },
  },

  name: 'EMGaugeChartCreator',
  computed: {
    ...mapGetters(['getTagsList', 'getTagDataByName']),
    getLatestTagValue() {
      return this.getTagDataByName(this.tag.name);
    },
    options() {
      return {
        chart: {
          type: 'gauge',
          plotBackgroundColor: null,
          plotBackgroundImage: null,
          plotBorderWidth: 0,
          plotShadow: false,
          spacingBottom: -120,
          spacingLeft: -100,
          spacingRight: -100,
          spacingTop: -0,
        },
        title: {
          text: '',
        },
        pane: {
          startAngle: -90,
          endAngle: 90,
          background: null,
          center: ['50%', '50%'],
        },
        // the value axis
        yAxis: {
          min: this.scale.min,
          max: this.scale.max,

          startOnTick: true,
          endOnTick: true,

          minorTickInterval: 'auto',
          minorTickWidth: 0,
          minorTickLength: 10,
          minorTickPosition: 'inside',
          minorTickColor: '',

          tickPixelInterval: 30,
          tickWidth: 1,
          tickPosition: 'inside',
          tickLength: 10,
          tickColor: '#666',
          labels: {
            step: 2,
            rotation: 'auto',
            style: {
              color: this.$vuetify.theme.dark ? 'white' : 'gray',
            },
          },
          title: {
            text: '',
            style: {
              color: this.$vuetify.theme.dark ? 'white' : 'gray',
            },
          },
          plotBands: this.legends,
        },
        plotOptions: {
          gauge: {
            dial: {
              radius: '75%',
              backgroundColor: '#666666',
              borderWidth: 0,
              baseWidth: 20,
              topWidth: 1,
              baseLength: '10%',
              rearLength: '0%',
            },
            pivot: {
              backgroundColor: '#666666',
              radius: 10,
            },
            wrap: false,
            dataLabels: {
              style: {
                color: 'var(--v-chartLegend-base)',
                textOutline: '',
              },
            },
          },
          series: {
            dataLabels: {
              format: `{y} ${this.tag.unit}`,
            },
          },
        },
        series: [this.series],
      };
    },
    chartHasData() {
      return this.getLatestTagValue !== null && this.getLatestTagValue !== undefined;
    },
  },
  watch: {
    getLatestTagValue(newTagObject) {
      this.updateGauge(newTagObject.value);
      this.$refs.emChart.$refs.chart.chart.redraw();
    },
  },
};
</script>

<style>

</style>
