<template>
  <div class="d-flex flex-row align-center mr-8 mt-2" style="cursor: pointer;"
    @click="setApp(appInfo.internalName)">
    <v-card class="mr-3 card-apps d-flex justify-center align-center">
      <v-badge bordered :value=appEvents.length offset-x="-55px" offset-y="-25px"
        :color="badgeColor"></v-badge>
      <EMIcons :name="appInfo.internalName.replace(/ /g,'')" width="60"/>
    </v-card>
    <p class="text-capitalize ma-0" style="word-break: normal;">{{ appInfo.internalName }}</p>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import EMIcons from '@/components/SVG/EMIcons.vue';
import EventTypeEnum from '../Enums/EventTypeEnum';

export default {
  components: { EMIcons },
  props: {
    appInfo: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(['getNewEvents']),
    appEvents() {
      return this.getNewEvents.filter((event) => event.class === this.appInfo.baseUrl);
    },
    badgeColor() {
      if (this.appEvents.filter((event) => event.type === 'alert').length > 0) {
        return 'red';
      } if (this.appEvents.filter((event) => event.type === 'warning').length > 0) {
        return 'yellow';
      }
      return this.$vuetify.theme.dark ? '#6ADDFF' : '#641E8C';
    },
  },
  methods: {
    ...mapActions(['setOpenedApp', 'setAlertText', 'setAlertType', 'setAlert']),
    setApp(appName) {
      this.setOpenedApp(appName);
      if (this.appEvents.length > 0) {
        if (this.appEvents.filter((event) => event.type === EventTypeEnum.ALERT).length > 0) {
          this.setAlertSettings('alert');
        } else if (
          this.appEvents.filter((event) => event.type === EventTypeEnum.WARNING).length > 0) {
          this.setAlertSettings('warning');
        } else if (
          this.appEvents.filter((event) => event.type === EventTypeEnum.INFORMATION).length > 0) {
          this.setAlertSettings('information');
        } else if (
          this.appEvents.filter((event) => event.type === EventTypeEnum.ADVICE).length > 0) {
          this.setAlertSettings('advice');
        }
      }
    },
    setAlertSettings(alertType) {
      this.setAlertText(this.appEvents.filter((event) => event.type === alertType)[0].message);
      this.setAlertType(alertType);
      this.setAlert(true);
    },
  },
};
</script>

<style scoped>
.card-apps {
  border: 3.5px solid var(--v-applications-base);
  border-radius: 6px;
  min-width: 80px;
  width: 80px;
  height: 80px;
  cursor: pointer;
}
</style>
