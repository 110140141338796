<template>
  <div>
      <div data-testid="message-txt" v-html="refinedMessage"></div>

      <EMWidgetSettings
      @dialogStateChangeEvent="changeSettingsDialogState"
      @saveSettingsEvent="saveTagSettings"
      v-bind:isOpen="settingsDialog"
      v-bind:title="title"
      v-bind:dialogWidth='800'
      ref="settings"
      >
      <div class="d-flex flex-column mb-2">
        <div>
          <v-textarea
          color="buttons"
          data-testid="message-input"
          outlined
          name="input-7-4"
          :rules="isNotEmptyRules"
          :label="$t('message')"
          v-model="messageSettings"
          value=""
        ></v-textarea>
        </div>
      </div>
      </EMWidgetSettings>
  </div>
</template>

<script>

import WidgetBasicMixin from '@/components/Widgets/mixins/widget-basic-mixin';

export default {
  data() {
    return {
      title: this.$i18n.t('widget_message_title'),
      message: '',
      messageSettings: '',
    };
  },
  props: {
    settingsDialog: {
      type: Boolean,
      default: false,
    },
    widgetData: {
      type: Object,
    },
    widgetTitle: {
      type: String,
    },
  },
  methods: {
    saveTagSettings(title) {
      this.title = title;
      this.message = this.messageSettings;
      this.$emit('updateSettingsWidgetEvent', title, { message: this.message }, {});
    },
    resetSettingChanges() {
      this.messageSettings = JSON.parse(JSON.stringify(this.message));
    },
  },
  components: {},
  name: 'EMWidgetMessage',
  created() {
    this.message = this.widgetData.message ? this.widgetData.message : '';
    this.messageSettings = this.widgetData.message ? this.widgetData.message : '';
    this.title = this.widgetTitle;
  },
  computed: {
    refinedMessage() {
      return this.message.replaceAll('\n', '</br>');
    },
  },
  mixins: [WidgetBasicMixin],
};
</script>
<style scoped>

</style>
