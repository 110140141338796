<template>
    <div>
      <EMChart :options='chartOptions' ref='emChart'></EMChart>
      <EMOverlay
        :showOverlay="!hasChartData"
        :text="$t('no_values')"
        icon="mdi-database-off-outline"
      />
      <EMHoverAlert
        :showAlertWhileHovering="showNoDataAlert"
        :positionX="noDataAlertPos.x"
        :positionY="noDataAlertPos.y"
        :alertMessage="$t('no_values')"
        alertColor="tooltip"
        icon="mdi-database-off"
      />
    </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import Highcharts from 'highcharts';
import EMChart from './EMChart.vue';
import EMOverlay from '../../../Overlay/EMOverlay.vue';
import COLORS from '../../constants/Colors';
import EMHoverAlert from '../../../Alert/EMHoverAlert.vue';

export default {
  props: {
    tags: {
      type: Array,
      required: true,
    },
    timeFrameValue: {
      type: Number,
      required: true,
    },
    isTimeInUTC: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    EMChart,
    EMOverlay,
    EMHoverAlert,
  },
  data() {
    const that = this;
    return {
      timeFrameKey: '',
      chartOptions: {
        time: {
          useUTC: that.isTimeInUTC,
        },
        chart: {
          marginTop: 50,
          events: {
            render(event) {
              const CurrentEmptyTagIdxList = [];
              event.target.series.forEach((s, index) => {
                if (s.processedYData.length === 0) CurrentEmptyTagIdxList.push(index);
              });
              that.emptyTagIdxList.forEach((emptyTagIdx) => {
                if (!CurrentEmptyTagIdxList.includes(emptyTagIdx)) {
                  event.target.legend.allItems[emptyTagIdx]
                    .legendItem.on('mouseover', null);
                  event.target.legend.allItems[emptyTagIdx]
                    .legendItem.on('mouseout', null);
                }
              });
              that.emptyTagIdxList = CurrentEmptyTagIdxList;
              if (event.target.series.length > 0) {
                if (CurrentEmptyTagIdxList.length > 0) {
                  CurrentEmptyTagIdxList.forEach((emptyTagHistIndex) => {
                    event.target.legend.allItems[emptyTagHistIndex].legendItem.on('mouseover', (e) => {
                      that.setNoDataAlertPosition(e);
                      that.showNoDataAlert = true;
                    });
                  });
                  CurrentEmptyTagIdxList.forEach((emptyTagHistIndex) => {
                    event.target.legend.allItems[emptyTagHistIndex].legendItem.on('mouseout', (e) => {
                      that.setNoDataAlertPosition(e);
                      that.showNoDataAlert = false;
                    });
                  });
                }
              }
            },
          },
        },
        title: {
          text: '',
        },
        legend: {
        },
        xAxis: {
          type: 'datetime',
          title: {
            text: this.$t('time'),
          },
        },
        series: [
          {
            name: '',
            data: [],
          },
        ],
        colors: COLORS,
      },
      previousHistData: [],
      showNoDataAlert: false,
      noDataAlertPos: { x: 0, y: 0 },
      emptyTagIdxList: [],
    };
  },
  name: 'EMLineChartCreator',
  methods: {
    ...mapActions(['fetchTagHistoricalData']),
    ...mapMutations(['removeHCTagData']),
    generateUnits() {
      const unitAxis = new Set();
      this.tags.forEach((tag, index) => {
        if (typeof tag.unit !== 'undefined') {
          unitAxis.add(tag.unit);
          return;
        }
        unitAxis.add(tag.name + index);
      });
      return unitAxis;
    },
    generateAllYaxis(unitAxis) {
      this.chartOptions.yAxis = [];
      const axisMapping = [];
      const unitAxisArray = Array.from(unitAxis);
      unitAxisArray.forEach((axis, index) => {
        const color = this.$vuetify.theme.dark ? 'white' : 'grey';
        const yAxisSettings = {
          labels: {
            style: {
              color,
            },
            align: 'right',
            formatter: (label) => {
              const value = Highcharts.Axis.prototype.defaultLabelFormatter.call(label);
              if (Number.isNaN(Number(value))) {
                return value;
              }
              if (Number(value) !== 0) {
                return Number(value).toFixed(1);
              }
              return value;
            },
          },
          title: {
            text: axis,
            style: {
              color,
            },
            align: 'high',
            offset: 25,
            rotation: -60,
            y: -40,
            x: 0,
          },
        };
        axisMapping[axis] = index;
        this.chartOptions.yAxis.push(yAxisSettings);
      });
      return axisMapping;
    },
    generateDataPlot() {
      this.chartOptions.series = [];
      const timeFrameFrom = new Date(new Date().getTime() - this.timeFrameValue);
      const timeFrameTo = new Date();
      this.timeFrameKey = `${timeFrameFrom.toISOString()}${timeFrameTo.toISOString()}`;
      if (this.tags.length > 0) {
        this.$emit('setLoadingAnimation', true);
        this.tags.forEach((tag) => {
          this.fetchTagHistoricalData({
            tag: tag.name,
            timeFrameFrom,
            timeFrameTo,
            timeFrameKey: this.timeFrameKey,
          });
        });
      }
    },
    updateDataPlot() {
      this.chartOptions.series = [];
      const unitAxis = this.generateUnits();
      const axisMapping = this.generateAllYaxis(unitAxis);
      const noDataSerieColor = this.$vuetify.theme.dark ? '#2e2e2e' : '#ffffff';
      const noDataIcon = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='%23${this.$vuetify.theme.dark ? 'ffffff' : '666666'}' d='M2.39 1.73L1.11 3l3.1 3.1c-.13.29-.21.59-.21.9v10c0 2.21 3.59 4 8 4c2.3 0 4.38-.5 5.84-1.27l3 3l1.27-1.27zM6 9.64c.76.43 1.7.78 2.76 1.01L12.11 14H12c-2.42 0-4.7-.6-6-1.55zM12 19c-3.87 0-6-1.5-6-2v-2.23c1.61.78 3.72 1.23 6 1.23c.68 0 1.34-.05 2-.13l2.34 2.36c-1.01.42-2.47.77-4.34.77M8.64 5.44L7.06 3.86C8.42 3.33 10.13 3 12 3c4.42 0 8 1.79 8 4v9.8l-2-2v-.03v.01l-1.55-1.53c.6-.22 1.13-.49 1.55-.8V9.64c-1.03.58-2.39 1.01-3.94 1.22L12.19 9C15.94 8.94 18 7.5 18 7s-2.13-2-6-2c-1.34 0-2.46.18-3.36.44'/%3E%3C/svg%3E`;
      this.tags.forEach((tag, index) => {
        const tagName = tag.unit ? `${tag.name} [${tag.unit}]` : tag.name;
        const historicalTagData = this.getHistoricalTagsDataByTagName(tag.name, this.timeFrameKey);
        const tagData = {
          name: tagName,
          data: historicalTagData,
          color: historicalTagData.length === 0
            ? noDataSerieColor : this.chartOptions.colors[index],
          unit: tag.unit,
          yAxis: axisMapping[tag.unit],
          visible: historicalTagData.length !== 0,
          marker: {
            symbol: historicalTagData.length === 0 ? `url(${noDataIcon})` : '',
          },
        };
        this.chartOptions.series.push(tagData);
        this.previousHistData = tagData.data;
        this.removeHCTagData({ tag: tag.name, timeFrameKey: this.timeFrameKey });
      });
      this.chartOptions.xAxis = {
        ...JSON.parse(JSON.stringify(this.$parent.xAxis)),
        labels: { style: { color: this.$vuetify.theme.dark ? 'white' : 'grey' } },
        title: { style: { color: this.$vuetify.theme.dark ? 'white' : 'grey' } },
      };
    },
    setNoDataAlertPosition(event) {
      const alertHeight = 56;
      const alertWidth = 143;
      const availableSpaceBelow = window.innerHeight - (event.clientY + alertHeight);
      if (availableSpaceBelow >= alertHeight) {
        this.noDataAlertPos.x = event.clientX - alertWidth / 2;
        this.noDataAlertPos.y = event.clientY + 9;
      } else {
        this.noDataAlertPos.x = event.clientX - alertWidth / 2;
        this.noDataAlertPos.y = event.clientY - alertHeight - 9;
      }
    },
  },
  mounted() {
  },
  computed: {
    ...mapGetters([
      'getHistoricalDataLoading',
      'getHistoricalTagsDataByTagName',
      'getTagDataByName',
    ]),
    getLoadingState() {
      let isLoading = false;
      this.tags.forEach((tag) => {
        if (this.getHistoricalDataLoading(tag.name, this.timeFrameKey)) {
          isLoading = true;
        }
      });
      return isLoading;
    },
    getAppTheme() {
      return this.$vuetify.theme.dark;
    },
    getTagsActualData() {
      return this.tags.map((tag) => this.getTagDataByName(tag.name));
    },
    hasChartData() {
      const chartHistSeries = this.tags
        .map((tag) => this.getHistoricalTagsDataByTagName(tag.name, this.timeFrameKey));
      const chartActualSeries = this.getTagsActualData;
      if ((chartHistSeries.length === 0 && chartActualSeries.length === 0)
        && this.previousHistData.length === 0) {
        return false;
      }
      return (chartHistSeries.some((series) => series.length > 0)
        || chartActualSeries.some((series) => series)) || this.previousHistData.length > 0;
    },
  },
  watch: {
    getLoadingState(newState, oldState) {
      if (!newState && oldState) {
        this.updateDataPlot();
        this.$emit('setLoadingAnimation', false);
      }
    },
    tags(newTag, oldTag) {
      if (JSON.stringify(newTag) !== JSON.stringify(oldTag)) {
        this.generateDataPlot();
      }
    },
    timeFrameValue() {
      this.generateDataPlot();
    },
    getAppTheme() {
      this.generateDataPlot();
    },
    isTimeInUTC(value) {
      this.$refs.emChart.$refs.chart.chart.update({
        time: {
          useUTC: value,
        },
      });
    },
  },
  created() {
    this.generateDataPlot();
  },
};
</script>

<style>
.widget-line-chart > div{
  width: 100% !important;
  height: 100% !important;
}
</style>
