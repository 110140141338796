<template>
  <v-dialog v-model="dialogModel" :width="width" :simpleDialog="simpleDialog"
  persistent :max-width="maxWidth ? maxWidth : width">
    <v-card class="d-flex flex-column">
      <v-btn large color="tableHeader" tile @click="closeDialog(id)" icon
      class="pa-0 ma-0" style="position: absolute; left: 85%; top: 3%;"
      v-if="simpleDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <slot></slot>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'EMDialog',
  props: {
    width: {
      type: String,
      default: '500',
      required: false,
    },
    id: {
      type: String,
      required: true,
    },
    simpleDialog: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxWidth: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters(['getDialog']),
    dialogModel: {
      get() {
        return this.getDialog(this.id);
      },
      set() {
        this.toggleDialog(this.id);
      },
    },
  },
  methods: {
    ...mapActions(['registerDialog', 'removeDialog', 'toggleDialog', 'closeDialog']),
  },
  mounted() {
    this.registerDialog(this.id);
  },
  beforeDestroy() {
    this.removeDialog(this.id);
  },
};
</script>
