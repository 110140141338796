import { axiosRestApiPrivate } from '../../axios/axiosInstance';

export default {
  getAllEvents() {
    return axiosRestApiPrivate.get('/event-management/events');
  },

  createEvent(eventObjs) {
    return axiosRestApiPrivate.post('/event-management/events', eventObjs);
  },

  updateEvent(eventId, eventObj) {
    return axiosRestApiPrivate.put(`/event-management/events/${eventId}`, eventObj);
  },

  deleteEvent(eventId) {
    return axiosRestApiPrivate.delete(`/event-management/events/${eventId}`);
  },

  filterEvents(filterObj) {
    return axiosRestApiPrivate.post('/event-management/events/filter', filterObj);
  },

  getNewEvents() {
    return axiosRestApiPrivate.post('/event-management/events/filter', { state: 'new', pageSize: 100 });
  },
};
