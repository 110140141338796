<template>
  <v-combobox
    dense
    :value="selectedTag"
    :items="tagsTypeList"
    :label="$t('tags')"
    item-text="tagName"
    item-value="tagName"
    color="buttons"
    item-color="buttons"
    solo
    @update:search-input="debouncedSelector($event)"
    class="widget-tag-component"
    ></v-combobox>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  data() {
    return {
      tagsTypeList: [],
      selectedTag: {},
    };
  },
  props: {
    tag: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    getAllTags() {
      this.tagsTypeList = this.getTagsList;
    },
    updateTagValue(value) {
      const tag = this.getTagsList.find((item) => item.tagName === value);
      if (tag) {
        this.$emit('updateTagValue', tag);
        return;
      }
      this.$emit('updateTagValue', {});
    },
  },
  name: 'EMTagSelect',
  computed: {
    ...mapGetters(['getTagsList']),
  },
  created() {
    this.debouncedSelector = _.debounce(this.updateTagValue, 500);
  },
  mounted() {
    this.getAllTags();
    this.selectedTag = this.tag.name;
  },
  watch: {
    tag(newValue) {
      this.selectedTag = newValue.name;
    },
  },
};
</script>

<style>
.widget-tag-component .v-text-field__details{
    display:none;
}
</style>
