<template>
  <EMDialog id="releaseNotes" width="100vh">
    <v-toolbar dense tile color="header" class="white--text">
      <v-toolbar-title>
        {{ $t('ems_release_notes_title', { version: systemVersion }) }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon dark tile @click="closeDialog('releaseNotes')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="release-dialog">
      <div v-html="markdownToHtml"></div>
    </v-card-text>
  </EMDialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { marked } from 'marked';
import EMDialog from '@/components/Dialog/EMDialog.vue';

export default {
  name: 'EMReleaseNotes',
  components: {
    EMDialog,
  },
  data() {
    return {
      systemVersion: process.env.VUE_APP_EMS_CURRENT_VERSION,
    };
  },
  computed: {
    ...mapGetters(['getReleaseNotesData']),
    markdownToHtml() {
      return marked(this.getReleaseNotesData);
    },
  },
  methods: {
    ...mapActions(['closeDialog', 'getCurrentVersionReleaseNotes']),
  },
  mounted() {
    this.getCurrentVersionReleaseNotes();
  },
};
</script>
<style scoped>
.release-dialog {
  padding: 20px !important;
  overflow-y: auto;
  height: 75vh;
}
</style>
