<template>
  <v-container class="login-container">
    <div class="container-left">
      <div class="content-logo d-flex align-center justify-center px-8">
        <img src="~assets/EM_Logo.svg" data-testid="login-page-app-logo-image" class="em-icon"/>
        <div class="d-flex flex-column">
          <div data-testid="login-page-app-main-title" class="em-title">
            EcoMAIN
          </div>
          <div data-testid="login-page-app-subtitle" class="em-subtitle">
            Vessel
            <div class="em-version" data-testid="login-page-app-version">v{{ systemVersion }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-right d-flex flex-column">
      <div class="d-flex justify-end ma-5">
        <img src="~assets/EM_SE_Logo.svg" style="width: 95px;"
        data-testid="login-page-app-se-logo-image"/>
      </div>
      <div class="d-flex flex-column align-center my-auto pb-16">
        <div class="white--text font-weight-bold text-h4 text-uppercase"
        style="letter-spacing: 5px !important;" data-testid="login-page-app-welcome-text">
          {{ $t('welcome') }}
        </div>
        <div class="white--text font-weight-light text-body-1 pt-3 mb-10"
            data-testid="login-page-app-subtext">
          {{ $t('sign_in_to_your_account') }}
        </div>
        <v-form @submit="userLogin" data-testid="login-form"
        class="d-flex flex-column justify-center login-wrapper">
          <v-text-field data-testid="username-field" class="textfield-login" solo id="input-1"
          v-model="username" required :label="$t('username')" />
          <v-text-field data-testid="password-field" class="textfield-login" solo id="input-2"
          color="buttons" v-model="password" :type="showPassword ? 'text': 'password'" required
          :append-icon="showPassword? 'mdi-eye': 'mdi-eye-off' " :label="$t('password')"
          @click:append="showPassword = !showPassword"/>
          <EMButton type="submit" class="white--text py-2" block xLarge primaryButtons
          data-testid="sign-in-button">
            {{ $t('sign_in') }}
          </EMButton>
          <v-row class="d-flex flex-row justify-end mt-0">
            <v-col cols="2">
              <EMLanguageSwitch :dark="true"/>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
    <EMAlert/>
  </v-container>
</template>

<script>
import EMLanguageSwitch from '@/components/Layout/EMLanguageSwitch.vue';
import EMButton from '@/components/Buttons/EMButton.vue';
import { mapActions } from 'vuex';
import EMAlert from '../components/Alert/EMAlert.vue';

export default {
  name: 'Login',
  components: {
    EMLanguageSwitch,
    EMButton,
    EMAlert,
  },
  data() {
    return {
      username: '',
      password: '',
      showPassword: false,
      responseMessage: '',
      systemVersion: process.env.VUE_APP_EMS_CURRENT_VERSION,
    };
  },
  methods: {
    ...mapActions(['login', 'getUserData', 'setAlert', 'setAlertText', 'setAlertType']),
    async userLogin(evt) {
      evt.preventDefault();
      const loginData = { userName: this.username, password: this.password };
      this.setAlert(false);
      await this.login(loginData)
        .then(() => {
          this.setAlertText(`${this.$i18n.t('welcome')} ${this.username}`);
          this.setAlertType('success');
          this.setAlert(true);
          this.$router.push('/');
        })
        .catch((err) => {
          this.setAlertText(err.response.data.ErrMessage ?? this.$i18n.t('default_error'));
          this.setAlertType('error');
          this.setAlert(true);
        });
    },
  },
  created() {
    this.$crontab.disableJob('verifyAuthCron');
    this.$crontab.disableJob('updateSysTimeCron');
    this.$crontab.disableJob('shortDataRefreshCron');
    this.$crontab.disableJob('longDataRefreshCron');
  },
};
</script>
<style scoped>
.login-container {
  background-color: var(--v-background-base);
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 !important;
  height: 100vh;
  display: flex;
  flex-direction: row;
}
.login-wrapper {
  width: 60%;
}
.container-right {
  width: 40%;
  background-color: var(--v-header-base);
  border-radius: 0% 0% 0% 45%;
}
.content-logo {
  background-color: var(--v-background-base);
  border-radius: 0% 45% 0% 0%;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}
.container-left {
  background-color: var(--v-header-base);
  width: 65%;
}
.divider-logo {
  width: 2px;
  max-width: 2px;
}
.textfield-login > .v-input__control > .v-input__slot {
  padding: 15px !important;
}
.em-title{
  font-size: 6vw;
  font-weight: 500;
  line-height: 7vw;
}
.em-subtitle{
  font-size: 1.5vw;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  line-height: 1.5vw;
}
.em-version {
  font-style: italic;
  font-size: 0.9vw;
  margin-left: 8px;
  line-height: 1.25vw;
}
.em-icon {
  width: 12vw;
  margin-right: 20px;
}
</style>
