<template>
    <div class="widget-settings-removeButton">
        <v-icon @click="removeItem()">
          mdi-close
        </v-icon>
    </div>
</template>

<script>

export default {
  data() {
    return {
    };
  },
  props: {
  },
  methods: {
    removeItem() {
      this.$emit('removeElementEvent');
    },
  },
  name: 'EMDeleteButton',
  computed: {
  },
  created() {
  },
  watch: {
  },
};
</script>

<style>
.widget-settings-removeButton{
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
