import EMTagSelect from '@/components/TagSelect/EMTagSelect.vue';
import EMDeleteButton from '@/components/Buttons/EMDeleteButton.vue';
import { mapGetters } from 'vuex';
import EMWidgetSettings from '../utils/EMWidgetSettings.vue';
import Enums from '../enums/index';

export default {
  data() {
    return {
      isNotEmptyRules: [
        (v) => !!v || this.$t('input_required'),
      ],
    };
  },
  methods: {
    changeSettingsDialogState() {
      this.$emit('dialogStateChangeEvent');
    },
    discardChanges() {
      this.resetSettingChanges();
    },
  },
  computed: {
    ...mapGetters(['getTagSetting']),
    tagSettingsManagement: {
      get() {
        return this.getTagSetting;
      },
    },
    timeFrameOptions() {
      return Enums.TimeFrameEnum.map((time) => (
        {
          name: this.$t(time.name),
          index: time.index,
          value: time.value,
        }));
    },
    tagLimitReached() {
      if (this.tagSettingsManagement.length === this.tagLimit) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    if (this.$refs.settings) {
      this.$refs.settings.$on('discardChanges', this.discardChanges);
    }
  },
  components: {
    EMWidgetSettings,
    EMTagSelect,
    EMDeleteButton,
  },

};
