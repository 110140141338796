/* eslint-disable no-shadow */
import client from 'api-client';
import Vue from 'vue';
import axios from 'axios';

const state = {
  isDashboardLoading: true,
  isWidgetDrawerOpen: false,
  tagList: [],
  tagSetting: [],
  actualTagsData: {},
  widgetsConfig: [],
  tagsNameToIdMapping: {},
  tagsIdToNameMapping: {},
  tagsHistoricalData: {},
  tagsHistoricalDataLoadingState: {},
  selectedTagsData: [],
};

const getters = {
  getTagsList: (state) => state.tagList,
  getTagIdByName: (state) => (tagName) => {
    const tag = state.tagList.find((tag) => tag.tagName === tagName);
    return tag ? tag.tagId : null;
  },
  getTagNameById: (state) => (tagId) => {
    const tag = state.tagList.find((tag) => tag.tagId === tagId);
    return tag ? tag.tagName : null;
  },
  getTagSetting: (state) => state.tagSetting,
  getWidgetDrawerState: (state) => state.isWidgetDrawerOpen,
  getIsLoadingState: (state) => state.isDashboardLoading,
  getActualTagsData: (state) => state.actualTagsData,
  getHistoricalTagsData: (state) => state.tagsHistoricalData,
  getLoadedTagsNames: (state) => [
    ...Object.keys(state.actualTagsData),
    ...Object.keys(state.tagsHistoricalData),
  ],
  getWidgetsConfig: (state) => state.widgetsConfig,
  getTagDataById: (state) => (tagId) => state.actualTagsData[tagId],
  getTagDataByName: (state) => (tagName) => state.actualTagsData[tagName],
  getLastBeforeDataByTagName: (state) => (tagName, timeFrameKey) => {
    if (state.tagsHistoricalData[tagName]) {
      return state.tagsHistoricalData[tagName][timeFrameKey];
    }
    return null;
  },
  getHistoricalTagsDataByTagName: (state) => (tagName, timeFrameKey) => {
    const data = [];
    if (state.tagsHistoricalData[tagName] && state.tagsHistoricalData[tagName][timeFrameKey]) {
      const dataSetArray = state.tagsHistoricalData[tagName][timeFrameKey];
      if (dataSetArray) {
        const { length } = dataSetArray;
        for (let i = 0; i < length; i += 1) {
          data.push(
            [dataSetArray[i].x, dataSetArray[i].y],
          );
        }
      }
    }
    return data;
  },
  getHistoricalDataLoading: (state) => (tagName, timeFrameKey) => {
    if (state.tagsHistoricalDataLoadingState[tagName]
      && state.tagsHistoricalDataLoadingState[tagName][timeFrameKey] !== undefined) {
      return state.tagsHistoricalDataLoadingState[tagName][timeFrameKey];
    }
    return true;
  },
  getSettingsDialogState: (state) => (widgetId) => {
    const widget = state.widgetsConfig.find((widget) => widget.i === widgetId);
    if (widget) {
      return widget.isSettingsOpen;
    }
    return false;
  },
  getSelectedTagsData: (state) => state.selectedTagsData,
};

const actions = {
  updateActTagsData({ getters, commit }, tagsData) {
    tagsData.forEach((tag) => {
      const updateTag = { ...tag, value: Number(tag.value) };
      const tagName = getters.getTagNameById(tag.tagId);
      if (tagName) commit('updateTag', { tagName, updateTag });
    });
  },
  fetchTagsList({ commit }) {
    return new Promise((resolve) => {
      resolve(client.tagData.getAllTags()
        .then((resp) => {
          commit('updateTagsList', resp.data.data);
        }));
    });
  },
  updateTagSettings({ commit }, tagObject) {
    commit('modifyTagSettings', tagObject);
  },
  addTagSettings({ commit }, tagObject) {
    commit('newTagSettings', tagObject);
  },
  removeTagSettings({ commit }, index) {
    commit('deleteTagSettings', index);
  },
  fetchTagDataList({ state, dispatch }, data) {
    if (data.length) {
      const tagIds = [];
      data.forEach((tag) => {
        const searchedTag = state.tagList.find((x) => x.tagName === tag);
        if (searchedTag) tagIds.push(searchedTag.tagId);
      });
      return new Promise((resolve) => {
        resolve(client.tagData.getActualData(tagIds)
          .then((resp) => {
            dispatch('updateActTagsData', resp.data.data);
          })
          .catch((err) => {
            console.error(err, 'Error while fetching tag data');
            dispatch('updateActTagsData', []);
          }));
      });
    }
    return null;
  },
  fetchTagHistoricalData({ getters, dispatch, commit }, data) {
    return new Promise((resolve) => {
      let page = 1;
      const sampleSize = 1000;
      const tagId = getters.getTagIdByName(data.tag);
      const startDate = data.timeFrameFrom.toISOString();
      const endDate = data.timeFrameTo.toISOString();
      const requestCancelToken = axios.CancelToken.source();
      resolve(client.tagData.getTimeSeriesData(
        tagId,
        startDate,
        endDate,
        sampleSize,
        page,
        requestCancelToken,
      ).then((response) => {
        commit('updateHCTag', {
          data: response.data.data,
          tag: data.tag.toString(),
          timeFrameKey: data.timeFrameKey,
        });
        if (response.data.data.count > (response.data.data.pageSize * page)) {
          page += 1;
          dispatch('fetchTagHistoricalDataPage', {
            data, tagId, startDate, endDate, page,
          });
        } else {
          const tag = data.tag.toString();
          commit('finishNextHCTag', { tag, timeFrameKey: data.timeFrameKey });
        }
      }).catch((err) => {
        console.error(err, 'Error while fetching tag historical data');
        commit('updateHCTag', {
          data: [],
          tag: data.tag.toString(),
          timeFrameKey: data.timeFrameKey,
        });
        commit('finishNextHCTag', { tag: data.tag.toString(), timeFrameKey: data.timeFrameKey });
      }));
    });
  },
  fetchTagHistoricalDataPage({ dispatch, commit }, data) {
    return new Promise((resolve) => {
      const { tagId } = data;
      const { startDate } = data;
      const { endDate } = data;
      const tagInfo = data.data;
      const sampleSize = 1000;
      const requestCancelToken = axios.CancelToken.source();
      resolve(client.tagData.getTimeSeriesData(
        tagId,
        startDate,
        endDate,
        sampleSize,
        data.page,
        requestCancelToken,
      ).then((response) => {
        commit('updateNextHCTag', {
          data: response.data.data,
          tag: tagInfo.tag.toString(),
          timeFrameKey: tagInfo.timeFrameKey,
        });
        if (response.data.data.count > (response.data.data.pageSize * data.page)) {
          const nextPage = data.page + 1;
          dispatch('fetchTagHistoricalDataPage', {
            data: tagInfo,
            tagId,
            startDate,
            endDate,
            page: nextPage,
          });
        } else {
          const tag = data.data.tag.toString();
          commit('finishNextHCTag', { tag, timeFrameKey: data.data.timeFrameKey });
        }
      }).catch(() => {
        const tag = data.data.tag.toString();
        commit('finishNextHCTag', { tag, timeFrameKey: data.data.timeFrameKey });
      }));
    });
  },
  fetchTagDataByIds: ({ getters, dispatch, commit }, tagIds) => {
    const loadedTagNames = [
      ...Object.keys(state.actualTagsData),
      ...Object.keys(state.tagsHistoricalData),
    ];
    const ids = tagIds.filter(
      (tagId) => !loadedTagNames.includes(getters.getTagNameById(tagId)),
    );
    if (ids.length !== 0) {
      dispatch('fetchTagDataList', ids);
    }
    commit('setSelectedTagsData', tagIds);
  },
  fetchTagDataByName: ({ dispatch, commit }, tagNames) => {
    const loadedTagNames = [
      ...Object.keys(state.actualTagsData),
      ...Object.keys(state.tagsHistoricalData),
    ];
    const names = tagNames.filter((tagName) => !loadedTagNames.includes(tagName));
    if (names.length !== 0) {
      dispatch('fetchTagDataList', names);
    }
    commit('setSelectedTagsData', tagNames);
  },
  updateWidgetConfig({ commit, getters }, newConfig) {
    commit('setWidgetConfig', newConfig);
    const keyName = `${getters.getUsername}-widgetConfig`;
    localStorage.setItem('widgetsConfig', JSON.stringify(newConfig));
    return new Promise((resolve) => {
      resolve(client.configurationData.setApplicationConfigData(
        keyName,
        newConfig,
      ));
    });
  },
  selectWidgetConfig({ commit, dispatch }, parsedConfig) {
    localStorage.setItem('widgetsConfig', JSON.stringify(parsedConfig));
    if (parsedConfig.length > 0) {
      const parsedValue = parsedConfig;
      const tagListArray = [];
      parsedValue.forEach((widget, index) => {
        parsedValue[index].isSettingsOpen = false;
        if (!widget.tagList) return;
        if (widget.tagList.length === 0
          || Object.keys(widget.tagList).length === 0) return;
        tagListArray.push(...widget.tagList);
      });
      commit('setWidgetConfig', parsedValue);
      const uniqueTagList = new Set(tagListArray);
      dispatch('fetchTagsList').then(() => {
        dispatch('getDefaultTagValue');
        dispatch('fetchTagDataByName', Array.from(uniqueTagList)).then(() => {
          commit('changeLoadingState', false);
        });
      });
    } else {
      commit('changeLoadingState', false);
    }
  },
  fetchWidgetConfig({ getters, dispatch }) {
    const keyName = `${getters.getUsername}-widgetConfig`;
    return new Promise((resolve) => {
      resolve(client.configurationData.getApplicationConfigData(keyName)
        .then((resp) => {
          const config = resp.data.data;
          const parsedConfig = JSON.parse(config);
          dispatch('selectWidgetConfig', parsedConfig);
          return parsedConfig;
        })
        .catch(() => {
          const config = new Array(0);
          dispatch('updateWidgetConfig', config);
          dispatch('selectWidgetConfig', config);
          return config;
        }));
      return JSON.parse(localStorage.getItem('widgetsConfig'));
    });
  },
  deleteWidget({ commit, dispatch }, widgetId) {
    commit('deleteWidget', widgetId);
    dispatch('updateWidgetConfig', state.widgetsConfig);
  },
  refreshActualTagsData({ dispatch }) {
    const names = [
      ...Object.keys(state.actualTagsData),
      ...Object.keys(state.tagsHistoricalData),
    ];
    if (names.length) dispatch('fetchTagDataList', names);
  },
  getDefaultTagValue({ getters, dispatch }) {
    const tagList = getters.getTagsList;
    const defaultTagName = tagList[0].tagName;
    dispatch('fetchTagDataByName', [defaultTagName]);
  },
};

const mutations = {
  updateTag(state, { tagName, updateTag }) {
    Vue.set(state.actualTagsData, tagName, updateTag);
  },
  updateWidgetDrawerState: (state, value) => {
    state.isWidgetDrawerOpen = value;
  },
  updateTagsList: (state, list) => {
    state.tagList = list;
  },
  modifyTagSettings: (state, data) => {
    state.tagSetting[data.index] = data.parameters;
    state.tagSetting = [...state.tagSetting];
  },
  newTagSettings: (state, data) => {
    if (Array.isArray(data)) {
      state.tagSetting = data;
    } else {
      state.tagSetting.push(data);
    }
  },
  deleteTagSettings: (state, index) => {
    state.tagSetting.splice(index, 1);
  },
  setWidgetConfig: (state, config) => {
    state.widgetsConfig = [];
    state.widgetsConfig = JSON.parse(JSON.stringify(config));
  },
  toggleWidgetSettingsDialog: (state, widgetId) => {
    const widgetSetting = state.widgetsConfig.find((widget) => widget.i === widgetId);
    widgetSetting.isSettingsOpen = !widgetSetting.isSettingsOpen;
  },
  setWidgetDataConfig: (state, widgetSettings) => {
    const widget = state.widgetsConfig.find((widget) => widget.i === widgetSettings.widgetId);
    widget.title = widgetSettings.title;
    widget.widgetData = widgetSettings.data;
    widget.tagList = widgetSettings.tagList;
  },
  deleteWidget: (state, widgetId) => {
    const i = state.widgetsConfig.map((item) => item.i).indexOf(widgetId);
    state.widgetsConfig.splice(i, 1);
  },
  updateLastBeforeTag: (state, data) => {
    if (state.tagsHistoricalData[data.tag]) {
      Vue.set(state.tagsHistoricalData[data.tag], data.timeFrameKey, data.data);
    } else {
      Vue.set(state.tagsHistoricalData, data.tag,
        { [data.timeFrameKey]: data.data });
    }
  },
  updateHCTag: (state, data) => {
    if (state.tagsHistoricalDataLoadingState[data.tag]) {
      Vue.set(state.tagsHistoricalDataLoadingState[data.tag], data.timeFrameKey, true);
    } else {
      Vue.set(state.tagsHistoricalDataLoadingState, data.tag,
        { [data.timeFrameKey]: true });
    }
    if (data.data.items) {
      if (data.data.items.length > 0) {
        const tagDataList = data.data.items[0].values;
        const historicalData = [];
        tagDataList.forEach((tagData) => {
          historicalData.push({
            x: new Date(tagData.time).getTime(),
            y: Number(tagData.value),
          });
        });
        if (!(data.tag in state.tagsHistoricalData)) {
          Vue.set(state.tagsHistoricalData, data.tag, {});
        }
        if (!(data.timeFrameKey in state.tagsHistoricalData[data.tag])) {
          Vue.set(state.tagsHistoricalData[data.tag], data.timeFrameKey, historicalData);
        } else {
          Vue.set(state.tagsHistoricalData[data.tag], data.timeFrameKey,
            state.tagsHistoricalData[data.tag][data.timeFrameKey].concat(historicalData));
        }
      }
    } else {
      Vue.set(state.tagsHistoricalData, data.tag,
        { [data.timeFrameKey]: null });
    }
  },
  removeHCTagData: (state, { tag, timeFrameKey }) => {
    if (state.tagsHistoricalData[tag]
      && state.tagsHistoricalData[tag][timeFrameKey] !== undefined) {
      delete state.tagsHistoricalData[tag][timeFrameKey];
    }
    if (state.tagsHistoricalDataLoadingState[tag]
      && state.tagsHistoricalDataLoadingState[tag][timeFrameKey] !== undefined) {
      delete state.tagsHistoricalDataLoadingState[tag][timeFrameKey];
    }
  },
  changeLoadingState: (state, isLoading) => {
    state.isDashboardLoading = isLoading;
  },
  setSelectedTagsData: (state, data) => {
    const asArray = Object.entries(state.actualTagsData);
    state.selectedTagsData = asArray
      .filter(([key, value]) => data.includes(parseInt(key, 10)) && value != null);
  },
  updateNextHCTag: (state, data) => {
    if (data.data.items.length > 0) {
      const tagDataList = data.data.items[0].values;
      const historicalData = [];
      tagDataList.forEach((tagData) => {
        historicalData.push({
          x: new Date(tagData.time).getTime(),
          y: Number(tagData.value),
        });
      });
      Vue.set(state.tagsHistoricalData[data.tag], data.timeFrameKey,
        state.tagsHistoricalData[data.tag][data.timeFrameKey].concat(historicalData));
    }
  },
  finishNextHCTag: (state, data) => {
    Vue.set(state.tagsHistoricalDataLoadingState[data.tag], data.timeFrameKey, false);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
