<template>
  <div>
    <v-badge dot class="badge" offset-x="-100px" offset-y="-100px"
      inline :value="hasAppsWithEventsHided" color="red"
      :style="eventsHidedOnLeft ? 'left: 10px;' : 'right: 10px;'"></v-badge>
    <v-slide-group show-arrows v-if="getGroupApps && getAppList.length > 0" class="app-board">
      <v-slide-item v-for="app in getAppList" :key="app.internalName" class="mx-2">
        <EMAppTile :appInfo='app' v-intersect="onIntersect"/>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
import EMAppTile from '@/components/Tile/EMAppTile.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'EMAppBoard',
  components: {
    EMAppTile,
  },
  computed: {
    ...mapGetters(['getGroupApps', 'getAppList', 'getNewEvents']),
    hasAppsWithEventsHided() {
      return this.getNewEvents.some((event) => this.appsHided.indexOf(event.class) !== -1);
    },
    eventsHidedOnLeft() {
      const appList = this.getAppList.map((app) => app.baseUrl).sort();
      return appList.indexOf(this.appsHided[0]) < appList.indexOf(this.appsVisible[0]);
    },
  },
  data() {
    return {
      appsHided: [],
      appsVisible: [],
    };
  },
  methods: {
    onIntersect(entries) {
      const app = entries[0];
      const appURL = this.getAppList
        .filter((x) => x.internalName === app.target.outerText)[0].baseUrl;
      const appHidedIndex = this.appsHided.indexOf(appURL);
      const appVisibleIndex = this.appsVisible.indexOf(appURL);
      if (app.isIntersecting && appVisibleIndex === -1) {
        this.appsVisible.push(appURL);
        this.appsVisible.sort();
        if (appHidedIndex !== -1) {
          this.appsHided.splice(appHidedIndex, 1);
        }
      } else if (!app.isIntersecting && appHidedIndex === -1) {
        this.appsHided.push(appURL);
        this.appsHided.sort();
        if (appVisibleIndex !== -1) {
          this.appsVisible.splice(appVisibleIndex, 1);
        }
      }
    },
  },
};
</script>

<style>
.v-slide-group__next, .v-slide-group__prev {
  min-width: 30px !important;
  max-width: 30px !important;
}
.v-slide-group__prev i, .v-slide-group__next i {
  font-size: 35px !important;
}
.app-board {
  height: 90px !important;
  margin: 8px 4px !important;
}
.badge {
  position: absolute;
  top: 70px;
}
</style>
