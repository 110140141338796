<template>
  <div>
    <v-text-field
      ref="textArea"
      :disabled="isDisabled"
      :label="label"
      :value="inputValue"
      color="buttons"
      :rules="rules"
      @input="onValueChanged($event)"
      :outlined="outlined"
      :readonly="isReadonly"
    ></v-text-field>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'EMTextArea',
  props: {
    label: {
      type: String,
      default: '',
    },
    inputValue: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    isReadonly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['value-changed'],
  data() {
    return {
      debouncedValue: null,
    };
  },
  methods: {
    validate() {
      return this.$refs.textArea.validate();
    },
    onValueChanged(value) {
      if (this.rules.length > 0) {
        if (this.validate()) {
          this.$emit('value-changed', value);
        }
      } else {
        this.$emit('value-changed', value);
      }
    },
  },
  created() {
    this.debouncedValue = _.debounce(this.onValueChanged, 1000);
  },
};
</script>

<style>

</style>
