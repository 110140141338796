<template>
  <v-btn elevation='2' :small='small' :x-large='xLarge' :fab='fab' :outlined='outlined'
    :disabled="disabled" :class="`${primaryButtons? 'btn': 'outlined'}`">
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  name: 'EMButton',
  props: {
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    xLarge: {
      type: Boolean,
      required: false,
      default: false,
    },
    fab: {
      type: Boolean,
      required: false,
      default: false,
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    primaryButtons: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
<style>
.btn:hover {
  background-color: var(--v-hoveringButtons-base) !important;
}

.btn {
  background-color: var(--v-buttons-base) !important;
  color: #FFF !important;
  caret-color: #FFF !important;
}

.outlined {
  background-color: transparent;
  color: var(--v-buttons-base) !important;
  caret-color: var(--v-buttons-base) !important;
}
</style>
