import { axiosRestApiPrivate } from '../../axios/axiosInstance';

export default {
  getApplicationList() {
    return axiosRestApiPrivate.get('/application-management/applications-versions');
  },
  getApplicationConfigData(keyName) {
    return axiosRestApiPrivate.get(`/key-value/${keyName}/configuration`);
  },
  setApplicationConfigData(keyName, value) {
    return axiosRestApiPrivate.put(`/key-value/${keyName}`, value);
  },
};
