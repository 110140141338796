<template>
  <div data-testid="select-wrapper">
    <v-select
      :value="select"
      @input="timestampUpdated"
      :items="timeFrames"
      :label="label"
      :item-text="itemText"
      :item-value="itemValue"
      outlined
    />
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'EMTimeFrameSelect',
  props: {
    label: {
      type: String,
      default: '',
    },
    inputValue: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    timeFrames: {
      type: Array,
      required: false,
      default: () => [],
    },
    itemText: {
      type: String,
      required: false,
      default: '',
    },
    itemValue: {
      type: String,
      required: false,
      default: '',
    },
    select: {
      type: Number,
      required: false,
      default: 60000,
    },
  },
  emits: ['timestampChanged'],
  data() {
    return {
      debouncedValue: null,
    };
  },
  methods: {
    timestampUpdated(value) {
      this.$emit('timestampChanged', value);
    },
  },
  created() {
    this.debouncedValue = _.debounce(this.timestampUpdated, 500);
  },
};
</script>

<style>

</style>
